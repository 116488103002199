import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

// change name
export const CARE_PROGRAM_REAL_API_KEY = 'careProgramRealApi';
const careProgramRealApi = createApi({
  reducerPath: CARE_PROGRAM_REAL_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: (builder) => ({
    getCareProgramTable: builder.query({
      query: (clientId) => ({
        url: `crud/careProgram`,
        method: 'GET',
        params: { 
          index: "client-index",
          pk: "clientId",
          pkValue: clientId,
          sk: "active",
          skValue: "True"
         },
      }),
      providesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    updateCareProgram: builder.mutation({
      query: (payload) => ({
        url: `crud/careprogram`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    addCareProgram: builder.mutation({
      query: (payload) => ({
        url: `crud/careprogram`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    getCareProgramHistory: builder.query({
      query: (clientId) => ({
        url: `history/careprogram`,
        method: 'GET',
        params: {
          index: "client-index",
          pk: "clientId",
          pkValue: clientId
        }
      }),
      providesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    checkCareProgramArrivalWindows: builder.query({
      query: (payload) => ({
        url: `check/careProgram`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response) => {
        const unAvailable = [];
        const available = response?.filter((item) => {
          if (!item.available) {
            unAvailable.push(item);
          }
          return item.available;
        });
        return {
          availableSchedule: available,
          unAvailableSchedule: unAvailable,
        };
      },
      providesTags: () => [{ type: 'careProgram', id: 'ALL' }],
    }),
    checkCareProgramAvailabilty: builder.query({
      query: (payload) => ({
        url: `check/careProgram`,
        method: 'POST',
        body: payload
      }),
    }),
  }),
});

export const {
  useGetCareProgramTableQuery,
  useGetCareProgramHistoryQuery,
  useCheckCareProgramArrivalWindowsQuery,
  useUpdateCareProgramMutation,
  useAddCareProgramMutation,
  useCheckCareProgramAvailabiltyQuery,
} = careProgramRealApi;

export default careProgramRealApi;
