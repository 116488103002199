import { Box, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { bool, func, instanceOf } from 'prop-types';
import React, { useState } from 'react';
import { MAX_TASK_DETAILS_FIELD_LENGTH } from '../../../../../../shared/constants';
import CustomInput from '../../../../../../components/Inputs';
import MultilineInput from '../../../../../../components/Inputs/MultilineInput';
import CustomSelect from '../../../../../../components/Select';
import { taskInitialState } from '../../constants';
import CustomAutocomplete from '../Autocomplete';
import ContentWrapper from './Wrapper';
import { customSwitcher } from './styles';

export default function Content({
  hasPredefinedData = false,
  setTaskData = () => {},
  taskData = {},
  isLoadingConfigs = false,
  configData = {},
}) {
  const [oldTaskData, setOldTaskData] = useState(taskInitialState);

  const handleChangeTask = (_, value) =>
    setTaskData({ ...taskData, name: value ? value.name : '' });

  const onSelectChange = params => {
    setTaskData({
      ...taskData,
      name: params.field === 'categoryTypes' && !taskData.isCustom ? '' : taskData.name,
      [params.field]: params.value,
    });
    if (params.field === 'categoryTypes' || params.field === 'levelOfAssistance') {
      setTaskData({
        ...taskData,
        [params.field]: params.id,
      });
      return '';
    }
  };
  const changeCustomTaskName = e => setTaskData({ ...taskData, [e.target.id]: e.target.value });

  const changeDetails = e => {
    if (e.target.value.length <= MAX_TASK_DETAILS_FIELD_LENGTH) {
      setTaskData({ ...taskData, [e.target.id]: e.target.value });
    }
  };

  const isStepAvailable = step => {
    if (step === 1) {
      return (
        !taskData.isCustom ||
        !!(taskData?.name.length && taskData?.categoryTypes.length) ||
        hasPredefinedData
      );
    }
    if (step === 2) {
      return (
        !taskData.isCustom ||
        !!(
          taskData?.name.length &&
          taskData?.categoryTypes.length &&
          taskData?.levelOfAssistance.length
        ) ||
        hasPredefinedData
      );
    }
    return true;
  };

  const selectCustomTask = e => {
    setTaskData({
      ...(hasPredefinedData ? taskData : taskInitialState),
      isCustom: e.target.checked,
    });
    if (!taskData.isCustom) {
      setOldTaskData(taskData);
    }
    if (taskData.isCustom) {
      setTaskData(oldTaskData);
    }
  };

  return (
    <ContentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <FormControlLabel
          control={
            <Switch
              sx={customSwitcher}
              checked={taskData.isCustom}
              color="secondary"
              onChange={e => selectCustomTask(e)}
            />
          }
          label={taskData.isCustom ? 'Custom Task' : 'Standard Task'}
        />
        {!taskData.isCustom && (
          <CustomSelect
            id="categoryTypes"
            isLoading={isLoadingConfigs}
            isMultiselect={false}
            options={configData?.categoryTypes?.map(cat => ({ id: cat.id, name: cat.name }))}
            placeHolder="Category"
            selectedValue={taskData?.categoryTypes}
            setValue={onSelectChange}
          />
        )}
        {taskData.isCustom ? (
          <CustomInput
            changeTaskName={changeCustomTaskName}
            id="name"
            label="Task"
            value={taskData?.name}
          />
        ) : (
          <CustomAutocomplete
            id="name"
            changeValue={handleChangeTask}
            options={configData.taskTypes.filter(t => t.category === taskData.categoryTypes)}
            selectedValue={taskData?.name || ''}
          />
        )}
        {taskData.isCustom && (
          <CustomSelect
            id="categoryTypes"
            isLoading={isLoadingConfigs}
            isMultiselect={false}
            options={configData?.categoryTypes.map(cat => ({ id: cat.id, name: cat.name }))}
            placeHolder="Category"
            selectedValue={taskData?.categoryTypes}
            setValue={onSelectChange}
          />
        )}
        {isStepAvailable(1) && (
          <CustomSelect
            id="levelOfAssistance"
            isLoading={isLoadingConfigs}
            isMultiselect={false}
            options={configData?.levelsOfAssistance?.map(lev => ({ id: lev.id, name: lev.name }))}
            placeHolder="Level Of Assistance"
            selectedValue={taskData?.levelOfAssistance}
            setValue={onSelectChange}
          />
        )}
        {isStepAvailable(2) && (
          <MultilineInput
            changeDetails={changeDetails}
            id="details"
            label="Details"
            maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
            value={taskData.details}
            showHelperText
          />
        )}
      </Box>
    </ContentWrapper>
  );
}

Content.propTypes = {
  hasPredefinedData: bool,
  setTaskData: func,
  taskData: instanceOf(Object),
  isLoadingConfigs: bool,
  configData: instanceOf(Object),
};
