import { useUpdateOneTimeVisitMutation } from '../../../../../../api/Visits/api';
import { CreateOneTimeVisitContext } from '../../../../../../pages/ClientDetails/context';
import { getCreateVisitConfirmButtonName } from '../../../../../../pages/ClientDetails/utils';
import { func, node } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../components/Dialog/UnsavedChangesContent';
import FullWidthDialog from '../../../../../../components/FullWidthDialog';
import Loader from '../../../../../../components/Loader';
import { createOneTimeVisitInitialData, createOneTimeVisitSteps } from '../../constants';
import { checkIsCreateVisitStepConfirmButtonDisabled } from '../../utils';
import { CreateOneTimeVisitMultistep } from './CreateOneTimeVisitMultistep';
import { v4 as uuidv4 } from 'uuid';

// import {
// localVisitData,
// tasksResponse,
// } from './CreateOneTimeVisitMultistep/TasksManagement/constants';

export default function CreateOneTimeVisitProvider({
  children = <div />,
  getCalendarData = () => {},
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [step, setStep] = useState(0);
  const [stepDataLoading, setStepDataLoading] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [visitParams, setVisitParams] = useState(createOneTimeVisitInitialData);
  const [availabilityScheduling, setAvailabilityScheduling] = useState(null);
  const [availableVisitTasks, setAvailableVisitTasks] = useState(null);
  const [isSelectedTimeOutOfRange, setIsSelectedTimeOutOfRange] = useState(false);
  const [pastTimeError, setPastTimeError] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const [updateOneTimeVisit, { error, isLoading, isSuccess, status }] =
    useUpdateOneTimeVisitMutation();

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const alertUser = useCallback(
    e => {
      if (!openDialog) {
        return true;
      }
      e.preventDefault();
      e.returnValue = '';
      return true;
    },
    [openDialog],
  );

  const clearCreationData = () => {
    setStep(0);
    setCompletedSteps([]);
    setAvailabilityScheduling(null);
    setAvailableVisitTasks([]);
    setVisitParams(createOneTimeVisitInitialData);
  };

  useEffect(() => {
    if (status === 'fulfilled' && isSuccess) {
      getCalendarData();
    }
  }, [getCalendarData, isSuccess, status]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [alertUser]);

  const addOneTimeVisit = () => setOpenDialog(true);

  const closeDialog = useCallback(() => {
    const {
      arrivalEnd,
      arrivalStart,
      dates,
      duration,
      exactTimeSlot,
      genderPreference,
      skills,
      visitType,
    } = visitParams;
    const isChangesAdded =
      step > 0 ||
      !!arrivalStart ||
      !!arrivalEnd ||
      !!dates?.length ||
      !!duration ||
      !!exactTimeSlot ||
      !!genderPreference ||
      !!skills?.length ||
      !!Object.keys(visitType).length;
    if (isChangesAdded) {
      setOpenConfirmationModal(true);
    } else {
      clearCreationData();
      setOpenDialog(false);
    }
  }, [step, visitParams]);

  const submitStep = useCallback(() => {
    if (step === createOneTimeVisitSteps.length - 1) {
      const visitData = {
        ...visitParams,
        tasks:
          availableVisitTasks?.map(x => ({
            id: uuidv4(),
            status: 'todo',
            careplanId: x.id,
          })) ?? [],
        taskDetails: availableVisitTasks,
      };

      clearCreationData();
      setOpenDialog(false);
      updateOneTimeVisit(visitData);
      return '';
    }
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    if (step === 0 && completedSteps.length > 1) {
      setCompletedSteps([step]);
    }
    if (step === 1) {
      setStepDataLoading(true);
    }
    setStep(prevState => prevState + 1);
    return '';
  }, [availableVisitTasks, completedSteps, step, updateOneTimeVisit, visitParams]);

  const goToEditTasks = useCallback(() => {
    navigate(`/clients/${id}/care-plan/current`);
    closeDialog();
  }, [closeDialog, id, navigate]);

  const memoizedProviderValue = useMemo(
    () => ({
      addOneTimeVisit,
      availabilityScheduling,
      availableVisitTasks,
      closeDialog,
      completedSteps,
      goToEditTasks,
      id,
      isSelectedTimeOutOfRange,
      pastTimeError,
      setAvailabilityScheduling,
      setAvailableVisitTasks,
      setIsSelectedTimeOutOfRange,
      setPastTimeError,
      setStep,
      setStepDataLoading,
      setVisitParams,
      step,
      stepDataLoading,
      visitParams,
      submitStep,
    }),
    [
      availabilityScheduling,
      availableVisitTasks,
      closeDialog,
      completedSteps,
      goToEditTasks,
      id,
      isSelectedTimeOutOfRange,
      pastTimeError,
      setPastTimeError,
      setStepDataLoading,
      step,
      stepDataLoading,
      visitParams,
      submitStep,
    ],
  );

  return (
    <CreateOneTimeVisitContext.Provider value={memoizedProviderValue}>
      {isLoading && <Loader />}
      <>
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={() => setOpenConfirmationModal(false)}
          submitButtonName="confirm"
          submitCallback={() => {
            clearCreationData();
            setOpenDialog(false);
            setOpenConfirmationModal(false);
          }}
          title="Confirmation"
          openDialog={openConfirmationModal}
        >
          <UnsavedChangesContent />
        </CustomDialog>
        <FullWidthDialog
          backButtonCallback={closeDialog}
          cancelButtonName="cancel"
          cancelCallback={closeDialog}
          disabledSubmit={checkIsCreateVisitStepConfirmButtonDisabled({
            isSelectedTimeOutOfRange,
            pastTimeError,
            step,
            stepDataLoading,
            visitData: visitParams,
          })}
          submitButtonName={getCreateVisitConfirmButtonName(step, true)}
          submitCallback={submitStep}
          title="Create Visit"
          openDialog={openDialog}
        >
          <CreateOneTimeVisitMultistep />
        </FullWidthDialog>
      </>
      {children}
    </CreateOneTimeVisitContext.Provider>
  );
}

CreateOneTimeVisitProvider.propTypes = {
  children: node,
  getCalendarData: func,
};
