import { Alert, Box } from '@mui/material';
import moment from 'moment/moment';
import VisitSlotTitle from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/AlternativeArrivalWindows/VisitSlotTitle';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../../../../shared/constants';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';
import { pluralize } from '../../../../../../../../../../shared/utils/pluralize';
import { checkingResultsColumns } from '../constants';
import {
  checkAvailabilityColumnsWrapper,
  checkAvailabilityCustomAlert,
} from '../styles';
import VisitAccordion from './VisitAccordion';

export default function AvailabilityColumns({
  alternativeArrivalWindows = {},
  deleteVisitFromSchedule = () => {},
  expanded = [],
  setAlternativeArrivalWindows = () => {},
  setExpanded = () => {},
  setVisitData = () => {},
  visitData = {},
}) {
  const updateVisitData = (date, data, newArrivalRange) => {
    const dateChanges = { };
    dateChanges[date.slice(0, 10)] = data;
    if (data?.date) {
      dateChanges[data.date] = {date: true};
      dateChanges[date.slice(0, 10)] = {canceled: true};
    }
    const tempVisitData = {...visitData};
    tempVisitData.dateChanges = {...tempVisitData.dateChanges, ...dateChanges};
    setVisitData(tempVisitData);
    const newUnAvailableScheduleList =
      alternativeArrivalWindows.unAvailableSchedule.filter(
        (item) => item.date !== date,
      );
    setExpanded(expanded.filter((item) => item !== date));
    setAlternativeArrivalWindows({
      availableSchedule: [
        ...alternativeArrivalWindows.availableSchedule,
        { ...newArrivalRange, reselectedUnavailable: true },
      ],
      unAvailableSchedule: newUnAvailableScheduleList,
    });
  };

  const visitsCounter =
    (alternativeArrivalWindows?.availableSchedule?.length || 0) +
    (alternativeArrivalWindows?.unAvailableSchedule?.length || 0);
  return (
    <Box sx={checkAvailabilityColumnsWrapper}>
      {checkingResultsColumns.map(
        ({ bgColor, color, dataKey, icon, getTitle, type }) => {
          const arrivalWindowsByResult = alternativeArrivalWindows
            ? alternativeArrivalWindows[dataKey]
            : [];
          return (
            !!arrivalWindowsByResult.length && (
              <Box
                key={dataKey}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <Alert
                  icon={icon}
                  severity={type}
                  sx={{
                    ...checkAvailabilityCustomAlert,
                    backgroundColor: bgColor,
                    color,
                  }}
                >
                  {getTitle(pluralize(arrivalWindowsByResult.length, 'visit'))}
                </Alert>
                {arrivalWindowsByResult.map((item) =>
                  dataKey === 'availableSchedule' ? (
                    <Box
                      key={`${item.date} ${item.arrivalStart}`}
                      sx={{
                        width: '100%',
                        padding: '20px 0 24px 0',
                        borderBottom: `1px solid ${COLORS.blue[200]}`,
                      }}
                    >
                      <VisitSlotTitle
                        deleteVisitFromSchedule={() =>
                          deleteVisitFromSchedule({ dataKey, date: item.date })
                        }
                        isExpanded
                        title={`${moment(
                          item.date,
                          ISO_DATE_ONLY_FORMAT,
                        ).format(SHORT_DATE_FORMAT)}, ${
                          item.exactTimeSlot
                            ? item.exactTimeSlot
                            : `${item.arrivalStart} - ${item.arrivalEnd}`
                        }`}
                      />
                    </Box>
                  ) : (
                    <VisitAccordion
                      deleteVisitFromSchedule={() =>
                        deleteVisitFromSchedule({ dataKey, date: item.date })
                      }
                      expanded={expanded}
                      isUnscheduled={dataKey === 'unAvailableSchedule'}
                      key={item.date}
                      setExpanded={setExpanded}
                      updateVisitData={updateVisitData}
                      visit={item}
                      visitsCount={visitsCounter}
                    />
                  ),
                )}
              </Box>
            )
          );
        },
      )}
    </Box>
  );
}

AvailabilityColumns.propTypes = {
  visitData: instanceOf(Object),
  setVisitData: func,
  alternativeArrivalWindows: instanceOf(Object),
  deleteVisitFromSchedule: func,
  expanded: instanceOf(Array),
  setAlternativeArrivalWindows: func,
  setExpanded: func,
};
