import { Box, Button, Typography } from '@mui/material';
import { func, string } from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { visitStatusesMap } from '../../../../shared/constants';
import CustomSelect from '../../../../components/Select';
import {
  styledCancelButton,
  visitStatusChangingConfirmationActionsWrapper,
  visitStatusChangingConfirmationWrapperStyles,
  visitStatusChangingTextAreaWrapper,
} from './styles';
import { useGetConfigQuery } from '../../../../api/Config/api';
import { VisitInfoTypeContext } from '../../../../shared/context';

export default function StatusChangingConfirmation({
  cancelNewStatus = () => {},
  confirmNewStatus = () => {},
  newStatus = '',
  newCancellationReason = '',
}) {
  const { updateTemporaryVisitData } = useContext(VisitInfoTypeContext);
  const [reason, setReason] = useState(newCancellationReason);
  const disabledConfirmation = useMemo(
    () => newStatus === visitStatusesMap.canceled && !reason.length,
    [newStatus, reason],
  );
  const onCancel = () => {
    setReason('');
    cancelNewStatus();
  };
  const onConfirm = () => {
    confirmNewStatus();
  };

  const { data } = useGetConfigQuery(['cancellationReasons']);

  const updateCancellationReason = reasonId => {
    setReason(reasonId);
    updateTemporaryVisitData({ cancellationReason: reasonId });
  };

  return (
    <Box sx={visitStatusChangingConfirmationWrapperStyles}>
      <Typography sx={{ mb: '10px' }}>
        {`Visit Status will be changed to '${newStatus}'. Do you want to proceed?`}
      </Typography>
      {newStatus === visitStatusesMap.canceled && (
        <CustomSelect
          id="reasons"
          isLoading={false}
          options={data?.cancellationReasons}
          placeHolder="Reasons"
          selectedValue={reason}
          setValue={params => updateCancellationReason(params.id)}
          addStyles={visitStatusChangingTextAreaWrapper}
        />
      )}

      <Box sx={visitStatusChangingConfirmationActionsWrapper}>
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Button
            color="secondary"
            variant="outlined"
            onClick={onCancel}
            disableElevation
            sx={styledCancelButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onConfirm}
            disabled={disabledConfirmation}
            disableElevation
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

StatusChangingConfirmation.propTypes = {
  cancelNewStatus: func,
  confirmNewStatus: func,
  newStatus: string,
  newCancellationReason: string,
};
