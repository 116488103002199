import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { LOCAL_STORAGE_KEYS } from './pages/Scheduling/constants';
import React, { Suspense, useMemo } from 'react';
import { useRoutes } from 'react-router';
import styles from './App.module.scss';
import Loader from './components/Loader';
import routes from './router';

// main app function
function App() {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const routing = useRoutes(
    useMemo(() => routes(isAuthenticated), [isAuthenticated]),
  );
  const timeValues = useMemo(() => {
    let hours;
    let minutes;
    let ampm;
    const time = [];
    for (let i = 420; i < 1320; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes += '0';
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours %= 12;
      if (hours === 0) {
        hours = 12;
      }
      time.push(`${hours}:${minutes} ${ampm}`);
    }
    return time;
  }, []);
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.timeValuesList,
    JSON.stringify(timeValues),
  );
  if (inProgress !== 'none') {
    return <Loader />;
  }
  return (
    <div className={styles.App}>
      <Suspense fallback={<Loader />}>
        {inProgress === 'none' ? routing : null}
      </Suspense>
    </div>
  );
}

export default App;
