import { Box, Typography } from '@mui/material';
import EditIcon from '../../../../../img/icons/edit-icon.svg';
import DeleteIcon from '../../../../../img/icons/iconButtons/delete-icon.svg';
import { AddTaskContext, CarePlanContext } from '../../../../../pages/ClientDetails/context';
import { string } from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import CustomDialog from '../../../../../components/Dialog';
import TableRowActions from './TableRowActions';

function TaskActions({ rowId = '' }) {
  const { carePlanTasks, deleteCarePlanTask } = useContext(CarePlanContext);
  const { editTask } = useContext(AddTaskContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const menuItems = useMemo(
    () => [
      {
        icon: EditIcon,
        key: 'Edit',
        name: 'Edit visit',
        callback: () => {
          editTask(rowId);
        },
      },
      {
        icon: DeleteIcon,
        key: 'Delete',
        name: 'Delete visit',
        callback: () => {
          setOpenDeleteDialog(true);
        },
      },
    ],
    [editTask, rowId],
  );

  const deleteTask = useCallback(() => {
    const newTask = carePlanTasks.find((task) => task.id === rowId);
    const taskId = newTask ? { id: newTask.id } : null;
    deleteCarePlanTask({
      ...taskId
    });
    setOpenDeleteDialog(false)
  }, [
    deleteCarePlanTask,
    carePlanTasks,
    rowId,
  ]);

  const carePlanTask = useMemo(
    () => carePlanTasks.find((task) => task.id === rowId)?.name || '',
    [carePlanTasks, rowId],
  );

  return (
    <div>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteTask}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {`The task "${carePlanTask}" will be removed. Do you want to proceed?`}
          </Typography>
        </Box>
      </CustomDialog>
      <TableRowActions menuItems={menuItems} />
    </div>
  );
}

TaskActions.propTypes = {
  rowId: string,
};

export default React.memo(TaskActions);
