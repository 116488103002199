import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  tasksStatusesColorSchemes,
  tasksStatusesMap,
  tasksTypeIcons,
} from '../../../../shared/constants';
import CustomTooltip from '../../../../components/CustomTooltip';
import MultilineInput from '../../../../components/Inputs/MultilineInput';
import StatusesCustomSelect from '../../../../components/StatusesSelect';

export const taskSelectionModels = {
  current: '0',
  future: '1',
};
export const tasksColumns = [
  {
    field: 'name',
    headerName: 'Task',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row }) => (
      <CustomTooltip title={row?.name}>
        <Typography
          sx={{
            fontSize: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            lineHeight: '24px',
          }}
          variant="h4"
        >
          {row.name}
        </Typography>
      </CustomTooltip>
    ),
    sortable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <CustomTooltip title={row.categoryTypesDetails?.name}>
        <Typography
          sx={{
            fontSize: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            lineHeight: '24px',
          }}
          variant="h4"
        >
          {row.categoryTypesDetails?.name}
        </Typography>
      </CustomTooltip>
    ),
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 300,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <MultilineInput
        disabled
        id={row.id}
        onKeyDown={e => e.stopPropagation()}
        value={row.details || ''}
      />
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      const rowStatus = row.status === 'todo' ? 'To Do' : row.status;
      const statusKey = tasksStatusesMap[rowStatus];
      return (
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            mt: '4px',
          }}
        >
          <StatusesCustomSelect
            colorScheme={tasksStatusesColorSchemes[statusKey]}
            disabled
            id="status"
            options={Object.keys(tasksStatusesMap)}
            isLoading={false}
            selectedValue={rowStatus ?? 'To Do'}
          />
        </Box>
      );
    },
  },
  {
    field: 'isCustom',
    headerName: 'Task Type',
    minWidth: 150,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <CustomTooltip title={row?.isCustom ? 'Custom task' : 'Care Program task'}>
          <Box
            component="img"
            src={tasksTypeIcons[row?.isCustom ? 'custom' : 'common']}
            sx={{ height: '20px', mt: '4px', mr: '20px' }}
            alt="img"
          />
        </CustomTooltip>
      </Box>
    ),
    sortable: false,
  },
];
