import { Box, Typography, CircularProgress } from '@mui/material';
import {
  useDeleteTerritoryIdMutation,
  useUpdateTerritoryByIdMutation,
} from '../../../../../../api/Administration/api';
import EditIcon from '../../../../../../img/icons/edit-icon.svg';
import DeleteIcon from '../../../../../../img/icons/iconButtons/delete-icon.svg';
import { instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import TableRowActionsWithIcons from '../../../../../../components/TableRowActionsWithIcons';
import AddTerritoryModal from '../AddTerritoryModal';
import ErrorTerritoryTerritory from './ErrorTerritoryTerritory';
import { teamColorBoxStyles } from './styles';

function TerritoryTableActions({ row = {}, locations = [] }) {
  const { setAlert } = useContext(AlertContext);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [territoryName, setTerritoryName] = useState(row?.name);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [order, setOrder] = useState(parseInt(row?.order, 10) || 0);
  const [responseTerritoryMessage, setResponseTerritoryMessage] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [updateTerritoryById, { error: updateTerritoryByIdError, isLoading: isUpdating }] =
    useUpdateTerritoryByIdMutation();
  const [deleteTerritory, { error: deleteTerritoryByIdError, isLoading: isDeleting }] =
    useDeleteTerritoryIdMutation();

  useEffect(() => {
    if (updateTerritoryByIdError) {
      const errorData = updateTerritoryByIdError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, updateTerritoryByIdError]);

  useEffect(() => {
    if (deleteTerritoryByIdError?.status === 405) {
      setOpenErrorModal(true);
    }
  }, [deleteTerritoryByIdError?.status]);

  const updateUser = () => {
    updateTerritoryById({
      ...row,
      name: territoryName,
      order,
      type: 'territory',
    });
    setOpenEditDialog(false);
  };

  const deleteTerritoryByIdHandler = () => {
    setOpenDeleteDialog(false);
    deleteTerritory({
      ...row,
      name: territoryName,
      order,
      type: "territory"
    }).then(response => {
      if (response?.data?.responseTerritoryMessage) {
        setResponseTerritoryMessage(response.data.responseTerritoryMessage);
        setOpenSuccessDialog(true);
      }
      setOpenDeleteDialog(false);
    }).catch(error => {
      console.error("Error deleting territory:", error);
    });
  };

  const menuItems = useMemo(
    () => [
      {
        icon: EditIcon,
        key: 'Edit',
        name: 'Edit',
        callback: () => {
          setOpenEditDialog(true);
          setOrder(parseInt(row?.order, 10) || 0);
        },
      },
      {
        icon: DeleteIcon,
        key: 'Remove',
        name: 'Remove',
        callback: () => {
          setOpenDeleteDialog(true);
        },
      },
    ],
    [row.order]
  );

  const cancelEditTerritory = () => {
    setOpenEditDialog(false);
    setOrder(parseInt(row?.order, 10) || 0);
    setTerritoryName(row.name);
  };

  const isLoading = isUpdating || isDeleting;

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'all',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          pointerEvents: isLoading ? 'none' : 'auto',
          ...teamColorBoxStyles,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{row?.name}</Typography>
        </Box>

        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={cancelEditTerritory}
          submitButtonName="Save"
          disabledSubmit={territoryName?.length === 0 || isLoading}
          submitCallback={updateUser}
          title="Edit Territory"
          openDialog={openEditDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
            <AddTerritoryModal
              territoryName={territoryName}
              setTerritoryName={setTerritoryName}
              data={locations}
              order={order}
              setOrder={setOrder}
            />
          </Box>
        </CustomDialog>

        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={() => setOpenDeleteDialog(false)}
          submitButtonName="Confirm"
          submitCallback={deleteTerritoryByIdHandler}
          title="Confirmation"
          openDialog={openDeleteDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
            <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
              The Territory
              <span style={{ fontWeight: 'bold', margin: '0 4px' }}>
                {row.name}
              </span>
              will be removed. Do you want to proceed?
            </Typography>
          </Box>
        </CustomDialog>

        <CustomDialog
          submitButtonName="OK"
          submitCallback={() => setOpenSuccessDialog(false)}
          title="Unable to Remove Territory"
          openDialog={openSuccessDialog}
        >         
          <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
            <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
              <span style={{ fontWeight: 'bold' }}>
                The following Clients and Caregivers are using this territory:
              </span>
              <br />
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                <li
                  dangerouslySetInnerHTML={{
                    __html: responseTerritoryMessage.replace(/\n/g, '<br />'),
                  }}
                />
              </ul>
            </Typography>
          </Box>
        </CustomDialog>


        <ErrorTerritoryTerritory
          openDeleteDialog={openErrorModal}
          setOpenDeleteDialog={setOpenErrorModal}
          errorData={deleteTerritoryByIdError?.data?.payload}
        />

        <TableRowActionsWithIcons menuItems={menuItems} />
      </Box>
    </>
  );
}

TerritoryTableActions.propTypes = {
  locations: instanceOf(Array),
  row: instanceOf(Object),
};

export default React.memo(TerritoryTableActions);
