import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { NavigationBlockingContext } from '../../shared/context';
import Title from './components/Title/Title';
import { CAREGIVER_DETAILS_TABS } from './constants';
import { CaregiverDetailsContext } from './context';

export default function CaregiverDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { tab } = useParams();
  const { blocked, setBlocked } = useContext(NavigationBlockingContext);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(
    () => () => {
      if (blocked) {
        setBlocked(false);
      }
    },
    [blocked, setBlocked],
  );

  const onEditProfile = useCallback(() => {
    setIsEdit(true);
    setBlocked(true);
  }, [setBlocked]);

  const onCloseProfileEditing = useCallback(() => {
    setIsEdit(false);
    setBlocked(false);
  }, [setBlocked]);

  const resetToInitialView = useCallback(() => {
    navigate(`caregivers/${id}`);
  }, [id, navigate]);

  const memoizedProviderValue = useMemo(
    () => ({
      isEdit,
      onCloseProfileEditing,
      onEditProfile,
      resetToInitialView,
    }),
    [isEdit, onCloseProfileEditing, onEditProfile, resetToInitialView],
  );

  return (
    <CaregiverDetailsContext.Provider value={memoizedProviderValue}>
      <Box
        sx={{
          width: '100%',
          typography: 'body1',
          maxHeight: 'calc(100vh - 180px)',
        }}
      >
        <TabContext value={tab}>
          <Title disableTabs={isEdit} />
          <Box>
            {CAREGIVER_DETAILS_TABS.map(({ key, child }) => (
              <TabPanel key={key} sx={{ padding: 0 }} value={key}>
                {child}
              </TabPanel>
            ))}
          </Box>
        </TabContext>
      </Box>
    </CaregiverDetailsContext.Provider>
  );
}
