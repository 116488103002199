import { Box, Grow, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import CommonTask from '../img/icons/tasksTypes/commonTask.svg';
import CustomTask from '../img/icons/tasksTypes/customTaskVisit.svg';
import Afternoon from '../img/icons/timeOfDay/afternoon.svg';
import Midday from '../img/icons/timeOfDay/midday.svg';
import Morning from '../img/icons/timeOfDay/morning.svg';
import CustomVisit from '../img/icons/visitTypes/customTaskVisit.svg';
import DailyBoost from '../img/icons/visitTypes/dailyBoost.svg';
import EssentialCare from '../img/icons/visitTypes/essentialCare.svg';
import ExtendedCare2Plus from '../img/icons/visitTypes/extendedCare2Plus.svg';
import ExtendedCare from '../img/icons/visitTypes/extendedCare.svg';
import ExtendedCarePlus from '../img/icons/visitTypes/extendedCarePlus.svg';
import FullService from '../img/icons/visitTypes/fullService.svg';
import QuickAssist from '../img/icons/visitTypes/quickAssist.svg';
import LunchBreak from '../img/icons/lunch-icon.svg';
import React from 'react';
import HistoryBulletList from '../components/HistoryBulletList';
import { COLORS } from './styles/Theme';

export const WEEK_DAYS = [
  { id: 0, name: 'M' },
  { id: 1, name: 'T' },
  { id: 2, name: 'W' },
  { id: 3, name: 'TH' },
  { id: 4, name: 'F' },
  { id: 5, name: 'SA' },
  { id: 6, name: 'SU' },
];

export const MAX_TASK_DETAILS_FIELD_LENGTH = 4000;

export const WORKING_WEEK_LENGTH = 7;

export const DEFAULT_SORTING_MODEL = { field: 'name', sort: 'asc' };

export const PAGE_SIZE = 20;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const ISO_DATE_ONLY_FORMAT = 'YYYY-MM-DD';
export const CONVERT_TO_UTC_FORMAT = 'YYYY-MM-DD h:mm A';
export const DATE_ONLY_STANDARD_FORMAT = 'MM/DD/YYYY';
export const FULL_DATE_H24_STANDARD_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const FULL_DATE_H12_STANDARD_FORMAT = 'MM/DD/YYYY h:mm A';
export const SHORT_DATE_FORMAT = 'MMM D, YYYY';
export const MONTH_DAY_YEAR_FORMAT = 'MMMM D, YYYY';
export const MONTH_DAY_DATE_FORMAT = 'MMMM D';
export const H12_TIME_FORMAT = 'h:mm A';
export const H24_TIME_FORMAT = 'HH:mm:ss';
export const TIME_OFF_REQUEST_DATE_FORMAT = 'MMM D, YYYY h:mm A';
export const TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT = 'MMM D, YYYY [at] h:mm A';
export const HISTORY_UPDATE_FORMAT = 'ddd D MMM YYYY, h:mm:ss A';
export const SEARCH_INPUT_DEBOUNCE_DELAY = 300;
export const VISIT_INFO_DEBOUNCE_DELAY = 200;

export const OPTIMIZATION_PERIOD_LENGTH = 14;

export const DEFAULT_WORKING_HOURS = 9;
export const DEFAULT_MAX_WORKING_HOUR = 23;
export const DEFAULT_MIN_WORKING_HOUR = 9;
export const MAX_SELECTED_DATES = 5;
export const MIN_SCHEDULER_WORKING_HOUR = 1;
export const MAX_SCHEDULER_WORKING_HOUR = 23;
export const CALIFORNIA_TIME_ZONE = 'America/Los_Angeles';
export const levelOfAssistance = [
  { id: 'prompt', name: 'Prompt' },
  { id: 'standbyassist', name: 'Standby Assist' },
  { id: 'partialassist', name: 'Partial Assist' },
  { id: 'perform', name: 'Perform' },
];

export const timeOfDays = [
  { id: 'morning', sortingKey: 1, name: 'Morning' },
  { id: 'midday', sortingKey: 2, name: 'Midday' },
  { id: 'afternoon', sortingKey: 3, name: 'Afternoon' },
];

export const timesOfDayInclusivity = {
  morning: '[]',
  midday: '(]',
  afternoon: '(]',
};

export const DAYS_MAP = [
  { id: 0, sortingKey: 1, name: 'Monday', shortName: 'Mo' },
  { id: 1, sortingKey: 2, name: 'Tuesday', shortName: 'Tu' },
  { id: 2, sortingKey: 3, name: 'Wednesday', shortName: 'We' },
  { id: 3, sortingKey: 4, name: 'Thursday', shortName: 'Th' },
  { id: 4, sortingKey: 5, name: 'Friday', shortName: 'Fr' },
  { id: 5, isWeekend: true, sortingKey: 6, name: 'Saturday', shortName: 'Sa' },
  { id: 6, isWeekend: true, sortingKey: 7, name: 'Sunday', shortName: 'Su' },
];

export const tasksTypeIcons = {
  custom: CustomTask,
  common: CommonTask,
};

export const viewTypeButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];

export const visitTypeIcons = {
  'quick assist': QuickAssist,
  'essential care': EssentialCare,
  'daily boost': DailyBoost,
  'full service': FullService,
  'extended care': ExtendedCare,
  'extended care (3h)': ExtendedCarePlus,
  'extended care (4h)': ExtendedCare2Plus,
  'custom visit': CustomVisit,
  'lunch break': LunchBreak,
};

export const visitStatusesMap = {
  scheduled: 'Scheduled',
  allocated: 'Allocated',
  enRoute: 'EnRoute',
  onSite: 'OnSite',
  inProgress: 'InProgress',
  completed: 'Completed',
  verified: 'Verified',
  canceled: 'Canceled',
  cannotBeAllocated: 'CannotBeAllocated',
  notAllocated: 'NotAllocated',
};

export const timesOfDayIcons = {
  morning: Morning,
  midday: Midday,
  afternoon: Afternoon,
};

export const backgroundColorSchemeMap = {
  active: {
    backgroundColor: COLORS.green[200],
    color: COLORS.green[600],
  },
  deactivated: {
    backgroundColor: COLORS.red[100],
    color: COLORS.red[700],
  },
  inactive: {
    backgroundColor: COLORS.red[100],
    color: COLORS.red[700],
  },
  suspended: {
    backgroundColor: COLORS.blue[60],
    color: COLORS.blue[500],
  },
  error: {
    backgroundColor: COLORS.red[100],
    color: COLORS.red[700],
  },
  errorAlert: {
    backgroundColor: COLORS.red[200],
    color: COLORS.red[600],
  },
  inActive: {
    backgroundColor: COLORS.blue[60],
    color: COLORS.blue[500],
  },
  info: {
    backgroundColor: COLORS.blue[80],
    color: COLORS.blue[700],
  },
  partiallySuccessful: {
    backgroundColor: COLORS.yellow[250],
    color: COLORS.yellow[700],
  },
  warning: {
    backgroundColor: COLORS.yellow[200],
    color: COLORS.yellow[700],
  },
  notCorrect: {
    backgroundColor: COLORS.yellow[250],
    color: COLORS.yellow[700],
  },
  unscheduled: {
    backgroundColor: COLORS.red[200],
    color: COLORS.red[600],
  },
  upcoming: {
    backgroundColor: COLORS.blue[70],
    color: COLORS.blue[500],
  },
  notUsed: {
    backgroundColor: COLORS.blue[300],
    color: COLORS.blue[500],
  },
  null: { backgroundColor: COLORS.blue[60], color: COLORS.blue[500] },
};

export const TransitionSlide = React.forwardRef((props, ref) => (
  <Slide direction="right" ref={ref} {...props} />
));

export const TransitionGrow = React.forwardRef((props, ref) => <Grow ref={ref} {...props} />);

export const visitArrivalTimeTypesNames = {
  arrivalWindow: 'arrivalWindow',
  exactTime: 'exactTime',
};

export const visitArrivalTimeTypes = [
  {
    value: visitArrivalTimeTypesNames.exactTime,
    label: 'Exact Time',
  },
  {
    value: visitArrivalTimeTypesNames.arrivalWindow,
    label: 'Arrival Window',
  },
];

export const WEBSOCKET_MESSAGES_TYPES = {
  caregiverDeactivationCheck: 'caregiverDeactivationCheckResponse',
  caregiverActivationCheck: 'caregiverActivationCheckResponse',
};
export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.dateCreated}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: params => <HistoryBulletList changes={params.value} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.author}</Typography>
      </Box>
    ),
  },
];

export const tasksStatusesMap = {
  Completed: 'completed',
  'Not Needed': 'notNeeded',
  'Client Refused': 'clientRefused',
  'Insufficient Time': 'insufficientTime',
  'To Do': 'toDo',
};

export const tasksStatusesColorSchemes = {
  completed: backgroundColorSchemeMap.active,
  notNeeded: backgroundColorSchemeMap.notUsed,
  partiallyCompleted: backgroundColorSchemeMap.partiallySuccessful,
  clientRefused: backgroundColorSchemeMap.error,
  toDo: backgroundColorSchemeMap.info,
  insufficientTime: backgroundColorSchemeMap.notCorrect,
};

export const TASKS_TYPES = {
  careProgramTasks: 'careProgramTasks',
  visitTasks: 'visitTasks',
};

export const tasksTypeButtons = [
  { name: 'Visit Tasks', infoType: TASKS_TYPES.visitTasks },
  { name: 'Care Plan Tasks', infoType: TASKS_TYPES.careProgramTasks },
];

export const eventsByCalendarTypes = {
  scheduling: 'Scheduling calendar event',
  caregiverCalendar: 'Caregiver calendar event',
};

export const ONLY_NUMBERS_INPUTS_KEYS = ['jobId', 'phoneNumber'];
