import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { func, instanceOf, string } from 'prop-types';
import React from 'react';
import {
  FULL_DATE_H12_STANDARD_FORMAT,
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
  MAX_TASK_DETAILS_FIELD_LENGTH,
  SHORT_DATE_FORMAT,
} from '../../../../../../../shared/constants';
import { roundUpFifteenMinutes } from '../../../../../../../shared/utils/common';
import ErrorAlert from '../../../../../../../components/ErrorAlert';
import MultilineInput from '../../../../../../../components/Inputs/MultilineInput';
import CustomCheckbox from '../Checkbox';
import ChooseDatesRows from './ChooseDatesRows';
import ContentWrapper from './Wrapper';
import { checkBoxesWrapper } from './styles';

/* eslint-disable no-console */
export default function SettingsView({
  chosenCaregiver = {},
  callOutData = {},
  error = null,
  setCallOutData = () => {},
  setError = () => {},
}) {

  const checkDatesValidation = ({
    callOutStartDate,
    callOutEndDate,
    callOutStartTime,
    callOutEndTime,
  }) => {
    if (error) {
      setError(null);
    }
    if (
      callOutStartDate &&
      callOutEndDate &&
      callOutEndDate.isBefore(callOutStartDate, 'day')
    ) {
      setError('You have been inputted wrong callout dates');
      return '';
    }
    if (!callOutStartDate || !callOutEndDate) {
      return '';
    }

    const startDate = moment(
      `${callOutStartDate?.format(SHORT_DATE_FORMAT)} ${callOutStartTime?.format(
        H12_TIME_FORMAT,
      )}`,
      FULL_DATE_H12_STANDARD_FORMAT,
    );
    const endDate = moment(
      `${callOutEndDate?.format(SHORT_DATE_FORMAT)} ${callOutEndTime?.format(
        H12_TIME_FORMAT,
      )}`,
      FULL_DATE_H12_STANDARD_FORMAT,
    );
    if (endDate.isSameOrBefore(startDate, 'minutes')) {
      setError('You have been inputted wrong callout dates or time');
      return '';
    }
    return '';
  };

  const changeRequestDate = ({ callOutStartDate, callOutEndDate }) => {
    setCallOutData({ ...callOutData, callOutStartDate, callOutEndDate });
    if (callOutStartDate && callOutEndDate) {
      checkDatesValidation({
        callOutStartDate,
        callOutEndDate,
        callOutStartTime: callOutData.callOutStartTime,
        callOutEndTime: callOutData.callOutEndTime,
      });
    }
  };

  const changeRequestTime = ({ callOutStartTime, callOutEndTime }) => {
    setCallOutData({
      ...callOutData,
      callOutStartTime,
      callOutEndTime,
    });
    if (callOutStartTime && callOutEndTime) {
      checkDatesValidation({
        callOutStartDate: callOutData.callOutStartDate,
        callOutEndDate: callOutData.callOutEndDate,
        callOutStartTime,
        callOutEndTime,
      });
    }
  };

  const changeDetails = (e) => {
    if (e.target.value.length <= MAX_TASK_DETAILS_FIELD_LENGTH) {
      setCallOutData({ ...callOutData, details: e.target.value });
    }
  };

  const changeStartingNow = (e) => {
    setCallOutData({
      ...callOutData,
      startingNow: e.target.checked,
      ...(e.target.checked
        ? {
            callOutStartTime: roundUpFifteenMinutes(moment()),
            callOutStartDate: moment(),
          }
        : {
            callOutStartTime: null,
            callOutStartDate: null,
          }),
    });
    if (e.target.checked && callOutData.callOutEndDate) {
      checkDatesValidation({
        callOutStartDate: moment(),
        callOutEndDate: callOutData.callOutEndDate,
        callOutStartTime: roundUpFifteenMinutes(moment()),
        callOutEndTime: callOutData?.callOutEndTime,
      });
    }
  };

  const changeForTheDay = (e) => {
    const endDate = callOutData.callOutStartDate
      ? callOutData.callOutStartDate
      : moment();
    const endTime = moment('23:45:00', H24_TIME_FORMAT);
    setCallOutData({
      ...callOutData,
      forTheDay: e.target.checked,
      ...(e.target.checked
        ? {
            callOutEndTime: moment('23:45:00', H24_TIME_FORMAT),
            callOutEndDate: endDate,
          }
        : {
            callOutEndTime: null,
            callOutEndDate: null,
          }),
    });
    if (e.target.checked && callOutData.callOutEndDate) {
      checkDatesValidation({
        callOutStartDate: callOutData.callOutStartDate,
        callOutEndDate: endDate,
        callOutStartTime: callOutData.callOutStartTime,
        callOutEndTime: endTime,
      });
    }
  };

  return (
    <ContentWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Typography>{chosenCaregiver.name}</Typography>
        <MultilineInput
          changeDetails={changeDetails}
          id="details"
          label="Details"
          maxLength={MAX_TASK_DETAILS_FIELD_LENGTH}
          value={callOutData?.details}
          showHelperText
        />
        <Box sx={checkBoxesWrapper}>
          <CustomCheckbox
            checked={callOutData?.startingNow}
            label="Starting now"
            onValueChange={changeStartingNow}
          />
          <CustomCheckbox
            checked={callOutData?.forTheDay}
            label="Until the end of the day"
            onValueChange={changeForTheDay}
          />
        </Box>
        {error && <ErrorAlert error={error} />}
        <ChooseDatesRows
          callOutData={callOutData}
          changeRequestDate={changeRequestDate}
          changeRequestTime={changeRequestTime}
          startedFromNow={callOutData?.startingNow}
          toTheEndOfDay={callOutData?.forTheDay}
        />
      </Box>
    </ContentWrapper>
  );
}

SettingsView.propTypes = {
  callOutData: instanceOf(Object),
  chosenCaregiver: string,
  error: string,
  setCallOutData: func,
  setError: func,
};
