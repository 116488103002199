import { Box, Typography } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';
import { COLORS } from '../../shared/styles/Theme';

export default function UnsavedChangesContent({
  text = 'All unsaved changes will be lost. Do you want to proceed?',
}) {
  return (
    <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
      <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
        {text}
      </Typography>
    </Box>
  );
}

UnsavedChangesContent.propTypes = {
  text: string,
};
