import moment from 'moment/moment';
import { getStatusClassName } from '../../shared/utils/common';
import { checkIfDraggable } from './constants';

export const getDndEventBoxClass = event => {
  const classNameData = { className: 'dnd-event' };
  if (event?.title === 'arrival') {
    classNameData.className = classNameData.className.concat(
      ` arrival ${event?.resourceId} ${event?.clientName} ${event?.team} arrival${event?.eventId}`,
    );
  }
  if (event?.title === 'arrivalTime') {
    classNameData.className = classNameData.className.concat(
      ` arrivalTime  arrivalTime${event?.id}   ${event?.resourceId}`,
    );
  }

  if (event?.visitTypeDetails?.name === 'Lunch Break') {
    classNameData.className = classNameData.className.concat(` lunchBreak`);
  }

  if (event?.caregiverId && event?.caregiverId === 'NoCaregiver') {
    classNameData.className = classNameData.className.concat(' not-allocated');
  }

  if (
    !checkIfDraggable({
      isShadowVisit: event?.isShadowVisit,
      status: event?.status,
    })
  ) {
    classNameData.className = classNameData.className.concat(' non-draggable');
  }
  classNameData.className = getStatusClassName(classNameData.className, event);
  return classNameData;
};

export const calculateCalendarVerticalScrollPosition = ({ currentDate, firstVisit, top }) => {
  const scrollMinutes = Math.floor(top * (60 / 160));
  const startOfDay = moment(firstVisit, 'HH:mm');
  const end = moment(startOfDay).add(scrollMinutes, 'minutes');
  return moment(currentDate)
    .set({
      hour: end?.hours(),
      minute: end?.minutes(),
      second: 0,
      millisecond: 0,
    })
    .toDate();
};

export const calculateResourceStatistics = (currentDate, caregiver, eventsList) => {
  const caregiverAvailabilities = caregiver?.availabilities ?? {};
  const currentWeekday = moment(currentDate).weekday();
  let currentAvailability = caregiverAvailabilities[currentWeekday];

  const availabilityStartTime = currentAvailability
    ? moment(currentAvailability.startTime, 'h:mm A')
    : undefined;
  const availabilityEndTime = currentAvailability
    ? moment(currentAvailability.endTime, 'h:mm A')
    : undefined;
  const caregiverAvailabilityDuration = currentAvailability
    ? availabilityEndTime.diff(availabilityStartTime, 'minutes')
    : 0;

  const caregiverEvents = eventsList.filter(event => event.caregiverId === caregiver.id);

  const totalMinutesBooked = caregiverEvents.reduce((acc, event) => acc + event.duration, 0);
  const totalTravelTime = caregiverEvents.reduce((acc, event) => acc + event.travelTime, 0);

  const hoursBooked = Math.floor(totalMinutesBooked / 60);
  const remainingMinutesBooked = totalMinutesBooked % 60;
  const hoursBookedWithTravelTime = Math.floor((totalMinutesBooked + totalTravelTime) / 60);
  const remainingMinutesBookedWithTravelTime = (totalMinutesBooked + totalTravelTime) % 60;
  const travelTimeHours = Math.floor(totalTravelTime / 60);
  const travelTimeRemainingMinutes = totalTravelTime % 60;

  const utilizationRate = (
    caregiverAvailabilityDuration > 0
      ? (totalMinutesBooked / caregiverAvailabilityDuration) * 100
      : 0
  ).toFixed(2);
  const utilizationRateWithTravelTime = (
    caregiverAvailabilityDuration > 0
      ? ((totalMinutesBooked + totalTravelTime) / caregiverAvailabilityDuration) * 100
      : 0
  ).toFixed(2);

  return {
    scheduledHours: `${hoursBooked > 0 ? `${hoursBooked}h ` : ''}${
      remainingMinutesBooked > 0 ? `${remainingMinutesBooked}min` : ''
    }`,
    utilizationRate,
    utilizationRateWithTravelTime,
    utilizationHoursWithTravelTime: `${
      hoursBookedWithTravelTime > 0 ? `${hoursBookedWithTravelTime}h ` : ''
    }${
      remainingMinutesBookedWithTravelTime > 0 ? `${remainingMinutesBookedWithTravelTime}min` : ''
    }`,
    expectedTravelTime: `${travelTimeHours > 0 ? `${travelTimeHours}h ` : ''}${
      travelTimeRemainingMinutes > 0 ? `${travelTimeRemainingMinutes}min` : ''
    }`,
  };
};
