import { Box, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../../img/icons/error-icon.svg';
import WarningIcon from '../../../../../img/icons/warning-icon.svg';
import { instanceOf, string } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import { COLORS } from '../../../../../shared/styles/Theme';
import { getIconComponent, violationTooltipComponent } from '../../../../../shared/utils/common';

export default function VisitType({ row = {} }) {
  const { tabSubView } = useParams();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      {['hard-constraints', 'soft-constraints'].includes(tabSubView) && (
        <Box>
          {tabSubView === 'hard-constraints'
            ? violationTooltipComponent({
                violations: row.violatedHardConstraints,
                icon: hardConstraintsWarning,
              })
            : violationTooltipComponent({
                violations: row.violatedSoftConstraints,
                icon: WarningIcon,
              })}
        </Box>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            ml: row?.shiftedBy ? '40px' : 0,
          }}
        >
          {getIconComponent(row.visitTypeDetails?.name?.toLowerCase())}
          <Typography
            variant={row?.shiftedBy ? 'body1' : 'h5'}
            sx={{
              ml: '8px',
              fontSize: '14px',
              color: COLORS.blue[700],
            }}
          >
            {row.visitTypeDetails?.name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

VisitType.propTypes = {
  row: instanceOf(Object),
  value: string,
};
