import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { TimeOffRequestsContext } from '../../../../../pages/CaregiverDetails/context';
import { instanceOf } from 'prop-types';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../../shared/utils/common';
import CustomDialog from '../../../../../components/Dialog';
import { continueButtonStyles, rejectButtonStyles } from '../styles';

export default function RequestsActions({ row = {} }) {
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const {
    startCheckOPtimizer,
    rejectPendingRequest,
    deletePendingRequest,
    closeActionDialogs,
    setCloseActionDialogs,
  } = useContext(TimeOffRequestsContext);

  useEffect(() => {
    if (closeActionDialogs){
      setOpenRejectDialog(false);
      setOpenDeleteDialog(false);
      setCloseActionDialogs(false);
    }
    // eslint-disable-next-line
  }, [closeActionDialogs]);

  const rejectTimeOffRequest = () => {
    setOpenRejectDialog(false);
    if (row.status?.toLowerCase() === 'approved') {
      const ptoData = {...row};
      ptoData.status = 'Rejected';
      startCheckOPtimizer(ptoData);
    }
    if (row.status?.toLowerCase() === 'pending') {
      const ptoData = {...row};
      ptoData.status = 'Rejected';
      rejectPendingRequest(ptoData);
    }
  };

  const deleteTimeOffRequest = () => {
    setOpenDeleteDialog(false);
    if (row.status?.toLowerCase() === 'approved') {
      const ptoData = {...row};
      ptoData.status = 'Deleted';
      startCheckOPtimizer(ptoData);
    }
    if (row.status?.toLowerCase() === 'pending') {
      const ptoData = {...row};
      ptoData.status = 'Deleted';
      deletePendingRequest(ptoData);
    }
  };

  const approveTimeOffRequest = () => {
    const ptoData = {...row};
    ptoData.status = 'Approved';
    startCheckOPtimizer(ptoData);
  };

  const showContinueButton = useMemo(
    () =>
      row.status?.toLowerCase() === 'pending' &&
      moment().isBefore(
        convertUtcToTeamTimeZoneMomentObject(row.from),
        'day',
      ),
    [row],
  );
  const showRejectButton = useMemo(
    () =>
      (row.status?.toLowerCase() === 'approved' &&
        moment().isBefore(
          convertUtcToTeamTimeZoneMomentObject(row.from),
          'minutes',
        )) ||
      (row.status?.toLowerCase() === 'pending'),
    [row],
  );

  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenRejectDialog(false)}
        openDialog={openRejectDialog}
        submitButtonName="Reject"
        submitCallback={rejectTimeOffRequest}
        title="Confirmation"
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Time-Off Request will be rejected. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        openDialog={openDeleteDialog}
        submitButtonName="Delete"
        submitCallback={deleteTimeOffRequest}
        title="Confirmation"
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            Time-Off Request will be deleted. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      
      {showContinueButton && (
        <Button
          color="secondary"
          disableElevation
          onClick={() => approveTimeOffRequest()}
          sx={continueButtonStyles}
          variant="outlined"
        >
          Approve
        </Button>
      )}
      {showRejectButton && (
        <Button
          color="secondary"
          disableElevation
          onClick={() => setOpenRejectDialog(true)}
          sx={rejectButtonStyles}
          variant="outlined"
        >
          Reject
        </Button>
      )}
      {showRejectButton && (
        <Button
          color="secondary"
          disableElevation
          onClick={() => setOpenDeleteDialog(true)}
          sx={rejectButtonStyles}
          variant="outlined"
        >
          Delete
        </Button>
      )}
    </Box>
  );
}

RequestsActions.propTypes = {
  row: instanceOf(Object),
};
