import moment from 'moment';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../../../../../shared/constants';

export const getSummaryData = (data) => {
  const {
    effectiveDate,
    userData,
    visitData,
  } = data;
  const arrivalWindows = [];

  return {
    client: `${userData?.firstName} ${userData?.lastName}`,
    address:`${userData?.street} ${userData?.city}, ${userData?.state}, ${userData?.zipCode}, ${userData?.country}`,
    visitType: visitData?.visitTypeDetails.name,
    duration: visitData?.duration,
    recurrence: visitData?.recurrence,
    effectiveDate: moment(effectiveDate, ISO_DATE_ONLY_FORMAT).format(
      SHORT_DATE_FORMAT,
    ),
    arrivalWindows,
  };
};
