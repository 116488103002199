import { Box, List, ListItem, Typography } from '@mui/material';
import { bool, instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import CounterBadge from '../../../components/CounterBadge';
import CustomTooltip from '../../../components/CustomTooltip';

export default function TeamsCell({
  isPrimaryTeams = false,
  isSecondaryTeams = false,
  teams = [],
  territory = '',
  isTerritory = false,
}) {
  const teamsList = useMemo(() => {
    if (teams?.length > 1) {
      return {
        mainTeam: teams[0],
        hiddenTeams: [...teams.slice(1)],
      };
    }
    if (teams?.length === 1) {
      return {
        mainTeam: teams[0],
      };
    }
    return null;
  }, [teams]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isPrimaryTeams && (
          <Box
            sx={{
              width: '16px',
              height: '16px',
              borderRadius: '3px',
              backgroundColor: teamsList?.mainTeam?.color?.color,
              mr: '10px',
            }}
          />
        )}
        {isPrimaryTeams && <Typography sx={{ mr: '6px' }}>{teamsList?.mainTeam?.name}</Typography>}
      </Box>
      {teamsList?.hiddenTeams?.length ? (
        <Box sx={{ width: 'fit-content' }}>
          <CustomTooltip
            placement="right-start"
            title={
              <Box sx={{ padding: '6px' }}>
                <List>
                  {teamsList?.hiddenTeams?.map(({ id, name }) => (
                    <ListItem key={id} sx={{ padding: 0 }}>
                      <Typography sx={{ mr: '4px', fontSize: '12px' }} noWrap>
                        {name}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            }
          >
            <div>
              <CounterBadge label={`+${teamsList?.hiddenTeams?.length.toString()}`} />
            </div>
          </CustomTooltip>
        </Box>
      ) : (
        ''
      )}
      {isSecondaryTeams && (
        <Box sx={{ display: 'flex' }}>
          {teams?.map((team, index) => (
            <Typography key={team?.id} sx={{ marginRight: '2px' }} variant="body1">{`${team?.name}${
              index !== teams?.length - 1 ? ',' : ''
            }`}</Typography>
          ))}
        </Box>
      )}
      {isTerritory && (
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ marginRight: '2px' }} variant="body1">
            {territory}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

TeamsCell.propTypes = {
  isPrimaryTeams: bool,
  isSecondaryTeams: bool,
  territory: string,
  teams: instanceOf(Array),
};
