import { COLORS } from '../../../../../../../../shared/styles/Theme';

export const stepContentWrapperStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1420px',
  width: '100%',
  marginBottom: '68px',
};

export const tasksManagementTableStyles = isEmpty => [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: 'calc(100vh - 516px)',
      padding: isEmpty ? '32px 0' : '1px',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      padding: '26px 21px 24px 21px',
    },
    '& .MuiDataGrid-footerContainer': {
      margin: 0,
    },
  },
];

export const tableActionsWrapper = {
  display: 'flex',
  gap: '16px',
  mt: '4px',
  height: 'fit-content',
};

export const getTaskActionsStyles = () => ({
  width: '40px',
  height: '40px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  opacity: 1,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: COLORS.blue[50],
  },
  '&.Mui-disabled': {
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    opacity: 0.6,
    cursor: 'default',
  },
});
