import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { H12_TIME_FORMAT, H24_TIME_FORMAT } from '../../../../../shared/constants';
import FullWidthDialog from '../../../../../components/FullWidthDialog';
import CustomTable from '../../../../../components/Table';
import { getEditAvailabilityColumns } from '../constants';
import EditAvailabilityHeader from './EditAvailabilityHeader';

function EditAvailability({
  cancelEditMode = () => {},
  closeDialog = () => {},
  disabledDays = {},
  isLoading = true,
  openEditMode = false,
  setShowMultiplyMode = () => {},
  showMultiplyMode = false,
  startDate = '',
  submitCallback = () => {},
  selectedDate = {},
  availabilityData = [],
  editedAvailabilityData = [],
  setEditedAvailabilityData = () => {},
}) {
  const apiRef = useGridApiRef();
  const [checkedRows, setCheckedRows] = useState([]);
  useEffect(() => {
    if (editedAvailabilityData) {
      setCheckedRows(
        editedAvailabilityData
          ?.filter(
            row =>
              row.schedule?.filter(scheduleEntry => scheduleEntry.startTime !== null)?.length > 0,
          )
          ?.map(row => row.id),
      );
    }
  }, [editedAvailabilityData]);

  const updateScheduleTime = useCallback(
    ({ formatTimeToString, rowId, timeKey, valueIndex }) => {
      if (editedAvailabilityData) {
        setEditedAvailabilityData(prevData => {
          const availabilityPrevData = [...prevData];
          return availabilityPrevData.map(availabilityDataEntry => {
            if (availabilityDataEntry.id === rowId) {
              return {
                ...availabilityDataEntry,
                schedule: availabilityDataEntry.schedule.map(
                  (rowScheduleEntry, rowScheduleEntryIndex) => {
                    if (rowScheduleEntryIndex === valueIndex) {
                      return {
                        ...rowScheduleEntry,
                        [timeKey]: formatTimeToString,
                      };
                    }
                    return rowScheduleEntry;
                  },
                ),
              };
            }
            return availabilityDataEntry;
          });
        });
      }
    },
    [editedAvailabilityData, setEditedAvailabilityData],
  );

  const updateAllSchedule = useCallback(
    schedule => setEditedAvailabilityData(schedule),
    [setEditedAvailabilityData],
  );

  const handleStartTimeChange = useCallback(
    (rowId, newValue, valueIndex) => {
      const selectedRow = editedAvailabilityData.find(row => row.id === rowId);
      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const formatTimeToString = selectedTime.format(H24_TIME_FORMAT);
      const end = moment(selectedRow?.schedule[valueIndex]?.endTime, H24_TIME_FORMAT);

      let conflictedAvailabilities = false;

      if (selectedRow?.schedule?.length > 1 && valueIndex === 1) {
        const previousAvailabilityEntryEndTime = moment(
          selectedRow?.schedule[0]?.endTime,
          H24_TIME_FORMAT,
        );
        if (selectedTime.isBefore(previousAvailabilityEntryEndTime)) {
          conflictedAvailabilities = true;
        }
      }

      if (selectedTime.isSameOrBefore(end) && !conflictedAvailabilities) {
        updateScheduleTime({ formatTimeToString, rowId, timeKey: 'startTime', valueIndex });
      }
    },
    [editedAvailabilityData, updateScheduleTime],
  );

  const handleEndTimeChange = useCallback(
    (rowId, newValue, valueIndex) => {
      const formatTimeToString = moment(newValue, H12_TIME_FORMAT).format(H24_TIME_FORMAT);
      const selectedRow = editedAvailabilityData.find(row => row.id === rowId);
      const startTime = selectedRow?.schedule[valueIndex]?.startTime;

      const selectedTime = moment(newValue, H12_TIME_FORMAT);
      const start = moment(startTime, H12_TIME_FORMAT);

      let conflictedAvailabilities = false;

      if (selectedRow?.schedule?.length > 1 && valueIndex === 0) {
        const nextAvailabilityEntryStartTime = moment(
          selectedRow?.schedule[1]?.startTime,
          H24_TIME_FORMAT,
        );
        if (selectedTime.isAfter(nextAvailabilityEntryStartTime)) {
          conflictedAvailabilities = true;
        }
      }

      if (startTime && selectedTime.isSameOrAfter(start) && !conflictedAvailabilities) {
        updateScheduleTime({ formatTimeToString, rowId, timeKey: 'endTime', valueIndex });
      }
    },
    [editedAvailabilityData, updateScheduleTime],
  );

  const handleCheckboxChange = useCallback(
    rowId => {
      if (editedAvailabilityData) {
        setEditedAvailabilityData(prevData => {
          const availabilityPrevData = [...prevData];

          return availabilityPrevData.map(availabilityDataEntry => {
            if (availabilityDataEntry.id === rowId) {
              if (availabilityDataEntry.schedule[0].startTime === null) {
                return {
                  ...availabilityDataEntry,
                  schedule: [
                    ...availabilityDataEntry.schedule.map(scheduleEntry => ({
                      ...scheduleEntry,
                      startTime: '8:00 AM',
                      endTime: '6:00 PM',
                    })),
                  ],
                };
              } else {
                return {
                  ...availabilityDataEntry,
                  schedule: [
                    ...availabilityDataEntry.schedule.map(scheduleEntry => ({
                      ...scheduleEntry,
                      startTime: null,
                      endTime: null,
                    })),
                  ],
                };
              }
            }
            return availabilityDataEntry;
          });
        });
      }
    },
    [editedAvailabilityData, setEditedAvailabilityData],
  );

  const addScheduleEntry = useCallback(
    rowId => {
      setEditedAvailabilityData(prevData => {
        const availabilityPrevData = [...prevData];

        return availabilityPrevData.map(availabilityDataEntry => {
          if (availabilityDataEntry.id === rowId) {
            const previousAvailabilityDataEntry =
              availabilityDataEntry.schedule[availabilityDataEntry.schedule.length - 1];
            return {
              ...availabilityDataEntry,
              schedule: [
                ...availabilityDataEntry.schedule,
                {
                  effectiveStartDate: previousAvailabilityDataEntry.effectiveStartDate,
                  endTime: moment(previousAvailabilityDataEntry.endTime, H24_TIME_FORMAT)
                    .add(30, 'minutes')
                    .format(H24_TIME_FORMAT),
                  startTime: moment(previousAvailabilityDataEntry.endTime, H24_TIME_FORMAT)
                    .add(15, 'minutes')
                    .format(H24_TIME_FORMAT),
                },
              ],
            };
          }
          return availabilityDataEntry;
        });
      });
    },
    [setEditedAvailabilityData],
  );

  const removeScheduleEntry = useCallback(
    rowId => {
      setEditedAvailabilityData(prevData => {
        const availabilityPrevData = [...prevData];

        return availabilityPrevData.map(availabilityDataEntry => {
          if (availabilityDataEntry.id === rowId) {
            return {
              ...availabilityDataEntry,
              schedule: availabilityDataEntry?.schedule?.slice(
                0,
                availabilityDataEntry?.schedule?.length - 1,
              ),
            };
          }
          return availabilityDataEntry;
        });
      });
    },
    [setEditedAvailabilityData],
  );

  const isPastAvailability = editedAvailabilityData.length > 1 && selectedDate?.infoType === 0;

  const columns = useMemo(
    () =>
      getEditAvailabilityColumns({
        checkedRows,
        handleCheckboxChange,
        handleStartTimeChange,
        handleEndTimeChange,
        startDate,
        isPastAvailability,
        disabledDays,
        addScheduleEntry,
        removeScheduleEntry,
      }),
    [
      checkedRows,
      handleCheckboxChange,
      handleStartTimeChange,
      handleEndTimeChange,
      startDate,
      isPastAvailability,
      disabledDays,
      addScheduleEntry,
      removeScheduleEntry,
    ],
  );

  const isScheduleChanged = useMemo(() => {
    return JSON.stringify(availabilityData) !== JSON.stringify(editedAvailabilityData);
  }, [availabilityData, editedAvailabilityData]);

  return (
    <FullWidthDialog
      cancelButtonName="cancel"
      cancelCallback={cancelEditMode}
      backButtonCallback={cancelEditMode}
      disabledSubmit={!isScheduleChanged}
      submitButtonName="Check Scheduling Updates"
      submitCallback={submitCallback}
      title="Edit Mode"
      openDialog={openEditMode}
      disableAllActions={isLoading}
    >
      <Box sx={{ padding: '30px 40px' }}>
        <EditAvailabilityHeader
          closeDialog={closeDialog}
          disabledDays={disabledDays}
          isLoading={isLoading}
          setShowMultiplyMode={setShowMultiplyMode}
          showMultiplyMode={showMultiplyMode}
          startDate={startDate}
          submitAvailability={updateAllSchedule}
        />
        <CustomTable
          apiRef={apiRef}
          columns={columns}
          headerHeight={44}
          isLoading={isLoading}
          rows={editedAvailabilityData}
          withoutPagination
          rowIdField="id"
        />
      </Box>
    </FullWidthDialog>
  );
}

EditAvailability.propTypes = {
  cancelEditMode: func,
  closeDialog: func,
  disabledDays: instanceOf(Object),
  isLoading: bool,
  openEditMode: bool,
  setShowMultiplyMode: func,
  showMultiplyMode: bool,
  startDate: string,
  submitCallback: func,
  selectedDate: instanceOf(Object),
  availabilityData: instanceOf(Array),
  editedAvailabilityData: instanceOf(Array),
  setEditedAvailabilityData: func,
};

export default React.memo(EditAvailability);