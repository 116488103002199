import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';
import { parseVisitData } from '../../components/VisitInfoTypeProvider/utils';

export const COMMON_API_KEY = 'commonApi';

const skillsSchemaTypesNames = {
  gender: 'Gender',
  language: 'Language',
  skill: 'Skill',
};

const commonApi = createApi({
  reducerPath: COMMON_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: builder => ({
    getLocationsList: builder.query({
      query: () => ({
        url: '/teams',
        method: 'GET',
      }),
      transformResponse: response => response.sort((a, b) => a.name.localeCompare(b.name)),
    }),
    getSkillsLibrary: builder.query({
      query: () => ({
        url: '/caregiver/schema/skills',
        method: 'GET',
      }),
      transformResponse: response => {
        const skills = response.schema.filter(({ type }) => type === skillsSchemaTypesNames.skill);
        const genders = response.schema.filter(
          ({ type }) => type === skillsSchemaTypesNames.gender,
        );
        const language = response.schema.filter(
          ({ type }) => type === skillsSchemaTypesNames.language,
        );
        return { genders, language, skills };
      },
    }),
    getFiltersData: builder.query({
      query: () => ({
        url: 'careplan/schema',
        method: 'GET',
      }),
      transformResponse: response => {
        const filters = { ...response };
        if (filters?.visitTypes) {
          filters.shortVisitTypes = filters.visitTypes?.map(({ name }) => name);
        }
        if (filters?.levelsOfAssistance) {
          filters.levelsOfAssistance = filters.levelsOfAssistance?.map(({ name }) => name);
        }
        if (filters?.weekdays) {
          filters.weekdays = filters.weekdays?.map(name => ({
            name,
            value: name.slice(0, 2).toUpperCase(),
          }));
        }
        return filters;
      },
    }),
    getVisitDetails: builder.query({
      query: ({ clientId, date, visitId }) => ({
        url: `visit/info/scheduled/${clientId}/${visitId}`,
        method: 'GET',
        params: { date },
      }),
      providesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
      transformResponse: response => parseVisitData(response),
    }),
    checkShadowCaregiverAvailability: builder.mutation({
      query: payload => ({
        url: `check/caregivers`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    checkPreferredCaregiverAvailability: builder.mutation({
      query: payload => ({
        url: `check/caregivers`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    applyShadowCaregiver: builder.mutation({
      query: visit => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    applyPreferredCaregiver: builder.mutation({
      query: visit => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    unmarkShadowCaregiver: builder.mutation({
      query: visit => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    unmarkPreferredCaregiver: builder.mutation({
      query: visit => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    updateVisitDetails: builder.mutation({
      query: visit => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    checkVisitRescheduling: builder.query({
      query: data => ({
        url: 'visit/info/scheduled/rescheduling/check',
        method: 'POST',
        body: data,
      }),
      transformResponse: (response, meta, data) => {
        const eventsDates = response?.rescheduledEvents
          ? Object.keys(response?.rescheduledEvents)
          : [];
        const mainEvent = response?.rescheduledEvents
          ? Object.values(response?.rescheduledEvents)
              .flat()
              .find(visit => visit.id === data.eventId)
          : {};
        const parsed = eventsDates.reduce((obj, item) => {
          const visitsData = response?.rescheduledEvents[item];
          return Object.assign(obj, {
            [item]: visitsData.filter(visit => visit.id !== data.eventId),
          });
        }, {});

        return {
          affectedVisits: parsed,
          nonShadowVisitsAnyMore: response?.nonShadowVisitsAnyMore,
          requestId: response?.requestId,
          rescheduledEvent: mainEvent,
          reschedulingStatus: response?.visitsWithIssues[0]?.status,
        };
      },
    }),
    confirmVisitRescheduling: builder.mutation({
      query: requestId => ({
        url: `visit/info/scheduled/rescheduling/confirm/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    checkRestoreCancelledVisit: builder.query({
      query: eventId => ({
        url: `visit/info/scheduled/restore-cancelled/check/${eventId}`,
        method: 'POST',
      }),
      transformResponse: (response, meta, eventId) => {
        const eventsDates = response?.rescheduledEvents
          ? Object.keys(response?.rescheduledEvents)
          : [];
        const mainEvent = response?.rescheduledEvents
          ? Object.values(response?.rescheduledEvents)
              .flat()
              .find(visit => visit.id === eventId)
          : {};
        const parsed = eventsDates.reduce((obj, item) => {
          const visitsData = response?.rescheduledEvents[item];
          return Object.assign(obj, {
            [item]: visitsData.filter(visit => visit.id !== eventId),
          });
        }, {});

        return {
          requestId: response?.requestId,
          rescheduledEvent: mainEvent,
          affectedVisits: parsed,
        };
      },
    }),
    confirmRestoreCancelledVisit: builder.mutation({
      query: requestId => ({
        url: `visit/info/scheduled/rescheduling/confirm/${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    restoreCancelledVisitNonOptimizedPeriod: builder.mutation({
      query: ({ date, visitId }) => ({
        url: `visit/info/scheduled/restore-cancelled/${date}/${visitId}`,
        method: 'POST',
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    getCancellationReasons: builder.query({
      query: () => ({
        url: `visit/info/cancellation/schema`,
        method: 'GET',
      }),
    }),
    applyStatus: builder.mutation({
      query: visit => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
  }),
});

export const {
  useGetFiltersDataQuery,
  useGetLocationsListQuery,
  useGetSkillsLibraryQuery,
  useGetVisitDetailsQuery,
  useUpdateVisitDetailsMutation,
  useConfirmVisitReschedulingMutation,
  useCheckVisitReschedulingQuery,
  useConfirmRestoreCancelledVisitMutation,
  useRestoreCancelledVisitNonOptimizedPeriodMutation,
  useCheckRestoreCancelledVisitQuery,
  useGetCancellationReasonsQuery,
  useCheckShadowCaregiverAvailabilityMutation,
  useApplyShadowCaregiverMutation,
  useUnmarkShadowCaregiverMutation,
  useCheckPreferredCaregiverAvailabilityMutation,
  useApplyPreferredCaregiverMutation,
  useUnmarkPreferredCaregiverMutation,
  useApplyStatusMutation,
} = commonApi;

export default commonApi;
