import { Box } from '@mui/material';
import { string } from 'prop-types';
import React, { useContext, useMemo } from 'react';
import {
  tasksStatusesColorSchemes,
  tasksStatusesMap,
  visitStatusesMap,
} from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import StatusesCustomSelect from '../../../../components/StatusesSelect';

export default function StatusAction({ id = '', status = '' }) {
  const { temporaryVisitData, updateTemporaryVisitData } = useContext(VisitInfoTypeContext);

  const changeStatus = value => {
    const currentTask = temporaryVisitData?.tasks?.find(task => task.id === id);
    const currentTaskIndex = temporaryVisitData?.tasks?.findIndex(task => task.id === id);
    const newTasks = [...temporaryVisitData?.tasks];
    newTasks[currentTaskIndex] = { ...currentTask, status: value };
    updateTemporaryVisitData({ tasks: newTasks });
  };

  const disableToChange = useMemo(
    () =>
      ![visitStatusesMap.inProgress, visitStatusesMap.completed].includes(
        temporaryVisitData?.status,
      ),
    [temporaryVisitData?.status],
  );

  const statusKey = tasksStatusesMap[status];
  return (
    <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', mt: '4px' }}>
      <StatusesCustomSelect
        colorScheme={tasksStatusesColorSchemes[statusKey]}
        disabled={false}
        id="status"
        options={Object.keys(tasksStatusesMap)}
        isLoading={false}
        selectedValue={status}
        setValue={changeStatus}
      />
    </Box>
  );
}

StatusAction.propTypes = {
  id: string,
  status: string,
  tasksType: string,
};
