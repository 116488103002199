import { Box, Collapse, Typography } from '@mui/material';
import {
  useApplyStatusMutation,
  useCheckRestoreCancelledVisitQuery,
  useConfirmRestoreCancelledVisitMutation,
  useRestoreCancelledVisitNonOptimizedPeriodMutation,
} from '../../../api/commonApi/api';
import { bool, func, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AlertContext from '../../../components/Alert';
import Loader from '../../../components/Loader';
import {
  canRestoreCanceledVisitStatuses,
  onlyCancelAllowsVisitStatuses,
  unVerifyVisitStatuses,
  visitStatuses,
} from '../constants';
import { infoKeyField, infoRowWrapperStyles } from '../styles';
import { getBgColor } from '../utils';
import CheckReschedulingTable from './CheckReschedulingTable';
import StatusChangingConfirmation from './StatusChangingConfirmation';
import VisitStatusSelect from './StatusSelect';
import { VisitInfoTypeContext } from '../../../shared/context';
import { api } from '../../../api/index';

export default function StatusSelectorField({
  cancelNewStatus = () => {},
  changeStatus = () => {},
  closeNewStatusConfirmation = () => {},
  date = '',
  disabledSelect = false,
  initialStatus = '',
  isFutureVisit = false,
  isNotAllocatedVisit = false,
  isUpdatingVisitStatus = false,
  needToConfirmStatus = false,
  reFetchCalendarEvents = () => {},
  scheduledVisit = false,
  visitId = '',
}) {
  const { setAlert } = useContext(AlertContext);
  const { temporaryVisitData } = useContext(VisitInfoTypeContext);
  const [openCheckReschedulingDialog, setOpenCheckReschedulingDialog] = useState(false);
  const userProfile = JSON.parse(localStorage.getItem('user'));

  const {
    data: reschedulingData,
    isLoading: isLoadingReschedulingData,
    isFetching: isFetchingReschedulingData,
    error: checkVisitReschedulingError,
  } = useCheckRestoreCancelledVisitQuery(visitId, {
    refetchOnMountOrArgChange: true,
    skip: !openCheckReschedulingDialog,
  });
  const [confirmRestoreCancelledVisit, { error: confirmVisitReschedulingError }] =
    useConfirmRestoreCancelledVisitMutation();
  const [
    restoreCancelledVisitNonOptimizedPeriod,
    { error: restoreCancelledVisitNonOptimizedPeriodError },
  ] = useRestoreCancelledVisitNonOptimizedPeriodMutation();
  const [applyStatus, { isLoading: applyStatusLoading, error: updateStatusError }] =
    useApplyStatusMutation();

  useEffect(() => {
    const errorData =
      checkVisitReschedulingError ||
      confirmVisitReschedulingError ||
      restoreCancelledVisitNonOptimizedPeriodError ||
      updateStatusError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    checkVisitReschedulingError,
    confirmVisitReschedulingError,
    restoreCancelledVisitNonOptimizedPeriodError,
    updateStatusError,
    setAlert,
  ]);

  const availableVisitsOptions = useMemo(() => {
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      userProfile?.roles?.includes('ADMIN') &&
      isFutureVisit
    ) {
      return canRestoreCanceledVisitStatuses;
    }
    if (initialStatus?.toLowerCase() === 'verified' && userProfile?.roles?.includes('ADMIN')) {
      return unVerifyVisitStatuses;
    }
    if (initialStatus?.toLowerCase() !== 'verified' && (isNotAllocatedVisit || isFutureVisit)) {
      return onlyCancelAllowsVisitStatuses;
    }
    return visitStatuses;
  }, [initialStatus, isNotAllocatedVisit, isFutureVisit, userProfile]);

  const closeRescheduling = useCallback(() => {
    setOpenCheckReschedulingDialog(false);
    cancelNewStatus();
  }, [cancelNewStatus]);

  const confirmReschedulingVisit = useCallback(() => {
    if (scheduledVisit) {
      confirmRestoreCancelledVisit(reschedulingData?.requestId)
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    } else {
      restoreCancelledVisitNonOptimizedPeriod({ date, visitId })
        .unwrap()
        .then(() => {
          setOpenCheckReschedulingDialog(false);
          reFetchCalendarEvents();
        });
    }
    closeNewStatusConfirmation();
  }, [
    closeNewStatusConfirmation,
    confirmRestoreCancelledVisit,
    date,
    reFetchCalendarEvents,
    reschedulingData,
    restoreCancelledVisitNonOptimizedPeriod,
    scheduledVisit,
    visitId,
  ]);

  const onConfirmNewStatus = async () => {
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      temporaryVisitData?.status?.toLowerCase() === 'scheduled' &&
      scheduledVisit
    ) {
      setOpenCheckReschedulingDialog(true);
      return '';
    }
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      temporaryVisitData?.status?.toLowerCase() === 'scheduled' &&
      !scheduledVisit
    ) {
      confirmReschedulingVisit();
      return '';
    }
    applyStatus(temporaryVisitData)
      .unwrap()
      .then(() => reFetchCalendarEvents());
    if (
      initialStatus?.toLowerCase() === 'scheduled' &&
      temporaryVisitData?.status?.toLowerCase() === 'canceled'
    ) {
      const payload = {
        id: temporaryVisitData.careprogramId,
      };
      const careProgramData = await api('GET', 'crud', 'careProgram', payload);
      if (careProgramData.error) {
        setAlert({
          careProgramData,
          type: 'error',
        });
      } else {
        const newDateChange = {canceled: true};
        let dateChanges = {};
        if (careProgramData?.data?.dateChanges){
          dateChanges = {...careProgramData?.data?.dateChanges}
        }
        dateChanges[date.slice(0,10)] = newDateChange;
        careProgramData.data.dateChanges = dateChanges;
        careProgramData.data.oneTimeChange = true;
        const response = await api('PATCH', 'crud', 'careProgram', careProgramData?.data);
        if (response.error) {
          setAlert({
            response,
            type: 'error',
          });
        }
      }
    }
    closeNewStatusConfirmation();
    return '';
  };

  const isReschedulingDataLoading = useMemo(
    () => isLoadingReschedulingData || isFetchingReschedulingData,
    [isLoadingReschedulingData, isFetchingReschedulingData],
  );

  if (isUpdatingVisitStatus) {
    return <Loader />;
  }

  return (
    <Box>
      {applyStatusLoading && <Loader />}
      <Box sx={infoRowWrapperStyles}>
        <Typography sx={infoKeyField}>Status:</Typography>
        <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
          <VisitStatusSelect
            bgColor={getBgColor(temporaryVisitData?.status)}
            disabled={disabledSelect}
            initialVisitStatus={initialStatus}
            isLoading={false}
            options={availableVisitsOptions}
            selectedValue={temporaryVisitData?.status || ''}
            setValue={changeStatus}
          />
        </Box>
      </Box>
      <Collapse in={needToConfirmStatus} timeout={300}>
        <StatusChangingConfirmation
          cancelNewStatus={cancelNewStatus}
          confirmNewStatus={onConfirmNewStatus}
          newStatus={temporaryVisitData?.status}
          newCancellationReason={temporaryVisitData?.cancellationReason}
        />
      </Collapse>
      {openCheckReschedulingDialog && (
        <CheckReschedulingTable
          closeRescheduling={closeRescheduling}
          confirmReschedulingVisit={confirmReschedulingVisit}
          isReschedulingDataLoading={isReschedulingDataLoading}
          openCheckReschedulingDialog={openCheckReschedulingDialog}
          reschedulingData={reschedulingData}
        />
      )}
    </Box>
  );
}

StatusSelectorField.propTypes = {
  cancelNewStatus: func,
  changeStatus: func,
  closeNewStatusConfirmation: func,
  date: string,
  disabledSelect: bool,
  initialStatus: string,
  isFutureVisit: bool,
  isNotAllocatedVisit: bool,
  isUpdatingVisitStatus: bool,
  needToConfirmStatus: bool,
  reFetchCalendarEvents: func,
  scheduledVisit: bool,
  visitId: string,
};
