import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import {
  CLIENTS_STATUSES,
  CLIENTS_STATUSES_MAP
} from '../../../../../pages/Clients/constants';
import React, { useContext, useMemo, useState } from 'react';
import { SHORT_DATE_FORMAT, backgroundColorSchemeMap } from '../../../../../shared/constants';
import { getStatusChangingInfo } from '../../../../../shared/utils/common';
import Badge from '../../../../../components/Badge';
import StatusesCustomSelect from '../../../../../components/StatusesSelect';
import { ClientDetailsContext, ClientProfilesContext } from '../../../context';
import {
  inactivePeriodStyles,
  profileReactivationButtonStyles,
} from '../styles';
import ClientDeactivationDialog from './ClientDeactivationDialog';
import ClientSuspendDialog from './ClientSuspendDialog';
import EffectiveDateModal from './EffectiveDateModal';

export default function StatusSelector() {
  const [statusAction, setStatusAction] = useState(null);
  const {
    clientData,
    setClientData,
  } = useContext(ClientProfilesContext);
  const { isEdit } = useContext(ClientDetailsContext);
  const clientStatus = clientData?.status;
  const [openEffectiveDateDialog, setOpenEffectiveDateDialog] = useState(false);
  const [openDeactivationModal, setOpenDeactivationModal] = useState(false);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [date, setDate] = useState(moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [endDate, setEndDate] = useState(moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));

  const updatePendingData = (newStatus) =>{
    const statusChanges = {};
    statusChanges[moment(date).format('YYYY-MM-DD')] = newStatus;
    if (newStatus === 'Suspended') {
      if (endDate > moment()) {
        statusChanges[moment(endDate).format('YYYY-MM-DD')] = 'Active';
      }
    }
    const pendingChanges = {
      ...clientData.pendingChanges,
      status: statusChanges,
    };
    const newProfileData = { ...clientData, pendingChanges: pendingChanges };
    setClientData({...newProfileData});
  }

  const updateClientData = (newStatus) =>{
    const newProfileData = { ...clientData, status: newStatus };
    setClientData({...newProfileData});
  }

  const accountChangesInfo = useMemo(
    () => getStatusChangingInfo(clientData),
    [clientData],
  );

  const changeStatus = (value) => {
    if (value === CLIENTS_STATUSES_MAP.suspended) {
      setOpenSuspendModal(true);
      setStatusAction('suspend');
      return '';
    }
    if (value === CLIENTS_STATUSES_MAP.inactive) {
      setOpenEffectiveDateDialog(true);
      setStatusAction('deactivate');
      return '';
    }
    return '';
  };

  const confirmNewStatus = () => {
    let thisStatusAction = statusAction;
    if (!statusAction){
      thisStatusAction = "active";
    }
    if (clientStatus === 'Active' && thisStatusAction === 'suspend') {
      updatePendingData('Suspended');
    }
    if (clientStatus === 'Active' && thisStatusAction === 'deactivate') {
      updatePendingData('Inactive');
    }
    if (clientStatus === 'Suspended' && thisStatusAction === 'active') {
      updateClientData('Active');
    }
    if (clientStatus === 'Inactive' && thisStatusAction === 'active') {
      updateClientData('Active');
    }
    setOpenDeactivationModal(false);
    setOpenSuspendModal(false);
  };
  const submitEffectiveDateDialog = () => {
    setOpenEffectiveDateDialog(false);
    setOpenDeactivationModal(true);
  };
  const isStatusChangedDisabled =
    clientData?.inactiveFrom &&
    moment().isBefore(moment(clientData?.inactiveFrom));

  return (
    <Box sx={{ minWidth: '250px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: '250px',
        }}
      >
        {isEdit && clientStatus === 'Active' ? (
          <StatusesCustomSelect
            colorScheme={backgroundColorSchemeMap[clientStatus?.toLowerCase()]}
            disabled={isStatusChangedDisabled}
            id="status"
            options={CLIENTS_STATUSES.map((option) => option.name)}
            isLoading={false}
            selectedValue={clientStatus}
            setValue={changeStatus}
          />
        ) : (
          <Badge
            colorScheme={backgroundColorSchemeMap[clientStatus?.toLowerCase()]}
            label={clientStatus}
          />
        )}
        <Typography sx={{ fontStyle: 'italic', ml: '10px' }}>
          {accountChangesInfo}
        </Typography>
        {clientStatus === 'Suspended' &&
          clientData?.suspendedFrom && (
            <Typography sx={inactivePeriodStyles} variant="body1">
              {`${moment(clientData?.suspendedFrom).format(
                SHORT_DATE_FORMAT,
              )} - ${moment(clientData?.resumedFrom).format(
                SHORT_DATE_FORMAT,
              )}`}
            </Typography>
          )}
      </Box>
      <EffectiveDateModal
        openEffectiveDateDialog={openEffectiveDateDialog}
        closeEffectiveDateDialog={() => setOpenEffectiveDateDialog(false)}
        submitEffectiveDateDialog={submitEffectiveDateDialog}
        date={date}
        selectDate={(newValue) => setDate(newValue)}
      />
      <ClientDeactivationDialog
        openDeactivationModal={openDeactivationModal}
        closeDeactivationModal={() => setOpenDeactivationModal(false)}
        confirmDeactivation={confirmNewStatus}
        date={date}
      />
      <ClientSuspendDialog
        openSuspendModal={openSuspendModal}
        closeSuspendModal={() => setOpenSuspendModal(false)}
        confirmSuspend={confirmNewStatus}
        date={date}
        setDate={setDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      {isEdit && ['Suspended', 'Inactive'].includes(clientStatus) && (
        <Button
          sx={profileReactivationButtonStyles}
          color="secondary"
          variant="outlined"
          onClick={confirmNewStatus}
          disableElevation
          disabled={clientData?.activeFrom}
        >
          {clientStatus === 'Suspended' ? 'resume services' : 'reactivate'}
        </Button>
      )}
    </Box>
  );
}
