import { Box, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import { personalInformationFieldsMap } from '../constants';
import { instanceOf, string } from 'prop-types';

function ClientData({ clientName = '', userData = {} }) {
  const userInfo = {
    client: clientName,
    address: [
      userData?.street,
      userData?.city,
      userData?.state,
      userData?.zipCode,
      userData?.country,
    ]
      .filter(addressLine => addressLine && addressLine.length > 0)
      .join(', '),
  };

  return (
    <div>
      {personalInformationFieldsMap?.map(({ name, key }) => (
        <Box key={name} sx={{ display: 'flex', mb: '16px' }}>
          <Typography
            variant="body1"
            sx={{ color: COLORS.blue[500], mr: '60px', minWidth: '70px' }}
          >
            {name}:
          </Typography>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {userInfo[key] || ''}
          </Typography>
        </Box>
      ))}
    </div>
  );
}

ClientData.propTypes = {
  clientName: string,
  userData: instanceOf(Object),
};

export default React.memo(ClientData);
