/* eslint-disable no-param-reassign */
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { H12_TIME_FORMAT, SHORT_DATE_FORMAT } from '../../../../../../shared/constants';
import HistoryBulletList from '../../../../../../components/HistoryBulletList';

export const ptoHistoryColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.dateChanged}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: (params) => (
      <HistoryBulletList changes={params.row} />
    ),
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.updatedBy}</Typography>
      </Box>
    ),
  },
];
export const addDateTimeObjects = (changesArray) => {
  const newChangesArray = changesArray.map((change) => {
    if (
      change.propertyName === 'fromDate' ||
      change.propertyName === 'toDate' ||
      change.propertyName === 'createdDate' ||
      change.propertyName === 'modifiedDate'
    ) {
      change.before = change.from;
      change.after = change.to;
      change.from = moment(change.from).format(SHORT_DATE_FORMAT);
      change.to = moment(change.to).format(SHORT_DATE_FORMAT);
    }
    return change;
  });

  const fromDateChange = changesArray.find(
    (change) => change.propertyName === 'fromDate',
  );
  const toDateChange = changesArray.find(
    (change) => change.propertyName === 'toDate',
  );
  if (fromDateChange) {
    const fromTimeChange = {
      type: fromDateChange.type,
      operation: fromDateChange.operation,
      propertyName: 'fromTime',
      from: moment(fromDateChange.before).format(H12_TIME_FORMAT),
      to: moment(fromDateChange.after).format(H12_TIME_FORMAT),
    };
    newChangesArray.push(fromTimeChange);
  }

  if (toDateChange) {
    const toTimeChange = {
      type: toDateChange.type,
      operation: toDateChange.operation,
      propertyName: 'toTime',
      from: moment(toDateChange.before).format(H12_TIME_FORMAT),
      to: moment(toDateChange.after).format(H12_TIME_FORMAT),
    };
    newChangesArray.push(toTimeChange);
  }

  return newChangesArray;
};
