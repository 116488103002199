import { Box, Typography } from '@mui/material';
import React from 'react';

function FailedResult() {
  return (
    <Box>
      <Typography sx={{ ml: '6px' }} variant="body1">
        The visit cannot be rescheduled
      </Typography>
    </Box>
  );
}

export default FailedResult;
