import { useGetHolidaysQuery } from '../../../../api/Administration/api';
import { useGetCaregiverCalendarQuery } from '../../../../api/Caregivers/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import { checkIfIsHoliday } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import VisitManagementProvider from '../../../../components/VisitInfoTypeProvider/VisitManagementProvider';
import CaregiverCalendarView from './CaregiverCalendarView';
import { CaregiverCalendarHeader } from './components/CaregiverCalendarHeader';
import './index.css';
import Loader from '../../../../components/Loader';

export default function CaregiverCalendar() {
  const [calendarDate, setCalendarDate] = useState(null);
  const { id } = useParams();

  const today = moment();
  const mondayDate = today.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT);
  const formattedDate = calendarDate
    ? moment(calendarDate)?.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT)
    : null;

  const {
    data: caregiverEvents,
    refetch: refetchCalendarData,
    error: getCaregiverCalendarError,
    isLoading: isLoadingCalendar,
  } = useGetCaregiverCalendarQuery(
    {
      caregiverId: id,
      date: formattedDate || mondayDate,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { setAlert } = useContext(AlertContext);
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();

  useEffect(() => {
    if (getHolidaysError || getCaregiverCalendarError) {
      const errorData = getHolidaysError || getCaregiverCalendarError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getHolidaysError, getCaregiverCalendarError, setAlert]);

  const parsedEvents = caregiverEvents?.map(caregiverEvent => {
    const thisVisit = { ...caregiverEvent };
    const arrivalTime = moment(thisVisit['arrivalStart']);
    const start = moment(thisVisit?.date)
      .set({
        hour: arrivalTime?.hours(),
        minute: arrivalTime?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const end = moment(start).add(thisVisit.duration, 'minutes').toDate();
    return {
      ...thisVisit,
      arrivalTime: arrivalTime.format('YYYY-MM-DDTHH:mm:ss'),
      start,
      end,
      arrivalTimeDuration: 60,
    };
  });

  useEffect(() => {
    const now = new Date();
    const timer = setTimeout(() => {
      const currentTimeIndicator = document.querySelector('.rbc-current-time-indicator');

      currentTimeIndicator?.classList.add('custom-time-indicator');
      const currentTimeIndicator1 = document.querySelector('.custom-time-indicator');
      const currentTime = now.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
      });
      currentTimeIndicator1?.setAttribute('data-current-time', currentTime);
    }, 0);
    return () => clearTimeout(timer);
  }, [parsedEvents]);
  const calendarHeader = ({ date }) => {
    const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);
    return (
      <CaregiverCalendarHeader
        date={date}
        holiday={isDateHoliday?.name ? date : null}
        holidayName={isDateHoliday?.name || ''}
      />
    );
  };

  const dayPropGetter = useCallback(
    date => {
      const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);

      const dayProps = {};

      if (isDateHoliday?.name) {
        dayProps.className = 'holiday';
      }

      return dayProps;
    },
    [holidays, calendarDate],
  );
  const slotPropGetter = useCallback(
    date => {
      const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);
      const slotTime = moment(date);
      const slotProps = {};
      let isSlotAvailable = false;
      if (!isDateHoliday) {
        // TODO: Fetch availabilities
        []?.forEach(availability => {
          const startTime = moment(availability.availabilities.startTime, 'h:mm A').subtract(
            5,
            'minutes',
          );
          const endTime = moment(availability.availabilities.endTime, 'h:mm A');
          const todaysStartTime = moment(availability.date).set({
            hour: startTime?.hours(),
            minute: startTime?.minutes(),
            second: 0,
            millisecond: 0,
          });
          const todaysEndTime = moment(availability.date).set({
            hour: endTime?.hours(),
            minute: endTime?.minutes(),
            second: 0,
            millisecond: 0,
          });
          if (slotTime.isBetween(todaysStartTime, todaysEndTime)) {
            isSlotAvailable = true;
            slotProps.style = {
              background: '#fff',
            };
          }
        });
      }
      if (!isSlotAvailable) {
        slotProps.className = 'outside-availability';
      }

      return slotProps;
    },
    [calendarDate, holidays],
  );
  return (
    <VisitManagementProvider reFetchCalendarEvents={refetchCalendarData}>
      {isLoadingCalendar ? (
        <Loader />
      ) : (
        <CaregiverCalendarView
          calendarHeader={calendarHeader}
          calendarEvents={parsedEvents}
          calendarDate={calendarDate}
          dayPropGetter={dayPropGetter}
          setCalendarDate={setCalendarDate}
          // TODO: Fetch availabilities
          availabilities={[]}
          slotPropGetter={slotPropGetter}
        />
      )}
    </VisitManagementProvider>
  );
}
