export const createOneTimeVisitInitialData = {
  arrivalStart: null,
  arrivalEnd: null,
  isExactTime: false,
  date: null,
  duration: null,
  genderPreference: null,
  preferredSkills: [],
  preferredLanguages: [],
  visitType: {},
  status: 'Scheduled',
  violatedHardConstraints: [],
  violatedSoftConstraints: [],
};

export const createOneTimeVisitSteps = ['Visit Parameters', 'Availability', 'Tasks & Summary'];
