import { Box, Button } from '@mui/material';
import { bool, func } from 'prop-types';
import React from 'react';
import { outlinedButtonStyles, setupActionsWrapperStyles } from './styles';

export default function SetupActions({
  applyPreferredCaregiver = () => {},
  availableToRecheck = false,
  caregiverHasBeenChanged = false,
  caregiverAvailabilityChecked = false,
  checkAvailability = () => {},
  notAvailableToSelect = false,
  onCancel = () => {},
  preferredCaregiverTheSameAsPrimary = false,
}) {
  return (
    <Box sx={setupActionsWrapperStyles}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={onCancel}
        disableElevation
        sx={outlinedButtonStyles}
      >
        Cancel
      </Button>
      {!caregiverAvailabilityChecked && (
        <Button
          color="primary"
          disabled={!caregiverHasBeenChanged}
          variant="contained"
          onClick={checkAvailability}
          disableElevation
          sx={{ minWidth: '188px' }}
        >
          Check availability
        </Button>
      )}
      {!preferredCaregiverTheSameAsPrimary && caregiverAvailabilityChecked && (
        <Button
          color="secondary"
          disabled={!availableToRecheck}
          variant="outlined"
          onClick={checkAvailability}
          disableElevation
          sx={outlinedButtonStyles}
        >
          Recheck
        </Button>
      )}
      {caregiverAvailabilityChecked && (
        <Button
          color="primary"
          disabled={
            !preferredCaregiverTheSameAsPrimary || availableToRecheck || notAvailableToSelect
          }
          variant="contained"
          onClick={applyPreferredCaregiver}
          disableElevation
        >
          Apply
        </Button>
      )}
    </Box>
  );
}

SetupActions.propTypes = {
  applyPreferredCaregiver: func,
  availableToRecheck: bool,
  caregiverHasBeenChanged: bool,
  caregiverAvailabilityChecked: bool,
  checkAvailability: func,
  notAvailableToSelect: bool,
  onCancel: func,
  preferredCaregiverTheSameAsPrimary: bool,
};
