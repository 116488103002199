import { Box, Dialog, DialogTitle, Typography } from '@mui/material';
import Arrow from '../../../../img/icons/arrow_forward.svg';
import { bool, func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { TransitionSlide } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import VisitTaskTable from './VisitTaskTable';
import { taskSelectionModels } from './constants';
import { dialogPaperStyles } from './styles';
import { v4 as uuidv4 } from 'uuid';
import { useGetConfigQuery } from '../../../../api/Config/api';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';

export default function TaskChangesModal({
  actualVisit = {},
  carePlanChanges = {},
  openDialog = false,
  setOpenDialog = () => {},
  updateVisitData = () => {},
  isUpdateCalendarLoading = true,
}) {
  const [openTasks, setOpenTasks] = useState(false);
  const [selectedOption, setSelectedOption] = useState(taskSelectionModels.current);

  const { setAlert } = useContext(AlertContext);

  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: configError,
  } = useGetConfigQuery(['categoryTypes'], {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (configError) {
      const errorData = configError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [setAlert, configError]);

  const updateTasks = () => {
    const tempVisitData = {
      ...actualVisit,
      tasks: actualVisit.tasks.filter(task => !task.careplanId),
      taskDetails: actualVisit.taskDetails.filter(
        taskDetail => actualVisit.tasks.findIndex(task => task.careplanId === taskDetail.id) === -1,
      ),
    };
    updateVisitData({
      ...tempVisitData,
      tasks: [
        ...tempVisitData.tasks,
        ...carePlanChanges.newCarePlanTasks?.map(task => ({
          careplanId: task.id,
          status: 'todo',
          id: uuidv4(),
        })),
      ],
      taskDetails: [...tempVisitData.taskDetails, ...carePlanChanges.newCarePlanTasks],
    });
    setOpenTasks(false);
    setOpenDialog(false);
  };

  const keepCurrentTasks = () => {
    setOpenTasks(false);
    setOpenDialog(false);
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        open={openDialog}
        keepMounted={false}
        TransitionComponent={TransitionSlide}
        PaperProps={{
          sx: { ...dialogPaperStyles },
        }}
      >
        {isUpdateCalendarLoading ? (
          <Loader />
        ) : (
          <>
            <DialogTitle>
              <Typography variant="h4">The Visit was Successfully Moved</Typography>
            </DialogTitle>
            <Typography sx={{ p: '24px' }}>
              What would you like to do with the visit tasks?
            </Typography>
            <Box
              sx={{
                padding: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
            >
              <Box
                onClick={() => {
                  setOpenTasks(true);
                  setSelectedOption(taskSelectionModels.current);
                }}
                sx={{
                  display: 'flex',
                  gap: 2,
                  cursor: 'pointer',
                  p: '10px',
                }}
              >
                <img src={Arrow} alt="arrow" />
                <Typography variant="body1" sx={{ color: COLORS.blue[700], cursor: 'pointer' }}>
                  To keep the current tasks
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  setOpenTasks(true);
                  setSelectedOption(taskSelectionModels.future);
                }}
                sx={{ display: 'flex', gap: 2, cursor: 'pointer', p: '10px' }}
              >
                <img src={Arrow} alt="arrow" />
                <Typography variant="body1" sx={{ color: COLORS.blue[700], cursor: 'pointer' }}>
                  Tasks from the Care Plan according to the new weekday & time
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Dialog>
      {isLoadingConfigs ? (
        <Loader />
      ) : (
        <VisitTaskTable
          tasks={
            selectedOption === taskSelectionModels.current
              ? carePlanChanges?.previousCarePlanTasks?.map(task => {
                  const carePlanTaskDetails = actualVisit?.taskDetails?.find(
                    taskDetail => taskDetail.id === task.careplanId,
                  );
                  const linkedCategory = configData?.categoryTypes?.find(
                    categoryType => categoryType.id === carePlanTaskDetails?.categoryTypes,
                  );
                  return { ...carePlanTaskDetails, ...task, categoryTypesDetails: linkedCategory };
                }) ?? []
              : carePlanChanges?.newCarePlanTasks?.map(task => ({ ...task, status: 'todo' })) ?? []
          }
          openTaskDialog={openTasks}
          setOpenTaskDialog={setOpenTasks}
          updateTasks={
            selectedOption === taskSelectionModels.current ? keepCurrentTasks : updateTasks
          }
        />
      )}
    </div>
  );
}

TaskChangesModal.propTypes = {
  openDialog: bool,
  actualVisit: instanceOf(Object),
  carePlanChanges: instanceOf(Object),
  setOpenDialog: func,
  updateVisitData: func,
  isUpdateCalendarLoading: bool,
};
