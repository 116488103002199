import { Box, Button } from '@mui/material';
import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import FullWidthDialog from '../FullWidthDialog';
import { api } from '../../api';
import AlertContext from '../Alert';
import Loader from '../Loader';
import DateAccordion from './dateAccordion';
import { WebSocketContext } from '../WebsocketProvider/WebsocketProvider';
import RefreshIcon from '@mui/icons-material/Refresh';
import { customDialogButtonStyles } from './styles';
import moment from 'moment';

const OptimizerCheck = ({
  data,
  dataObjName,
  submitFunction,
  open,
  openToggle,
  cancelFunction,
  returnAPIData = false,
}) => {
  const [subscribe, unsubscribe] = useContext(WebSocketContext);
  const { setAlert } = useContext(AlertContext);
  const [optimizerData, setOptimizerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [savedNewVisits, setSavedNewVisits] = useState(false);
  const [savedExistingVisits, setSavedExistingVisits] = useState(false);
  const [deletedVisits, setDeletedVisits] = useState(false);
  const [deletedQueueItems, setSavedQueueItems] = useState(false);

  const getData = async () => {
    const payload = {
      [dataObjName]: data,
    };
    const apiData = await api('POST', 'check', 'optimizer', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (!apiData?.data?.optimized) {
        submitFunction(returnAPIData ? undefined : data);
        setAlert({
          message: 'Nothing to optimize',
          type: 'success',
        });
        setDisabledSubmit(true);
        setSavedNewVisits(false);
        setSavedExistingVisits(false);
        setDeletedVisits(false);
        setSavedQueueItems(false);
        openToggle(false);
      } else {
        setOptimizerData(apiData.data);
      }
      setLoading(false);
    }
  };

  const getQueueData = async queueId => {
    const payload = {
      id: queueId,
    };
    const apiData = await api('GET', 'crud', 'optimizationqueue', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      return apiData.data;
    }
  };

  const splitPayloads = payloads => {
    const listOfPayloads = [];
    let numberOfVisits = 0;
    let numberOfPayloads = 0;
    for (let visit in payloads) {
      if (numberOfVisits === 0) {
        listOfPayloads.push([]);
      }
      if (numberOfVisits === 50) {
        numberOfVisits = 0;
        numberOfPayloads++;
        listOfPayloads.push([]);
      }
      listOfPayloads[numberOfPayloads].push(payloads[visit]);
      numberOfVisits++;
    }
    return listOfPayloads;
  };

  const addVisits = async addedVisits => {
    const listOfPayloads = splitPayloads(addedVisits)
    let returnedData = [];
    for (let payload in listOfPayloads) {
      const apiData = await api('POST', 'crud', 'visits', listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        returnedData = [...returnedData, ...apiData.data];
        setAlert({
          message: 'Visits saved successfully',
          type: 'success',
        });
      }
    }
    setSavedNewVisits(true);
    return returnedData;
  };

  const updateVisits = async editedVisits => {
    const listOfPayloads = splitPayloads(editedVisits);
    let returnedData = [];
    for (let payload in listOfPayloads) {
      const apiData = await api('PATCH', 'crud', 'visits', listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        returnedData = [...returnedData, ...apiData.data];
        setAlert({
          message: 'Visits updated successfully',
          type: 'success',
        });
      }
    }
    setSavedExistingVisits(true);
    return returnedData;
  };

  const deleteVisits = async deletedVisits => {
    const listOfPayloads = splitPayloads(deletedVisits);
    for (let payload in listOfPayloads) {
      const apiData = await api('DELETE', 'crud', 'visits', listOfPayloads[payload]);
      if (apiData.error) {
        setAlert({
          apiData,
          type: 'error',
        });
      } else {
        setAlert({
          message: 'Visits deleted successfully',
          type: 'success',
        });
      }
    }
    setDeletedVisits(true);
  };

  const deleteQueueItems = async queueIds => {
    const apiData = await api('DELETE', 'crud', 'optimizationQueue', queueIds);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      setSavedQueueItems(true);
      setAlert({
        message: 'Queue items deleted successfully',
        type: 'success',
      });
    }
  };

  useEffect(() => {
    if (open) {
      getData();
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (savedNewVisits && savedExistingVisits && deletedQueueItems && deletedVisits) {
      setOptimizerData(null);
      setDisabledSubmit(true);
      setSavedNewVisits(false);
      setSavedExistingVisits(false);
      setSavedQueueItems(false);
      openToggle(false);
    }
    // eslint-disable-next-line
  }, [savedNewVisits, savedExistingVisits, deletedQueueItems, deletedVisits]);

  const cancelOptimizer = () => {
    setLoading(true);
    setSavedNewVisits(true);
    setSavedExistingVisits(true);
    setDeletedVisits(true);
    const queueRecords = [];
    for (let payload in optimizerData.queuePayLoads) {
      queueRecords.push({ id: optimizerData.queuePayLoads[payload].id });
    }
    if (queueRecords.length > 0) {
      const tempPayload = {
        realdelete: true,
        items: queueRecords,
      };
      deleteQueueItems(tempPayload);
    } else {
      setSavedQueueItems(true);
    }
    if (cancelFunction) {
      cancelFunction();
    }
  };

  const optimizeSchedule = async () => {
    setLoading(true);
    const editedVisits = [];
    const addedVisits = [];
    const deletedVisits = [];
    const queueRecords = [];
    for (let payload in optimizerData.queuePayLoads) {
      queueRecords.push({ id: optimizerData.queuePayLoads[payload].id });
      for (let visit in optimizerData.queuePayLoads[payload].addedVisits) {
        addedVisits.push(optimizerData.queuePayLoads[payload].addedVisits[visit]);
      }
      for (let visit in optimizerData.queuePayLoads[payload].editedVisits) {
        editedVisits.push(optimizerData.queuePayLoads[payload].editedVisits[visit]);
      }
      for (let visit in optimizerData.queuePayLoads[payload].deletedVisits) {
        deletedVisits.push(optimizerData.queuePayLoads[payload].deletedVisits[visit]);
      }
    }
    let addedReturnData = [];
    if (addedVisits.length > 0) {
      addedReturnData = await addVisits(addedVisits);
    } else {
      setSavedNewVisits(true);
    }
    let updatedReturnData = [];
    if (editedVisits.length > 0) {
      updatedReturnData = await updateVisits(editedVisits);
    } else {
      setSavedExistingVisits(true);
    }
    if (deletedVisits.length > 0) {
      await deleteVisits(deletedVisits);
    } else {
      setDeletedVisits(true);
    }
    if (queueRecords.length > 0) {
      const tempPayload = {
        realdelete: true,
        items: queueRecords,
      };
      deleteQueueItems(tempPayload);
    } else {
      setSavedQueueItems(true);
    }
    await submitFunction(returnAPIData ? [...addedReturnData, ...updatedReturnData] : data);
  };

  useEffect(() => {
    subscribe('optimizer', messageData => {
      if (messageData.command === 'updatedStatus' || messageData.command === 'allFinished') {
        if (messageData.command === 'allFinished') {
          setDisabledSubmit(false);
        }
        if (optimizerData) {
          const getNewData = async () => {
            const queueData = await getQueueData(messageData.queueId);
            const tempQueuePayLoads = optimizerData.queuePayLoads;
            const newQueuePayLoads = [];
            for (let payload in tempQueuePayLoads) {
              if (tempQueuePayLoads[payload].id === messageData.queueId) {
                newQueuePayLoads.push(queueData);
              } else {
                newQueuePayLoads.push(tempQueuePayLoads[payload]);
              }
            }
            optimizerData.queuePayLoads = newQueuePayLoads;
            setOptimizerData({ ...optimizerData });
          };
          getNewData();
        }
      }
    });
    return () => {
      unsubscribe('optimizer');
    };
    // eslint-disable-next-line
  }, [optimizerData, subscribe, unsubscribe]);

  const refreshData = useCallback(async () => {
    setLoading(true);
    if (optimizerData?.operationId?.length > 0) {
      const refreshPlayload = {
        index: 'operationId-index',
        pk: 'operationId',
        pkValue: optimizerData.operationId,
      };
      const refreshedApiData = await api('GET', 'crud', 'optimizationqueue', refreshPlayload);

      if (refreshedApiData.error) {
        setAlert({
          refreshedApiData,
          type: 'error',
        });
      } else if (refreshedApiData?.data && optimizerData) {
        const tempOptimizerData = { ...optimizerData };
        const newQueuePayLoads = refreshedApiData.data.sort(
          (a, b) => moment(a.date) - moment(b.date),
        );
        const types = newQueuePayLoads.map(item => item.type);
        const numberOfFinsihed = types.filter(type => type === 'finished').length;
        if (numberOfFinsihed === newQueuePayLoads.length) {
          setDisabledSubmit(false);
        } else {
          setDisabledSubmit(true);
        }
        tempOptimizerData.queuePayLoads = newQueuePayLoads;
        setOptimizerData({ ...tempOptimizerData });
      }
    }
    setLoading(false);
  }, [optimizerData, setAlert]);

  const getCustomButton = useMemo(() => {
    return (
      <Button
        color="secondary"
        variant="outlined"
        onClick={refreshData}
        disabled={!optimizerData?.operationId?.length > 0}
        startIcon={<RefreshIcon />}
        disableElevation
        sx={customDialogButtonStyles}
      >
        Refresh
      </Button>
    );
  }, [optimizerData?.operationId?.length, refreshData]);

  return (
    <Box>
      <FullWidthDialog
        cancelButtonName="cancel"
        cancelCallback={cancelOptimizer}
        backButtonCallback={cancelOptimizer}
        submitButtonName="confirm Updates"
        submitCallback={optimizeSchedule}
        title="Optimizer Check"
        openDialog={open}
        disabledSubmit={disabledSubmit}
        customTitleButton={getCustomButton}
      >
        <Box sx={{ padding: '30px' }}>
          {loading ? (
            <Loader />
          ) : (
            <Box sx={{ padding: '30px' }}>
              {optimizerData?.queuePayLoads.map((item, index) => (
                <DateAccordion key={index} data={item} />
              ))}
            </Box>
          )}
        </Box>
      </FullWidthDialog>
    </Box>
  );
};

export default OptimizerCheck;
