import { Box, Link, Typography } from '@mui/material';
import preferredCaregiverIcon from '../../../../../../img/icons/preferred-icon.svg';
import primaryCaregiverIcon from '../../../../../../img/icons/primary-visit-icon.svg';
import shadowCaregiverIcon from '../../../../../../img/icons/shadow-visit-black-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CustomTooltip from '../../../../../../components/CustomTooltip';
import { caregiverNameStyles, visitCaregiverInfo, visitCaregiversWrapper } from './styles';

export default function VisitCaregiverInfo({ visitData = {} }) {
  const primaryCaregiverPresent =
    visitData?.caregiverId?.length > 0 && visitData.caregiverId !== 'NoCaregiver';

  return (
    <Box sx={visitCaregiversWrapper}>
      <Box sx={visitCaregiverInfo}>
        {primaryCaregiverPresent && !visitData?.shadowCaregiverId?.length > 0 && (
          <CustomTooltip title="Primary Caregiver">
            <img src={primaryCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
        {primaryCaregiverPresent ? (
          <Link
            component={RouterLink}
            to={`/caregivers/${visitData?.caregiverId}`}
            underline="none"
          >
            <Typography variant="body1" sx={caregiverNameStyles}>
              {`${visitData?.caregiverName}`}
            </Typography>
          </Link>
        ) : (
          <Typography sx={{ fontStyle: 'italic' }}>Not Allocated</Typography>
        )}
        {primaryCaregiverPresent && (
          <CustomTooltip title="Primary Caregiver">
            <img src={preferredCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
      </Box>
      {visitData?.shadowCaregiverId?.length && (
        <Box sx={visitCaregiverInfo}>
          <CustomTooltip title="Shadow visit">
            <img src={shadowCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
          <Link
            component={RouterLink}
            to={`/caregivers/${visitData?.shadowCaregiverId}`}
            underline="none"
          >
            <Typography variant="body1" sx={caregiverNameStyles}>
              {`${visitData?.shadowCaregiverName}`}
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
}

VisitCaregiverInfo.propTypes = {
  visitData: instanceOf(Object),
};
