import { Box } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  ISO_DATE_ONLY_FORMAT,
  visitArrivalTimeTypesNames,
  visitStatusesMap,
} from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ArrivalTypeSelector from '../../../../components/ArrivalTypeSelector';
import { CustomDatePicker } from '../../../../components/CustomDateAndTimePickers';
import CustomAutocomplete from '../AutocompleteSelect';
import CustomTeamSelect from '../TeamSelect';
import { visitCaregiverAndDateWrapper } from './styles';
import { useGetCaregiversListQuery } from '../../../../api/Caregivers/api';

function CaregiverAndDate({
  disabledAll = false,
  initialVisitData = {},
  isExactTime = false,
  team = {},
  updateTemporaryVisitData = () => {},
  visitDate = '',
  visitStatus = '',
  teamId = '',
  teamName = '',
  caregiverId = '',
  caregiverName = '',
  isPreferredCaregiver = false,
  shadowCaregiverId = '',
  shadowCaregiverName = '',
}) {
  const { setAlert } = useContext(AlertContext);

  const {
    data: caregivers,
    error,
    isLoading: isCaregiversListLoading,
  } = useGetCaregiversListQuery(
    {
      team: teamId,
      active: true,
      date: visitDate,
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  const caregiversList = useMemo(
    () =>
      caregivers
        ?.map(({ firstName, id, lastName }) => ({
          id,
          name: `${firstName} ${lastName}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(caregiver => caregiver.id !== shadowCaregiverId),
    [caregivers, shadowCaregiverId],
  );

  const shadowCaregiversList = useMemo(
    () =>
      caregivers
        ?.map(({ firstName, id, lastName }) => ({
          id,
          name: `${firstName} ${lastName}`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(caregiver => caregiver.id !== caregiverId),
    [caregivers, caregiverId],
  );

  const teamsList = useMemo(() => {
    const caregiverData = caregivers?.find(({ id }) => id === caregiverId);
    return caregiverData
      ? [
          ...(caregiverData?.primaryTeamsDetails ?? []),
          ...(caregiverData?.secondaryTeamsDetails ?? []),
        ].filter(teamsDetails => !!teamsDetails)
      : [];
  }, [caregivers, caregiverId]);

  const selectedTeamColor = useMemo(() => {
    if (!teamsList.length || !team) {
      return 'transparent';
    }
    const foundTeam = teamsList?.find(
      ({ name }) => name?.toLowerCase() === team?.name?.toLowerCase(),
    );
    return foundTeam ? foundTeam?.color?.color : 'transparent';
  }, [teamsList, team]);

  const selectCaregiver = (_, value) => {
    if (!value) {
      return;
    }
    const selectedCaregiver = caregivers.find(({ id }) => id === value?.id);
    updateTemporaryVisitData({
      caregiverId: selectedCaregiver.id,
      caregiverName: `${selectedCaregiver.firstName} ${selectedCaregiver.lastName}`,
    });
  };

  const selectShadowCaregiver = (_, value) => {
    if (!value) {
      return;
    }
    const selectedCaregiver = caregivers.find(({ id }) => id === value?.id);
    updateTemporaryVisitData({
      shadowCaregiverId: selectedCaregiver.id,
      shadowCaregiverName: `${selectedCaregiver.firstName} ${selectedCaregiver.lastName}`,
    });
  };

  const changeVisitTeam = useCallback(
    value => {
      const teamObject = teamsList?.find(item => item.name === value);
      updateTemporaryVisitData({
        team: teamObject.id,
        teamDetails: teamObject,
      });
    },
    [teamsList, updateTemporaryVisitData],
  );

  const changeVisitDate = useCallback(
    date =>
      updateTemporaryVisitData({
        date: moment(date).startOf('day').format(ISO_DATE_ONLY_FORMAT),
      }),
    [updateTemporaryVisitData],
  );

  const changeArrivalTimeType = useCallback(
    e => {
      const isExactTimeSelected = e.target.value === 'exactTime';
      updateTemporaryVisitData({
        isExactTime: isExactTimeSelected,
        arrivalTimeStart: initialVisitData.arrivalTimeStart,
        arrivalTimeEnd: isExactTimeSelected
          ? initialVisitData.arrivalTimeStart
          : initialVisitData.arrivalTimeEnd,
      });
    },
    [initialVisitData, updateTemporaryVisitData],
  );

  const maxDatePickerDate = moment().startOf('week').add(13, 'days');

  const disabledToChange = useMemo(
    () =>
      ![visitStatusesMap.scheduled, visitStatusesMap.enRoute, visitStatusesMap.onSite].includes(
        visitStatus,
      ),
    [visitStatus],
  );
  return (
    <Box sx={visitCaregiverAndDateWrapper}>
      <CustomAutocomplete
        id="primaryCaregiver"
        changeValue={selectCaregiver}
        disabled={disabledAll || isCaregiversListLoading || disabledToChange}
        optionsList={caregiversList}
        label="Primary Caregiver"
        selectedValue={caregiverName}
        showAdditionalIcon={isPreferredCaregiver}
      />
      <CustomAutocomplete
        id="shadowCaregiver"
        changeValue={selectShadowCaregiver}
        disabled={disabledAll || isCaregiversListLoading || disabledToChange}
        optionsList={shadowCaregiversList}
        label="Shadow Caregiver"
        selectedValue={shadowCaregiverName}
      />
      {!!teamsList.length && (
        <CustomTeamSelect
          changeValue={changeVisitTeam}
          disabled={disabledAll || disabledToChange}
          isLoading={isCaregiversListLoading}
          options={teamsList}
          placeHolder="Team"
          selectedValue={teamName}
          teamBgColor={selectedTeamColor}
        />
      )}
      <CustomDatePicker
        changeDate={changeVisitDate}
        customStyles={[{ width: '200px', flex: 'inherit' }]}
        disabled={disabledAll || disabledToChange}
        date={moment(visitDate, ISO_DATE_ONLY_FORMAT)}
        isDisablePast
        label="Visit Date"
        maxDate={maxDatePickerDate}
      />
      <ArrivalTypeSelector
        arrivalTimeType={
          isExactTime
            ? visitArrivalTimeTypesNames.exactTime
            : visitArrivalTimeTypesNames.arrivalWindow
        }
        changeArrivalTimeType={changeArrivalTimeType}
        disabled={disabledAll || disabledToChange}
      />
    </Box>
  );
}

CaregiverAndDate.propTypes = {
  disabledAll: bool,
  initialVisitData: instanceOf(Object),
  isExactTime: bool,
  team: instanceOf(Object),
  updateTemporaryVisitData: func,
  visitDate: string,
  visitStatus: string,
  teamId: string,
  teamName: string,
  caregiverId: string,
  caregiverName: string,
  isPreferredCaregiver: bool,
  shadowCaregiverId: string,
  shadowCaregiverName: string,
};

export default React.memo(CaregiverAndDate);
