import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const CAREGIVERS_API_KEY = 'caregiversApi';

const caregiversApi = createApi({
  reducerPath: CAREGIVERS_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: builder => ({
    getCaregiversList: builder.query({
      query: params => ({
        url: 'crud/caregivers',
        method: 'GET',
        params: params,
      }),
    }),
    getCaregiverActivity: builder.query({
      query: ({ caregiverId }) => ({
        url: `activity/log/caregiver/${caregiverId}`,
        method: 'GET',
      }),
    }),
    getCaregiverInfo: builder.query({
      query: caregiverId => ({
        url: `crud/caregivers/`,
        method: 'GET',
        params: { id: caregiverId },
      }),
      providesTags: () => [{ type: 'caregiverInfo', id: 'ALL' }],
    }),
    updateCaregiverInfo: builder.mutation({
      query: payload => ({
        url: `crud/caregivers/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    updateCaregiverTeam: builder.mutation({
      query: ({ caregiverId, payload }) => ({
        url: `check/caregivers/`,
        method: 'POST',
        body: {
          caregiverId,
          action: 'updateTeam',
          ...payload,
        },
      }),
    }),
    confirmCaregiverTeamChange: builder.mutation({
      query: payload => ({
        url: `crud/caregivers/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
    getCaregiverHistory: builder.query({
      query: caregiverId => ({
        url: `history/caregivers/`,
        method: 'GET',
        params: {
          index: 'id-index',
          pk: 'id',
          pkValue: caregiverId,
        },
      }),
      providesTags: () => [{ type: 'caregiverInfo', id: 'ALL' }],
    }),
    getCaregiverCalendar: builder.query({
      query: params => ({
        url: `crud/visits/`,
        method: 'GET',
        params: {
          index: 'caregiver-index',
          pk: 'caregiverId',
          pkValue: params.caregiverId,
          sk: 'active',
          skValue: 'True',
          filters: JSON.stringify([
            {
              key: 'date',
              value: params.date,
              operation: '>',
              preOperation: 'AND',
            },
          ]),
        },
      }),
    }),
    checkCaregiverDeactivation: builder.query({
      query: caregiverId => ({
        url: `check/caregivers/`,
        method: 'POST',
        body: {
          caregiverId,
          action: 'deactivate',
        },
      }),
    }),
    confirmCaregiverDeactivation: builder.mutation({
      query: payload => ({
        url: `crud/caregivers/`,
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});
export const {
  useGetCaregiversListQuery,
  useGetCaregiverInfoQuery,
  useUpdateCaregiverInfoMutation,
  useConfirmCaregiverDeactivationMutation,
  useGetCaregiverHistoryQuery,
  useGetCaregiverCalendarQuery,
  useGetCaregiverActivityQuery,
  useUpdateCaregiverTeamMutation,
  useConfirmCaregiverTeamChangeMutation,
  useCheckCaregiverDeactivationQuery,
} = caregiversApi;

export default caregiversApi;
