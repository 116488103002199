import { Box, Typography } from '@mui/material';
import { useGetConfigQuery } from '../../../../../../api/Config/api';
import {
  taskNameStyles,
  taskSubInfoStyles,
  taskSubInfoWrapper,
} from '../../../../../../pages/ClientDetails/views/CarePlan/components/TableTaskCell/styles';
import { instanceOf } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import CustomTooltip from '../../../../../../components/CustomTooltip';

export default function TableTaskCell({ row }) {
  const { data: configData } = useGetConfigQuery(['categoryTypes', 'levelsOfAssistance']);

  const getNameById = (id, dataArray) => {
    const item = dataArray.find(item => item.id === id);
    return item ? item.name : id;
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CustomTooltip title={row.name}>
        <Typography sx={taskNameStyles} variant="h4">
          {row.name}
        </Typography>
      </CustomTooltip>
      <Box sx={taskSubInfoWrapper}>
        <Typography variant="body1" sx={taskSubInfoStyles}>
          {getNameById(row.categoryTypes, configData?.categoryTypes ?? [])}
        </Typography>
        <Box
          sx={{
            width: '2px',
            height: '2px',
            borderRadius: '50%',
            backgroundColor: COLORS.blue[500],
          }}
        />
        <Typography variant="body1" sx={taskSubInfoStyles}>
          {getNameById(row.levelOfAssistance, configData?.levelsOfAssistance ?? [])}
        </Typography>
      </Box>
    </Box>
  );
}

TableTaskCell.propTypes = {
  row: instanceOf(Object),
};
