import { Box } from '@mui/material';
import moment from 'moment';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext, useState } from 'react';
import TeamEffectiveDateModal from '../TeamEffectiveDateModal';
import TeamsSelect from '../TeamsSelect';

export default function SecondaryRegionsSelector() {
  const { editingProfileDataLibrary, profileData, teamDate, setProfileData } =
    useContext(CaregiverProfileContext);
  const [optionIsOpen, setOptionIsOpen] = useState(false);
  const [date, setDate] = useState(
    moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );
  const [pendingChanges, setPendingChanges] = useState({});
  const [teamEffectiveDateOpen, setTeamEffectiveDateOpen] = useState(false);

  const changeProfileData = () => {
    setProfileData({ ...pendingChanges });
  };

  const onSelectChange = (newDetails, newIds) => {
    const secondaryTeams = { ...profileData?.pendingChanges?.secondaryTeams } || {};
    secondaryTeams[moment(date).format('YYYY-MM-DD')] = newIds;
    const secondaryTeamsDetails = { ...profileData?.pendingChanges?.secondaryTeamsDetails } || {};
    secondaryTeamsDetails[moment(date).format('YYYY-MM-DD')] = newDetails;
    const pendingChanges = {
      ...profileData?.pendingChanges,
      secondaryTeams: secondaryTeams,
      secondaryTeamsDetails: secondaryTeamsDetails,
    };
    const newProfileData = { ...profileData, pendingChanges: pendingChanges };
    setPendingChanges({ ...newProfileData });
    setTeamEffectiveDateOpen(true);
  };

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <TeamsSelect
        id="secondaryTeams"
        options={editingProfileDataLibrary?.teams}
        placeHolder="Secondary teams"
        selectedValue={profileData?.secondaryTeamsDetails}
        setValue={onSelectChange}
        isOpen={optionIsOpen}
        setIsOpen={setOptionIsOpen}
      />
      <TeamEffectiveDateModal
        closeEffectiveDateDialog={() => setTeamEffectiveDateOpen(false)}
        openEffectiveDateDialog={teamEffectiveDateOpen && profileData?.status === 'Active'}
        submitEffectiveDateDialog={changeProfileData}
        date={teamDate}
        selectDate={setDate}
      />
    </Box>
  );
}
