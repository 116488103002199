import { Box, Typography } from '@mui/material';
import { getScheduleStyles } from '../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramTableDaysSchedule/styles';
import { bool, instanceOf } from 'prop-types';
import React, { useCallback } from 'react';
import { DAYS_MAP } from '../../../../../../shared/constants';

function CareProgramTableDaysSchedule({
  selectedDays = {},
  transparent = false,
}) {
  const getWrapperStyles = useCallback(
    (selected) => getScheduleStyles({ transparent, selected }),
    [transparent],
  );
  return (
    <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
      {DAYS_MAP?.map((thisDay) => {
        const selected = selectedDays[thisDay.id] ? selectedDays[thisDay.id] : false;
        return (
          <Box key={thisDay.id} sx={getWrapperStyles(selected)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: '13px', fontWeight: 600 }}
              >
                {thisDay.name.slice(0, 2).toUpperCase()}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

CareProgramTableDaysSchedule.propTypes = {
  selectedDays: instanceOf(Object),
  transparent: bool,
};

export default React.memo(CareProgramTableDaysSchedule);
