import { Alert, AlertTitle, Box, Grow, Stack } from '@mui/material';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../api/Administration/api';
import {
  useConfirmCaregiverAvailabilityMutation,
  useGetCaregiverAvailabilityQuery,
} from '../../../../api/Availability/api';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT, SHORT_DATE_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import AvailabilityControls from './components/AvailabilityControls';
import AvailabilityTable from './components/AvailabilityTable';
import CaregiverAvailabilityHistory from './components/CaregiverAvailabilityHistory';
import EditAvailability from './components/EditAvailability';
import EffectiveDateDialog from './components/EffectiveDateDialog';
import AvailabilityNoRowsComponent from './components/NoRowsComponent';
import OptimizerCheck from '../../../../components/Optimizer/OptimizerCheck';
import { viewTypeButtons } from './constants';
import { successAlertStyles } from './styles';

export default function Availability() {
  const { setAlert } = useContext(AlertContext);
  const { id, tabSubView } = useParams();
  const navigate = useNavigate();
  const [showMultiplyMode, setShowMultiplyMode] = useState(false);
  const initialEffectiveDate = moment().add(1, 'day');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [date, setDate] = useState(initialEffectiveDate);
  const [editMode, setEditMode] = useState(false);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [effectiveDates, setEffectiveDates] = useState([]);
  const [editedAvailabilityData, setEditedAvailabilityData] = useState(availabilityData);
  const [caregiverData, setCaregiverData] = useState({});
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);

  useEffect(() => {
    if (availabilityData?.length) {
      const dates = [];
      let id = 1;
      for (const availability of availabilityData) {
        if (availability.schedule) {
          for (const scheduleEntry of availability.schedule) {
            if (!dates.find(date => date.rawDate === scheduleEntry.effectiveStartDate)) {
              dates.push({
                name: moment(scheduleEntry.effectiveStartDate).format(SHORT_DATE_FORMAT),
                infoType: String(id),
                rawDate: scheduleEntry.effectiveStartDate,
              });
              id++;
            }
          }
        }
      }
      setEditedAvailabilityData(availabilityData);
      setSelectedDate(dates[0]);
      setEffectiveDates(dates);
    }
  }, [availabilityData]);

  const {
    data: caregiverDataRaw,
    isLoading,
    error: careGiverAvailabilityError,
  } = useGetCaregiverAvailabilityQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (caregiverDataRaw) {
      setCaregiverData(caregiverDataRaw);
    }
  }, [caregiverDataRaw]);

  const [
    updateAvailability,
    { isLoading: isAvailabilityUpdating, error: updateCaregiverAvailabilityError },
  ] = useConfirmCaregiverAvailabilityMutation();

  //TODO: Implement the below endpoint
  const {
    data: getWeekdays,
    isLoading: getWeekdaysLoading,
    error: getWeekdaysError,
  } = useGetWeekendsSchedulingDaysQuery();

  useEffect(() => {
    if (careGiverAvailabilityError || updateCaregiverAvailabilityError || getWeekdaysError) {
      const errorData =
        careGiverAvailabilityError || updateCaregiverAvailabilityError || getWeekdaysError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [careGiverAvailabilityError, getWeekdaysError, setAlert, updateCaregiverAvailabilityError]);

  useEffect(() => {
    if (caregiverData && Object.keys(caregiverData.availabilities ?? {}).length) {
      const availabilities = [];
      let id = 0;
      for (const key of Object.keys(caregiverData.availabilities ?? {})) {
        availabilities.push({
          id: String(id),
          day: key,
          schedule: caregiverData.availabilities[key],
        });
        id++;
      }
      setAvailabilityData(availabilities);
    }
  }, [caregiverData]);

  const checkAvailabilityData = () => {
    const newAvailabilities = {};
    for (const editedAvailability of editedAvailabilityData) {
      const filteredSchedule = editedAvailability.schedule.filter(
        scheduleEntry => scheduleEntry.startTime !== null && scheduleEntry.endTime !== null,
      );
      if (filteredSchedule.length) {
        const previousAvailabilityDataEntry = availabilityData.find(
          availabilityDataEntry => availabilityDataEntry.day === editedAvailability.day,
        );
        let oldDataToPreserve = [];

        if (previousAvailabilityDataEntry?.schedule?.length) {
          oldDataToPreserve = previousAvailabilityDataEntry.schedule.filter(
            scheduleEntry =>
              scheduleEntry.effectiveStartDate !== filteredSchedule[0].effectiveStartDate,
          );
        }
        newAvailabilities[editedAvailability.day] = [...oldDataToPreserve, ...filteredSchedule];
      }
    }
    const newCaregiverData = { ...caregiverData, availabilities: newAvailabilities };
    setCheckData([newCaregiverData]);
    setOpenCheckOptomizer(true);
  };

  const updateAvailabilityData = async newCaregiverData => {
    if (newCaregiverData?.length) {
      await updateAvailability(newCaregiverData[0])
        .unwrap()
        .then(() => {
          setCaregiverData(newCaregiverData[0]);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 3000);
        });
      setEditMode(false);
      setEditedAvailabilityData([]);
      setDate(initialEffectiveDate);
    }
  };

  const submitAvailabilityDialog = () => {
    setOpenDialog(false);
    setEditedAvailabilityData([]);
    setEditMode(true);
    setShowMultiplyMode(true);
  };

  const cancelEditMode = () => {
    setShowMultiplyMode(false);
    setEditMode(false);
    setEditedAvailabilityData([]);
    setDate(initialEffectiveDate);
  };

  const changeSubView = value => navigate(`/caregivers/${id}/availability/${value}`);

  return (
    <Stack sx={{ maxWidth: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ButtonsGroup
          buttons={viewTypeButtons}
          changeSelected={changeSubView}
          selected={tabSubView}
        />
        {tabSubView === 'current' && !isLoading && (
          <AvailabilityControls
            availabilityData={availabilityData}
            setOpenDialog={setOpenDialog}
            effectiveDates={effectiveDates}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
        )}
        <EffectiveDateDialog
          openAvailabilityDialog={openDialog}
          date={date}
          selectDate={newValue => setDate(newValue)}
          closeAvailabilityDialog={() => {
            setOpenDialog(false);
            setDate(initialEffectiveDate);
          }}
          submitAvailabilityDialog={submitAvailabilityDialog}
          showWarningTitle={false}
        />
        <Grow in={showSuccessAlert}>
          <Alert sx={successAlertStyles} severity="success">
            <AlertTitle>Success</AlertTitle>
            Successfully update Availability
          </Alert>
        </Grow>
      </Box>
      <Box sx={{ marginTop: '30px' }}>
        {tabSubView === 'current' ? (
          <Box>
            <AvailabilityTable
              availabilityData={availabilityData}
              isLoading={isLoading || isAvailabilityUpdating}
              noRowsOverlay={AvailabilityNoRowsComponent}
              selectedDate={selectedDate}
            />
            <EditAvailability
              cancelEditMode={cancelEditMode}
              closeDialog={cancelEditMode}
              disabledDays={getWeekdays?.schema}
              isLoading={isLoading || isAvailabilityUpdating || getWeekdaysLoading}
              openEditMode={editMode}
              setShowMultiplyMode={setShowMultiplyMode}
              showMultiplyMode={showMultiplyMode}
              startDate={moment(date).format(ISO_DATE_ONLY_FORMAT)}
              submitCallback={checkAvailabilityData}
              selectedDate={selectedDate}
              availabilityData={availabilityData}
              editedAvailabilityData={editedAvailabilityData}
              setEditedAvailabilityData={setEditedAvailabilityData}
            />
            <OptimizerCheck
              data={checkData}
              dataObjName="caregiverObj"
              submitFunction={updateAvailabilityData}
              open={openCheckOptomizer}
              openToggle={setOpenCheckOptomizer}
            />
          </Box>
        ) : (
          <CaregiverAvailabilityHistory />
        )}
      </Box>
    </Stack>
  );
}