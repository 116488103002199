import React, { useContext } from 'react';
import { VisitInfoTypeContext } from '../../../../shared/context';
import FullWidthDialog from '../../../FullWidthDialog';
import { visitInfoTypeDialogs } from '../../constants';
import VisitDetails from './VisitDetails';

export default function VisitDetailsWrapper() {
  const {
    closeDialog,
    closeVisitDetailsDialog,
    isVisitDataChanged,
    onUpdateVisitDetails,
    openDialogType,
    visitConfirmationAvailable,
  } = useContext(VisitInfoTypeContext);
  return (
    <FullWidthDialog
      backButtonCallback={closeVisitDetailsDialog}
      cancelButtonName="cancel"
      cancelCallback={closeDialog}
      disabledSubmit={!visitConfirmationAvailable || !isVisitDataChanged}
      submitButtonName="Save"
      submitCallback={() => onUpdateVisitDetails()}
      title="Visit Details"
      openDialog={openDialogType === visitInfoTypeDialogs.visitFullDetails}
    >
      <VisitDetails />
    </FullWidthDialog>
  );
}
