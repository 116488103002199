import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '../../../../../../../../img/icons/iconButtons/delete-icon.svg';
import { array, func, instanceOf } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import CustomTooltip from '../../../../../../../../components/CustomTooltip';
import CustomDialog from '../../../../../../../../components/Dialog';
import { getTaskActionsStyles, tableActionsWrapper } from './styles';

export default function TaskActions({ row = {}, allTasks = [], updateTasks = () => {} }) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const deleteTask = () => {
    const newTasks = allTasks?.filter(task => task.id !== row.id);
    updateTasks(newTasks);
  };

  const rowTasks = allTasks ?? [];
  const itemIndex = rowTasks.findIndex(({ id }) => id === row?.id);

  const swapTasks = (target, currentItem) => {
    if (!rowTasks?.length) {
      return true;
    }
    const copiedTasks = [...rowTasks];
    const currentIndex = rowTasks.findIndex(({ id }) => id === currentItem);
    if (currentIndex === -1) {
      return true;
    }
    const targetIndex = currentIndex + target;
    [copiedTasks[targetIndex], copiedTasks[currentIndex]] = [
      copiedTasks[currentIndex],
      copiedTasks[targetIndex],
    ];
    updateTasks(copiedTasks);
    return true;
  };

  const actions = [
    {
      callback: () => setOpenDeleteDialog(true),
      icon: <img src={DeleteIcon} alt="Delete" />,
      label: 'Delete',
    },
    {
      callback: () => swapTasks(1, row?.id),
      disabled: itemIndex === rowTasks?.length - 1,
      icon: <ArrowDownwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />,
      label: 'Move down',
    },
    {
      callback: () => swapTasks(-1, row?.id),
      disabled: itemIndex < 1,
      icon: <ArrowUpwardIcon sx={{ fill: COLORS.blue[300], fontSize: '20px' }} />,
      label: 'Move up',
    },
  ];

  const taskActionStyles = useCallback(action => getTaskActionsStyles(action), []);
  const taskName = useMemo(
    () => allTasks?.find(task => task.id === row?.id)?.name || '',
    [allTasks, row?.id],
  );

  const onActionClick = (e, callback) => {
    e.preventDefault();
    e.stopPropagation();
    callback();
  };

  return (
    <Box sx={tableActionsWrapper}>
      {actions?.map(action => (
        <CustomTooltip title={action.label} key={action.label}>
          <span>
            <IconButton
              aria-label={action.label}
              disabled={action.disabled}
              disableRipple
              onClick={e => onActionClick(e, action.callback)}
              sx={() => taskActionStyles(action)}
            >
              {action.icon}
            </IconButton>
          </span>
        </CustomTooltip>
      ))}
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenDeleteDialog(false)}
        submitButtonName="confirm"
        submitCallback={deleteTask}
        title="Confirmation"
        openDialog={openDeleteDialog}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            {`The task "${taskName}" will be removed. Do you want to proceed?`}
          </Typography>
        </Box>
      </CustomDialog>
    </Box>
  );
}

TaskActions.propTypes = {
  row: instanceOf(Object),
  allTasks: array,
  updateTasks: func,
};
