import { Dialog } from '@mui/material';
import { bool, element, func, instanceOf, string, node } from 'prop-types';
import React from 'react';
import { TransitionSlide } from '../../shared/constants';
import Loader from '../../components/Loader';
import Actions from './Actions';
import Title from './Title';
import { dialogPaperStyles } from './styles';

export default function CustomDialog({
  cancelButtonName = '',
  cancelCallback = () => {},
  children = <div />,
  customStyles = {},
  disableOnCloseByClickOutside = false,
  disabledSubmit = false,
  isLoading = false,
  openDialog = false,
  slotProps = {},
  submitButtonName = '',
  submitCallback = () => {},
  title = '',
  titleBadge = <div />,
  titleIcon = <div />,
  withBorder = false,
}) {
  return (
    <Dialog
      disableEscapeKeyDown={disableOnCloseByClickOutside}
      open={!!openDialog}
      onClose={() => (disableOnCloseByClickOutside ? {} : cancelCallback())}
      keepMounted={false}
      PaperProps={{
        sx: { ...dialogPaperStyles, ...customStyles },
      }}
      slotProps={slotProps}
      TransitionComponent={TransitionSlide}
    >
      {isLoading && <Loader />}
      <Title
        closeDialog={cancelCallback}
        title={title}
        titleBadge={titleBadge}
        titleIcon={titleIcon}
        withBorder={withBorder}
      />
      {children}
      <Actions
        cancelButtonName={cancelButtonName}
        cancelCallback={cancelCallback}
        disabled={disabledSubmit}
        submitButtonName={submitButtonName}
        submitCallback={submitCallback}
      />
    </Dialog>
  );
}

CustomDialog.propTypes = {
  cancelButtonName: string,
  cancelCallback: func,
  children: node,
  disableOnCloseByClickOutside: bool,
  disabledSubmit: bool,
  isLoading: bool,
  openDialog: bool,
  slotProps: instanceOf(Object),
  submitButtonName: string,
  submitCallback: func,
  title: string,
  titleBadge: element,
  titleIcon: element,
  withBorder: bool,
  customStyles: instanceOf(Object),
};
