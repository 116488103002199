export const tablesWrapper = {
  paddingRight: '10px',
  height: 'calc(100vh - 310px)',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.8em',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};

export const tableStyles = [
  {
    '& .MuiDataGrid-virtualScroller': {
      boxSizing: 'border-box',
      maxHeight: '250px',
      overflowX: 'hidden',
      overflowY: 'auto !important',
      padding: '1px',
      scrollbarColor: '#888 #f1f1f1',
      scrollbarWidth: 'thin',
    },
    '& .MuiDataGrid-overlayWrapperInner': {
      minHeight: '135px',
      height: '135px !important',
    },
    '& .MuiDataGrid-row': {
      paddingTop: '10px',
      paddingBottom: '10px',
      boxSizing: 'border-box',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-columnHeader': {
      padding: '0 20px',
    },
    '& .MuiDataGrid-selectedRowCount': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnHeader--moving': {
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell:first-of-type': {
      padding: '0 0 0 20px',
    },
  },
];
