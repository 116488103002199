import { Box, IconButton } from '@mui/material';
import DeleteIcon from '../../../../../../../img/icons/iconButtons/delete-icon.svg';
import moment from 'moment';
import { isCrossedPeriods } from '../../../../../../../pages/Scheduling/components/ScheduleCaregiverManagement/utils';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useState } from 'react';
import { H24_TIME_FORMAT, H12_TIME_FORMAT } from '../../../../../../../shared/constants';
import CustomTimeSelect from '../../../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import ErrorAlert from '../../../../../../../components/ErrorAlert';

function ArrivalWindow({
  deleteRange = () => {},
  endTime = '',
  endTimeDisabled = false,
  id = '',
  periods = [],
  setHasErrors = () => {},
  startTime = '',
  updateRange = () => {},
  date = '',
}) {
  const [error, setError] = useState(null);
  const updateArrivalWindow = ({ startTimeValue, endTimeValue }) => {
    const arrivalRange = {
      temporaryId: id,
      effectiveStartDate: date,
      startTime: moment(startTimeValue, "YYYY-MM-DDTHH:mm:ss.sssZ").isValid()
        ? (moment(startTimeValue).format("hh:mm A") === "12:00 AM" 
           ? "00:00:00" 
           : moment(startTimeValue).format(H24_TIME_FORMAT)) 
        : '',
      endTime: moment(endTimeValue, "YYYY-MM-DDTHH:mm:ss.sssZ").isValid() 
        ? (moment(endTimeValue).format("hh:mm A") === "12:00 AM" 
           ? "00:00:00" 
           : moment(endTimeValue).format(H24_TIME_FORMAT))
        : '',
    };
    updateRange(arrivalRange);
  };

  const onStartChange = (newValue) => {
    const endTimeValue = moment(endTime, H12_TIME_FORMAT);
    updateArrivalWindow({ startTimeValue: newValue, endTimeValue });
    if (newValue.isSameOrAfter(endTimeValue)) {
      setError('You have been inputted wrong arrival window time');
      setHasErrors(true);
      return true;
    }
    const crossedPeriodsError = isCrossedPeriods({
      startTime: newValue,
      endTime: endTimeValue,
      id,
      periods,
    });
    if (crossedPeriodsError) {
      setError(crossedPeriodsError);
      setHasErrors(true);
      return true;
    }
    setHasErrors(false);
    setError(null);
    return false;
  };

  const onEndChange = (newValue) => {
    const startTimeValue = moment(startTime, H12_TIME_FORMAT);
    updateArrivalWindow({ startTimeValue, endTimeValue: newValue });
    if (newValue.isSameOrBefore(startTimeValue)) {
      setError('You have been inputted wrong arrival window time');
      setHasErrors(true);
      return false;
    }
    const crossedPeriodsError = isCrossedPeriods({
      startTime: startTimeValue,
      endTime: newValue,
      id,
      periods,
    });
    if (crossedPeriodsError) {
      setError(crossedPeriodsError);
      setHasErrors(true);
      return true;
    }
    setError(null);
    setHasErrors(false);
    return false;
  };

  return (
    <div>
      {error && <ErrorAlert error={error} />}
      <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', width: '100%', gap: '20px' }}>
          <CustomTimeSelect
            onSelectTimeValue={(value) =>
              onStartChange(moment(value, H12_TIME_FORMAT))
            }
            placeHolder="One-time Availability Start"
            selectedTime={startTime}
          />
          <CustomTimeSelect
            disabled={endTimeDisabled}
            onSelectTimeValue={(value) =>
              onEndChange(moment(value, H12_TIME_FORMAT))
            }
            placeHolder="One-time Availability End"
            selectedTime={endTime}
          />
        </Box>
        <IconButton
          aria-label="delete"
          disableRipple
          onClick={() => deleteRange(id)}
          sx={{ p: 0 }}
        >
          <img src={DeleteIcon} alt="Delete" />
        </IconButton>
      </Box>
    </div>
  );
}

ArrivalWindow.propTypes = {
  deleteRange: func,
  endTime: string,
  endTimeDisabled: bool,
  id: string,
  periods: instanceOf(Array),
  setHasErrors: func,
  startTime: string,
  updateRange: func,
};

export default React.memo(ArrivalWindow);
