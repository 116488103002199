import { isItemsContainSubstring } from '../../shared/utils/common';

export const filterClient = ({ clientName, teams, phoneNumber, status }, clients) => {
  let data = [...clients];
  if (data?.length) {
    if (clientName?.length) {
      data = data.filter(item => {
        const fullName = `${item.firstName} ${item.lastName}`;
        const reverseFullName = `${item.lastName} ${item.firstName}`;
        return (
          isItemsContainSubstring(fullName, clientName) ||
          isItemsContainSubstring(reverseFullName, clientName)
        );
      });
    }
    if (phoneNumber?.length) {
      data = data.filter(item => {
        const numericPhoneNumber = item?.phone?.replace(/\D/g, '');

        const formattedPhoneNumber = `(${numericPhoneNumber?.slice(
          0,
          3,
        )}) ${numericPhoneNumber?.slice(3, 6)}-${numericPhoneNumber?.slice(6)}`;
        return item.phone ? isItemsContainSubstring(formattedPhoneNumber, phoneNumber) : '';
      });
    }
    if (!status.length) {
      data = [];
    }
    if (status.length) {
      data = data.filter(item => status.map(status => status.name).includes(item.status));
    }
    if (teams.length === 0) {
      data = [];
    }
    if (teams.length) {
      let emptyTeamClients = [];
      let selectedRegionClient = [];
      const teamIds = teams?.map(location => location.id);

      if (teams?.includes('Empty Team')) {
        emptyTeamClients = data?.filter(item => item?.team?.length === 0 || item.team === null);
      }

      if (teams?.some(team => team !== 'Empty Team')) {
        selectedRegionClient = data?.filter(item => teamIds?.includes(item?.team));
      }

      data = Array.from(new Set([...selectedRegionClient, ...emptyTeamClients]));
    }
  }
  return data;
};

export const getSelectedLocations = (selectedLocations, locationsList) => {
  if (!selectedLocations?.length || !locationsList?.length) return false;
  const regions = selectedLocations?.filter(
    item => locationsList?.map(({ name }) => name).indexOf(item) >= 0,
  ).length;
  const communities = selectedLocations.length - regions;
  return { regions, communities };
};
