import { Box, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import { detailsInfoKeysStyles, detailsWrapper } from '../styles';

export default function DetailsWrapper({ resourceStatistics = {} }) {
  const calculateColor = () => {
    if (Number(resourceStatistics?.utilizationRate) < 50) {
      return '#FF3B30';
    }
    if (Number(resourceStatistics?.utilizationRate) < 60) {
      return '#FF9C28';
    }
    return '#09AF00';
  };
  return (
    <Box className="expandedHeader" sx={detailsWrapper}>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles, pl: '12px' }}>with Travel Time:</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            color: calculateColor(),
          }}
        >
          {resourceStatistics?.utilizationRateWithTravelTime}%
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles, fontWeight: 700 }}>
          Utilization Hours:
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {resourceStatistics?.scheduledHours?.length ? resourceStatistics?.scheduledHours : 'N/A'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={{ ...detailsInfoKeysStyles, pl: '12px' }}>with Travel Time:</Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {resourceStatistics?.utilizationHoursWithTravelTime?.length
            ? resourceStatistics?.utilizationHoursWithTravelTime
            : 'N/A'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: '3px' }}>
        <Typography sx={detailsInfoKeysStyles}>Expected Travel Time:</Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {resourceStatistics?.expectedTravelTime?.length
            ? resourceStatistics?.expectedTravelTime
            : 'N/A'}
        </Typography>
      </Box>
    </Box>
  );
}

DetailsWrapper.propTypes = {
  resourceStatistics: instanceOf(Object),
};
