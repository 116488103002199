import { Box, Stack } from '@mui/material';
import { useGetWeeklyReportQuery } from '../../../../api/Reporting/api';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import SelectTeamFilter from '../../../../components/SelectTeamFilter';
import VisitManagementProvider from '../../../../components/VisitInfoTypeProvider/VisitManagementProvider';
import ReportStatistics from './components/ReportStatistics';
import ReportingWeekDates from './components/ReportingWeek';
import VisitsList from './components/VisitsList';
import { processAllocatedVisit, processUnallocatedVisit, viewTypeButtons } from './constants';
import { tablesWrapper } from './styles';

export default function WeeklySchedulingReport() {
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const { tabSubView } = useParams();
  const currentWeekStartDate = moment().clone().startOf('isoweek');
  const [reportingWeekStartDate, setReportingWeekStartDate] = useState(currentWeekStartDate);

  const {
    data: weeklyReportData,
    error,
    isLoading,
    isFetching,
    refetch: refetchWeeklyReport,
  } = useGetWeeklyReportQuery(
    {
      dateFrom: reportingWeekStartDate.format(ISO_DATE_ONLY_FORMAT),
      dateTo: reportingWeekStartDate.clone().endOf('isoweek').format(ISO_DATE_ONLY_FORMAT),
    },
    { refetchOnMountOrArgChange: true },
  );

  const [teamsList, setTeamsList] = useState([]);
  const [teamFilter, setTeamFilter] = useState([]);
  const [visitsList, setVisitsList] = useState([]);
  const [visitsData, setVisitsData] = useState([]);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  useEffect(() => {
    if (weeklyReportData) {
      const teams = [];
      const tempVisitsData = [];

      weeklyReportData.forEach(item => {
        if (
          item.status === 'NotAllocated' ||
          item.violatedHardConstraints?.length > 0 ||
          item.violatedSoftConstraints?.length > 0
        ) {
          tempVisitsData.push(
            item.status === 'NotAllocated'
              ? processUnallocatedVisit(item)
              : processAllocatedVisit(item),
          );
        }
      });

      tempVisitsData.forEach(visit => {
        if (visit.teamDetails?.name && !teams.includes(visit.teamDetails.name)) {
          teams.push(visit.teamDetails.name);
        }
      });

      setVisitsData(tempVisitsData);
      setTeamsList(teams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyReportData]);

  useEffect(() => {
    setTeamFilter(teamsList);
  }, [teamsList]);

  useEffect(() => {
    let filteredVisits = visitsData.filter(visit => teamFilter.includes(visit.teamDetails?.name));

    if (tabSubView?.length) {
      switch (tabSubView) {
        case 'not-allocated':
          filteredVisits = filteredVisits.filter(visit => visit.status === 'NotAllocated');
          break;
        case 'hard-constraints':
          filteredVisits = filteredVisits.filter(
            visit => visit.violatedHardConstraints?.length > 0,
          );
          break;
        case 'soft-constraints':
          filteredVisits = filteredVisits.filter(
            visit => visit.violatedSoftConstraints?.length > 0,
          );
          break;
        default:
          break;
      }
    }

    setVisitsList(filteredVisits);
  }, [teamFilter, visitsData, tabSubView]);

  const changeSubView = value => navigate(`/reporting/weekly-scheduling-report/${value}`);

  return (
    <VisitManagementProvider reFetchCalendarEvents={refetchWeeklyReport}>
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '20px',
          }}
        >
          {(isLoading || isFetching) && <Loader />}
          <ButtonsGroup
            buttons={viewTypeButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
          />
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <ReportingWeekDates
              date={reportingWeekStartDate}
              setNewDate={setReportingWeekStartDate}
            />
            <SelectTeamFilter
              hasSelectAllOption
              id="teams"
              isMultiselect
              options={teamsList}
              placeHolder="Teams"
              selectedValue={teamFilter}
              setValue={({ value }) => setTeamFilter(value)}
            />
          </Box>
        </Box>
        {/* {TODO statistics will be implemented later} */}
        {!tabSubView && <ReportStatistics />}
        <Box sx={tablesWrapper}>
          <VisitsList visitsList={visitsList} tabSubView={tabSubView} />
        </Box>
      </Stack>
    </VisitManagementProvider>
  );
}
