import { Box, Collapse } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  CONVERT_TO_UTC_FORMAT,
  H12_TIME_FORMAT,
  visitArrivalTimeTypesNames,
} from '../../../../../../../../shared/constants';
import AlertContext from '../../../../../../../../components/Alert';
import CustomBadges from '../../../../../../../../components/CustomBadges';
import SelectWithCounter from '../../../../../../../../components/FiltersComponents/Select';
import MultilineInput from '../../../../../../../../components/Inputs/MultilineInput';
import Loader from '../../../../../../../../components/Loader';
import CustomSelect from '../../../../../../../../components/Select';
import DatesCalendar from '../DatesCalendar';
import { MAX_LONG_TEXT_DESCRIPTION_FIELD } from '../constants';
import { visitParametersWrapper } from '../styles';
import ArrivalSlots from './ArrivalSlots';
import ClientData from './ClientData';
import StepTitle from './StepTitle';
import VisitTypeBlock from './VisitTypeBlock';
import { useGetConfigQuery } from '../../../../../../../../api/Config/api';
import { useParams } from 'react-router';
import { useGetUserDataByIdQuery } from '../../../../../../../../api/Clients/api';
import { v4 as uuidv4 } from 'uuid';
import { sortObjectTimeOfDays } from '../../../../../../../../shared/utils/common';

function VisitParameters({
  isSelectedTimeOutOfRange = false,
  pastTimeError = null,
  setIsSelectedTimeOutOfRange = () => {},
  setPastTimeError = () => {},
  setVisitParams = () => {},
  visitParams = {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [showVisitNotes, setShowVisitNotes] = useState(
    visitParams?.visitDetails?.length > 0 ?? false,
  );
  const [visitArrivalTimeType, setVisitArrivalTimeType] = useState(
    visitParams?.isExactTime
      ? visitArrivalTimeTypesNames.exactTime
      : visitArrivalTimeTypesNames.arrivalWindow,
  );
  const {
    data: userData,
    isLoading: isLoadingUserData,
    error: getUserDataError,
  } = useGetUserDataByIdQuery(id);
  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: getConfigError,
  } = useGetConfigQuery(['skills', 'genders', 'languages', 'timeOfDays', 'visitTypes']);

  useEffect(() => {
    if (userData) {
      const id = uuidv4();
      setVisitParams(prev => ({
        ...prev,
        id: id,
        oneTimeVisit: true,
        address: `${userData.street}, ${userData.city}, ${userData.state}, ${userData.zipCode}, ${userData.country}`,
        careprogramId: id,
        team: userData.team,
        territory: userData.territory,
        clientId: userData.id,
        clientName: [userData.firstName, userData.lastName]
          .filter(name => name && name.length > 0)
          .join(' '),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (getConfigError || getUserDataError) {
      const errorData = getConfigError || getUserDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getConfigError, getUserDataError, setAlert]);

  const checkPastTimeError = useCallback(
    date => {
      const californiaCurrentTime = moment().tz(CALIFORNIA_TIME_ZONE);
      const californiaCurrentDateSelected = moment(date).tz(CALIFORNIA_TIME_ZONE, true);

      if (
        moment(californiaCurrentDateSelected).isSameOrBefore(
          californiaCurrentTime.add(10, 'minutes'),
          'minutes',
        )
      ) {
        setPastTimeError(
          'You cannot create a visit starting less than 10 min of the current time (CA timezone)',
        );
      } else {
        setPastTimeError(null);
      }
    },
    [setPastTimeError],
  );

  const changeDays = useCallback(
    date => {
      const newArrivalStart = visitParams?.arrivalStart?.length
        ? moment(
            `${date.substring(0, 10)} ${moment(visitParams.arrivalStart).format(H12_TIME_FORMAT)}`,
            CONVERT_TO_UTC_FORMAT,
          ).format('YYYY-MM-DDTHH:mm:ss')
        : undefined;
      const newArrivalEnd = visitParams?.arrivalEnd?.length
        ? moment(
            `${date.substring(0, 10)} ${moment(visitParams.arrivalEnd).format(H12_TIME_FORMAT)}`,
            CONVERT_TO_UTC_FORMAT,
          ).format('YYYY-MM-DDTHH:mm:ss')
        : undefined;
      setVisitParams(prevValue => ({
        ...prevValue,
        date,
        arrivalStart: newArrivalStart,
        arrivalEnd: newArrivalEnd,
        arrivalTime: newArrivalStart,
      }));

      if (newArrivalStart) {
        checkPastTimeError(newArrivalStart);
      }
    },
    [checkPastTimeError, setVisitParams, visitParams.arrivalEnd, visitParams.arrivalStart],
  );

  const onSelectChange = params => {
    if (params.field === 'visitType') {
      const selectedValue = configData?.visitTypes?.find(type => type.id === params.id);
      setVisitParams(prevValue => ({
        ...prevValue,
        visitType: selectedValue?.id,
        visitTypeDetails: selectedValue,
        duration: selectedValue?.duration,
      }));
    } else if (params.field === 'genderPreference') {
      setVisitParams(prevValue => ({
        ...prevValue,
        genderPreference: configData?.genders?.find(type => type.id === params.id).id,
      }));
    } else if (params.field === 'preferredSkills') {
      setVisitParams(prevValue => ({
        ...prevValue,
        preferredSkills: params.value,
      }));
    } else if (params.field === 'preferredLanguages') {
      setVisitParams(prevValue => ({
        ...prevValue,
        preferredLanguages: params.value,
      }));
    }
  };

  const changeDuration = value => {
    setVisitParams({ ...visitParams, duration: +value });
  };

  const minTime = useMemo(() => {
    const sortedTimeOfDays = sortObjectTimeOfDays(configData);
    return sortedTimeOfDays?.length ? sortedTimeOfDays[0].from : '8:00';
  }, [configData]);

  const maxTime = useMemo(() => {
    const sortedTimeOfDays = sortObjectTimeOfDays(configData);
    return sortedTimeOfDays?.length ? sortedTimeOfDays[sortedTimeOfDays.length - 1].to : '19:00';
  }, [configData]);

  const checkIsOutOfRange = useCallback(
    ({ arrivalStart, arrivalEnd }) => {
      return (
        moment(arrivalStart, 'YYYY-MM-DDTHH:mm:ss').isBefore(
          moment(`${visitParams?.date.substring(0, 10)} ${minTime}`, 'YYYY-MM-DD H:mm'),
          'minutes',
        ) ||
        moment(arrivalEnd, 'YYYY-MM-DDTHH:mm:ss').isAfter(
          moment(`${visitParams?.date.substring(0, 10)} ${maxTime}`, 'YYYY-MM-DD H:mm'),
          'minutes',
        )
      );
    },
    [maxTime, minTime, visitParams?.date],
  );

  const changeExactTime = useCallback(
    time => {
      const newTimeValue = moment(
        `${visitParams?.date.substring(0, 10)} ${time}`,
        CONVERT_TO_UTC_FORMAT,
      ).format('YYYY-MM-DDTHH:mm:ss');

      const isOutOfRange = checkIsOutOfRange({
        arrivalStart: newTimeValue,
        arrivalEnd: newTimeValue,
      });

      setIsSelectedTimeOutOfRange(isOutOfRange);

      setVisitParams(prevValue => ({
        ...prevValue,
        arrivalStart: newTimeValue,
        arrivalEnd: newTimeValue,
        arrivalTime: newTimeValue,
      }));

      checkPastTimeError(newTimeValue);
    },
    [
      checkIsOutOfRange,
      checkPastTimeError,
      setIsSelectedTimeOutOfRange,
      setVisitParams,
      visitParams?.date,
    ],
  );

  const changeArrivalTimeRange = useCallback(
    data => {
      const newStartTimeValue = data.arrivalStart?.length
        ? moment(
            `${visitParams?.date.substring(0, 10)} ${data.arrivalStart}`,
            CONVERT_TO_UTC_FORMAT,
          ).format('YYYY-MM-DDTHH:mm:ss')
        : undefined;
      const newEndTimeValue = data.arrivalEnd?.length
        ? moment(
            `${visitParams?.date.substring(0, 10)} ${data.arrivalEnd}`,
            CONVERT_TO_UTC_FORMAT,
          ).format('YYYY-MM-DDTHH:mm:ss')
        : undefined;

      setVisitParams(prevValue => ({
        ...prevValue,
        arrivalStart: newStartTimeValue,
        arrivalEnd: newEndTimeValue,
        arrivalTime: newStartTimeValue,
      }));

      if (newStartTimeValue && newEndTimeValue) {
        const isOutOfRange = checkIsOutOfRange({
          arrivalStart: newStartTimeValue,
          arrivalEnd: newEndTimeValue,
        });
        setIsSelectedTimeOutOfRange(isOutOfRange);
      }
      if (newStartTimeValue) {
        checkPastTimeError(newStartTimeValue);
      }
    },
    [
      checkIsOutOfRange,
      checkPastTimeError,
      setIsSelectedTimeOutOfRange,
      setVisitParams,
      visitParams?.date,
    ],
  );

  const changeArrivalTimeType = useCallback(
    e => {
      setVisitArrivalTimeType(e.target.value);
      if (isSelectedTimeOutOfRange) {
        setIsSelectedTimeOutOfRange(false);
      }
      if (pastTimeError) {
        setPastTimeError(null);
      }

      const isExactTime = e.target.value === visitArrivalTimeTypesNames.exactTime;
      setVisitParams(prevValue => ({
        ...prevValue,
        isExactTime: isExactTime,
        arrivalTime: prevValue.arrivalStart,
        arrivalEnd: prevValue.arrivalStart,
      }));
    },
    [
      isSelectedTimeOutOfRange,
      pastTimeError,
      setIsSelectedTimeOutOfRange,
      setPastTimeError,
      setVisitParams,
    ],
  );

  const changeVisitDetails = e => {
    if (e.target.value.length <= MAX_LONG_TEXT_DESCRIPTION_FIELD) {
      setVisitParams({ ...visitParams, visitDetails: e.target.value });
    }
  };

  const onDeleteSkill = skillIdToDelete => {
    setVisitParams(prev => ({
      ...visitParams,
      preferredSkills: prev.preferredSkills.filter(skillId => skillId !== skillIdToDelete),
    }));
  };

  const onDeleteLanguage = languageIdToDelete => {
    setVisitParams(prev => ({
      ...visitParams,
      preferredLanguages: prev.preferredLanguages.filter(
        languageId => languageId !== languageIdToDelete,
      ),
    }));
  };

  if (isLoadingConfigs || isLoadingUserData) {
    return <Loader />;
  }
  return (
    <Box sx={visitParametersWrapper}>
      <StepTitle />
      <ClientData userData={userData} clientName={visitParams?.clientName} />
      <Box sx={{ width: '100%', height: '8px' }} />
      <DatesCalendar handleSelectDate={changeDays} selectedDate={visitParams?.date} />
      <Box sx={{ width: '100%', height: '24px' }} />
      <VisitTypeBlock
        changeDuration={changeDuration}
        changeVisitType={onSelectChange}
        filtersData={configData}
        visitParams={visitParams}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <ArrivalSlots
        arrivalEnd={
          visitParams.arrivalEnd?.length
            ? moment(visitParams.arrivalEnd).format(H12_TIME_FORMAT)
            : undefined
        }
        arrivalStart={
          visitParams.arrivalStart?.length
            ? moment(visitParams.arrivalStart).format(H12_TIME_FORMAT)
            : undefined
        }
        changeArrivalTimeRange={changeArrivalTimeRange}
        changeArrivalTimeType={changeArrivalTimeType}
        changeExactTime={changeExactTime}
        isSelectedTimeOutOfRange={isSelectedTimeOutOfRange}
        pastTimeError={pastTimeError}
        visitArrivalTimeType={visitArrivalTimeType}
      />
      <CustomSelect
        id="genderPreference"
        isLoading={isLoadingConfigs || isLoadingUserData}
        options={configData?.genders}
        placeHolder="Gender Preference"
        selectedValue={visitParams?.genderPreference || ''}
        setValue={onSelectChange}
      />
      <Box sx={{ width: '100%', height: '24px' }} />
      <SelectWithCounter
        disableColored
        filterItems={visitParams?.preferredSkills?.map(skillId => ({ id: skillId })) || []}
        id="preferredSkills"
        isLoading={isLoadingConfigs || isLoadingUserData}
        isMultiselect
        options={configData?.skills}
        placeHolder="Skills Preference"
        setFilters={onSelectChange}
        showFiltersCount
      />
      <Collapse in={!!visitParams?.preferredSkills?.length}>
        <CustomBadges
          items={
            visitParams?.preferredSkills?.map(skill => {
              const configSkill = configData?.skills?.find(item => item.id === skill);
              return {
                name: configSkill?.name,
                value: configSkill?.id,
              };
            }) || []
          }
          onDelete={onDeleteSkill}
        />
      </Collapse>
      <Box sx={{ width: '100%', height: '24px' }} />
      <SelectWithCounter
        disableColored
        filterItems={visitParams?.preferredLanguages?.map(skillId => ({ id: skillId })) || []}
        id="preferredLanguages"
        isLoading={isLoadingConfigs || isLoadingUserData}
        isMultiselect
        options={configData?.languages}
        placeHolder="Language Preference"
        setFilters={onSelectChange}
        showFiltersCount
      />
      <Collapse in={!!visitParams?.preferredLanguages?.length}>
        <CustomBadges
          items={
            visitParams?.preferredLanguages?.map(language => {
              const configLanguage = configData?.languages?.find(item => item.id === language);
              return {
                name: configLanguage?.name,
                value: configLanguage?.id,
              };
            }) || []
          }
          onDelete={onDeleteLanguage}
        />
      </Collapse>
      <Box sx={{ width: '100%', height: '12px' }} />
      <FormControlLabel
        sx={{ marginBottom: '12px', maxWidth: '200px' }}
        control={<Checkbox checked={showVisitNotes} />}
        onChange={() => {
          if (showVisitNotes) {
            setVisitParams(prev => ({ ...prev, visitDetails: undefined }));
          }
          setShowVisitNotes(!showVisitNotes);
        }}
        label="Additional Visit Details"
      />
      {showVisitNotes && (
        <MultilineInput
          changeDetails={changeVisitDetails}
          id="visitDetails"
          label="Additional Visit Details"
          maxLength={MAX_LONG_TEXT_DESCRIPTION_FIELD}
          value={visitParams.visitDetails}
          showHelperText
        />
      )}
      <Box sx={{ width: '100%', height: '15px' }} />
    </Box>
  );
}

VisitParameters.propTypes = {
  isSelectedTimeOutOfRange: bool,
  pastTimeError: string,
  setIsSelectedTimeOutOfRange: func,
  setPastTimeError: func,
  setVisitParams: func,
  visitParams: instanceOf(Object),
};

export default React.memo(VisitParameters);
