import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { getAvailabilityColumns } from '../../../../../../pages/CaregiverDetails/view/Availability/constants';
import { bool, func, instanceOf } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import CustomTable from '../../../../../../components/Table';

function AvailabilityTable({
  availabilityData = {},
  isLoading = true,
  noRowsOverlay = () => {},
  selectedDate = {},
}) {
  const apiRef = useGridApiRef();
  const [checkedRows, setCheckedRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (availabilityData.length) {
      if (selectedDate) {
        const rows = availabilityData
          ?.map(row => {
            const filteredSchedule = row.schedule?.filter(
              rowSchedule => rowSchedule.effectiveStartDate === selectedDate.rawDate,
            );
            if (filteredSchedule?.length) {
              return {
                ...row,
                schedule: filteredSchedule,
              };
            } else return undefined;
          })
          .filter(row => !!row);
        setCheckedRows(rows.map(row => row.id));
        setTableRows(rows);
      }
    }
  }, [availabilityData, selectedDate]);

  const columns = useMemo(
    () =>
      getAvailabilityColumns({
        checkedRows,
      }),
    [checkedRows],
  );

  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={columns}
        headerHeight={44}
        noRowsOverlay={noRowsOverlay}
        isLoading={isLoading}
        rows={tableRows}
        withoutPagination
      />
    </Box>
  );
}

AvailabilityTable.propTypes = {
  availabilityData: instanceOf(Object),
  isLoading: bool,
  noRowsOverlay: func,
  selectedDate: instanceOf(Object),
};
export default AvailabilityTable;