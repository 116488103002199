import { useAddCallOutMutation } from '../../../../../api/Scheduler/api';
import moment from 'moment';
import { LOCAL_STORAGE_KEYS } from '../../../../../pages/Scheduling/constants';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  DATE_ONLY_STANDARD_FORMAT,
  FULL_DATE_H24_STANDARD_FORMAT,
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
} from '../../../../../shared/constants';
import { convertDateToUTC } from '../../../../../shared/utils/common';
import AlertContext from '../../../../../components/Alert';
import CustomDialog from '../../../../../components/Dialog';
import { ScheduleCaregiverManagementContext } from '../../../context';
import { callOutSteps } from '../constants';
import { checkRequiredFieldsFilled } from '../utils';
import SettingsView from './components/SettingsView';
import { filterCaregiversByTeams } from './constants';
import OptimizerCheck from '../../../../../components/Optimizer/OptimizerCheck';
import { VisitInfoTypeContext } from '../../../../../shared/context';

export default function CreateCallOut() {
  const { reFetchCalendarEvents } = useContext(VisitInfoTypeContext);
  const { setAlert } = useContext(AlertContext);
  const [fieldsError, setFieldsError] = useState(null);
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);

  const {
    addingCallOutStep,
    callOutData,
    closeCallOutCreation,
    setCallOutData,
    chosenCaregiver,
    caregiversList,
  } = useContext(ScheduleCaregiverManagementContext);

  const [addCallOut, { isLoading: isUpdating, error: addCallOutError }] = useAddCallOutMutation();

  useEffect(() => {
    if (addCallOutError) {
      const errorData = addCallOutError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [addCallOutError, setAlert]);

  const isDataChanged = useMemo(
    () => Object.values(callOutData).some(value => !!value),
    [callOutData],
  );

  const isRequiredFieldsFilled = useMemo(
    () => checkRequiredFieldsFilled(callOutData),
    [callOutData],
  );

  const closeDialog = useCallback(() => {
    closeCallOutCreation();
    setFieldsError(null);
  }, [closeCallOutCreation]);

  const getFormattedDate = ({ date, time }) =>
    date && time && `${date.format(ISO_DATE_ONLY_FORMAT)}T${time?.format(H24_TIME_FORMAT)}`;

  const openOptimizer = () => {
    setOpenCheckOptomizer(true);

    const requestStartDate = getFormattedDate({
      date: moment(callOutData?.callOutStartDate, DATE_ONLY_STANDARD_FORMAT),
      time: moment(callOutData?.callOutStartTime, H12_TIME_FORMAT),
    });
    const requestEndDate = getFormattedDate({
      date: moment(callOutData?.callOutEndDate, DATE_ONLY_STANDARD_FORMAT),
      time: moment(callOutData?.callOutEndTime, H12_TIME_FORMAT),
    });
    const data = {
      caregiverId: callOutData.caregiver.id,
      from: requestStartDate,
      to: requestEndDate,
      details: callOutData.details,
      isCallout: callOutData.isCallout,
      ptoType: callOutData.ptoType,
      status: callOutData.status,
      allDay: callOutData.allDay,
    };

    setCheckData({ ...data });
  };

  const optimizeScheduling = () => {
    addCallOut({ ...checkData });
    reFetchCalendarEvents();
    closeDialog();
  };

  const activeCaregivers = useMemo(
    () =>
      caregiversList?.filter(
        ({ primaryTeams, secondaryTeams, status }) =>
          status?.toLowerCase() === 'active' && (primaryTeams?.length || secondaryTeams?.length),
      ),
    [caregiversList],
  );
  const chosenFilterTeams = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.teamsFilter));
  const filteredCaregivers = useMemo(
    () => filterCaregiversByTeams(activeCaregivers, chosenFilterTeams),
    [activeCaregivers, chosenFilterTeams],
  );
  return (
    <>
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        disabledSubmit={!isDataChanged || !isRequiredFieldsFilled || !!fieldsError}
        isLoading={isUpdating}
        openDialog={addingCallOutStep === callOutSteps.addCallOut}
        submitButtonName="Optimize scheduling"
        submitCallback={openOptimizer}
        title="Callout"
      >
        <SettingsView
          activeCaregivers={filteredCaregivers}
          caregiversLoading={false}
          callOutData={callOutData}
          error={fieldsError}
          setCallOutData={setCallOutData}
          setError={setFieldsError}
          chosenCaregiver={chosenCaregiver}
        />
        <OptimizerCheck
          data={[checkData]}
          dataObjName="ptoObj"
          submitFunction={optimizeScheduling}
          open={openCheckOptomizer}
          openToggle={setOpenCheckOptomizer}
        />
      </CustomDialog>
    </>
  );
}
