import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import styles from './style.module.css';
import { WEEK_DAYS } from '../../shared/constants';

const formatKeyName = key => {
  if (!key || typeof key !== 'string') return '';
  return key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
};

const getDayNameById = id => {
  const day = WEEK_DAYS.find(day => day.id === id);
  return day ? day.name : id;
};

const formatValue = (value, fieldKey, details = [], idField = 'id', nameField = 'name') => {
  
  if (fieldKey === 'days' && value) {
    return value?.map(getDayNameById).join(', ');
  }

  if (Array.isArray(value)) {
    return value
      .map(item => {
        if (item && item[idField] && item[nameField]) {
          return item[nameField];
        }
        return JSON.stringify(item); 
      })
      .join(', ');
  }

  if (typeof value === 'object' && value !== null) {
    if (value?.name) {
      return value?.name.toString(); 
    }
    return Object.entries(value)
      .map(([key, val]) => `${key}: ${formatValue(val, key, details, idField, nameField)}`)
      .join(', ');
  }

  return value?.toString() || 'None';
};

const getHistoryList = arr => (
  <ul className={styles.bulletList}>
    {arr?.data?.map((item, index) => {
      const key = `${arr.action}-${index}-${arr.id}`;

      if (arr.action === 'edited') {
        return (
          <li
            key={key}
            className={
              !item?.newValue || Object.values(item?.newValue).includes('None')
                ? `${styles.bulletListItem} ${styles.removedType}`
                : styles.bulletListItem
            }
          >
            <span className={styles.boldWeight}>{formatKeyName(item?.name)}'s </span>
            {Object.keys(item?.newValue || {}).map(fieldKey => (
              <span key={fieldKey}>
                {formatKeyName(fieldKey)} is changed from
                <span className={styles.oldTime}>
                  {formatValue(item?.oldValue?.[fieldKey], fieldKey) || 'None'}
                </span>{' '}
                to
                <span className={styles.newTime}>
                  {formatValue(item?.newValue?.[fieldKey], fieldKey) || 'None'}
                </span>
              </span>
            ))}
          </li>
        );
      }

      if (arr.action === 'removed') {
        return (
          <li key={key} className={`${styles.bulletListItem} ${styles.removedType}`}>
            <span className={styles.boldWeight}>
              {item?.oldValue?.name ||
                `${item?.oldValue?.firstName || ''} ${item?.oldValue?.lastName || ''}`}
            </span>
            {' was removed'}
          </li>
        );
      }

      if (arr.action === 'added') {
        return (
          <li key={key} className={`${styles.bulletListItem} ${styles.addedType}`}>
            <span className={styles.boldWeight}>
              {item?.newValue?.name ||
                `${item?.newValue?.firstName || ''} ${item?.newValue?.lastName || ''}`}{' '}
            </span>
            {' was added'}
          </li>
        );
      }

      return null;
    })}
  </ul>
);

export default function HistoryBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Object),
};
