import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, MenuItem, Select } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import CustomTooltip from '../../../../../components/CustomTooltip';
import SelectRenderValue from './SelectRenderValue';
import { customPaperStyles, customSelectItem, customSelectStyles } from './style';

export default function TeamsSelect({
  id = '',
  isRegionsSelect = false,
  disabled = false,
  options = [],
  placeHolder = '',
  selectedValue = [],
  setValue = () => {},
  isOpen = false,
  setIsOpen = () => {},
}) {
  const handleChangeTeams = clickedOption => {
    let newDetails = [];
    let newIds = [];
    const isAlreadySelected = selectedValue.find(item => item?.id === clickedOption.id);
    if (isAlreadySelected) {
      newDetails = selectedValue.filter(item => item?.id !== clickedOption.id);
      newIds = newDetails.map(item => item.id);
    } else {
      newDetails = [...selectedValue, clickedOption];
      newIds = newDetails.map(item => item?.id);
    }
    setValue(newDetails, newIds);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const selectedIds = selectedValue?.length
    ? selectedValue.map(item => item?.id)
    : ['None Selected'];

  return (
    <Select
      IconComponent={ExpandMoreIcon}
      className={'has-selected-items'}
      multiple
      disabled={disabled}
      sx={{ ...customSelectStyles }}
      onOpen={handleOpen}
      onClose={handleClose}
      open={isOpen}
      MenuProps={{
        PaperProps: {
          sx: customPaperStyles,
        },
      }}
      renderValue={() => (
        <SelectRenderValue
          options={options}
          placeHolder={placeHolder}
          selectedValue={selectedValue}
        />
      )}
      value={selectedIds}
    >
      {options?.map((option, index) => (
        <CustomTooltip key={index} title={''} placement="top-start">
          <span>
            <MenuItem
              disabled={false}
              disableRipple
              sx={{ ...customSelectItem }}
              value={option.id}
              onClick={() => handleChangeTeams(option)}
            >
              <Checkbox
                checked={
                  !selectedValue?.length
                    ? false
                    : selectedValue?.find(item => item?.id === option.id)
                    ? true
                    : false
                }
                sx={{
                  padding: '5px 9px',
                }}
              />
              {option.name}
            </MenuItem>
          </span>
        </CustomTooltip>
      ))}
    </Select>
  );
}

TeamsSelect.propTypes = {
  id: string,
  isRegionsSelect: bool,
  disabled: bool,
  options: instanceOf(Array),
  placeHolder: string,
  selectedValue: instanceOf(Array),
  setValue: func,
  isOpen: bool,
  setIsOpen: func,
};
