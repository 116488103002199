import { Box, Typography } from '@mui/material';
import StarIcon from '../../../../img/icons/star-icon.svg';
import WarningIconYellow from '../../../../img/icons/warning-icon.svg';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import CustomTooltip from '../../../../components/CustomTooltip';
import { teamsTooltipComponent } from '../../constants';
import { detailsInfoKeysStyles } from '../styles';
import AdditionalInfoAndStatuses from './AdditionalInfoAndStatuses';
import CaregiverInfo from './CaregiverInfo';
import DetailsWrapper from './DetailsWrapper';
import NotAllocatedHeaderComponent from './NotAllocatedHeaderComponent';

function CustomHeader({
  caregiver = {},
  editMode = false,
  isDateHoliday = {},
  selectedDate = {},
  resourceStatistics = {},
}) {
  const calculateColor = () => {
    if (caregiver.utilizationRate < 50) {
      return '#FF3B30';
    }
    if (caregiver.utilizationRate < 60) {
      return '#FF9C28';
    }
    return '#09AF00';
  };
  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {caregiver.title === 'Not Allocated Visits' ? (
        <NotAllocatedHeaderComponent caregiver={caregiver} editMode={editMode} />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            className="CaregiversBox"
            sx={{
              display: 'flex',
              padding: '10px 10px 10px 10px',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CaregiverInfo caregiver={caregiver} editMode={editMode} />
              <AdditionalInfoAndStatuses
                caregiver={caregiver}
                editMode={editMode}
                isDateHoliday={isDateHoliday}
                selectedDate={selectedDate}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: '8px',
              }}
            >
              {caregiver?.primaryTeamsDetails?.length > 0
                ? teamsTooltipComponent(caregiver.primaryTeamsDetails)
                : 'N/A'}
              <img src={StarIcon} alt="" />
              {caregiver?.secondaryTeamsDetails?.length > 0 &&
                teamsTooltipComponent(caregiver.secondaryTeamsDetails)}
            </Box>
            <Box sx={{ ml: '6px' }}>
              <Box sx={{ display: 'flex', mb: '2px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}>Scheduled Hours:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {caregiver?.violates30hoursAWeekRule && (
                    <CustomTooltip title={caregiver.violates30hoursAWeekRule} placement="right">
                      <img src={WarningIconYellow} alt="warningIcon" />
                    </CustomTooltip>
                  )}
                  <CustomTooltip title={caregiver?.violates50PercentADayRule} placement="right">
                    <Typography
                      sx={
                        caregiver.violates50PercentADayRule
                          ? {
                              color: '#FF3B30',
                              fontSize: '12px',
                              fontWeight: 600,
                            }
                          : { fontSize: '12px' }
                      }
                    >
                      {resourceStatistics?.scheduledHours?.length
                        ? resourceStatistics?.scheduledHours
                        : 'N/A'}
                    </Typography>
                  </CustomTooltip>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: '3px' }}>
                <Typography
                  sx={{
                    ...detailsInfoKeysStyles,
                    fontSize: '12px',
                    fontWeight: 700,
                  }}
                >
                  Utilized Rate:
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: calculateColor(),
                  }}
                >
                  {resourceStatistics?.utilizationRate}%
                </Typography>
              </Box>
            </Box>
          </Box>
          <DetailsWrapper resourceStatistics={resourceStatistics} />
        </Box>
      )}
    </Box>
  );
}
export default CustomHeader;

CustomHeader.propTypes = {
  caregiver: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  selectedDate: instanceOf(Object),
  editMode: bool,
  resourceStatistics: instanceOf(Object),
};
