import { Box, Collapse, Typography } from '@mui/material';
import moment from 'moment';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { H12_TIME_FORMAT, visitStatusesMap } from '../../../shared/constants';
import { infoKeyField, infoRowEditableInfoWrapper, infoRowWrapperStyles } from '../styles';
import VisitActualTimesSetup from './ActualTimeSetup';
import ActualTimeWarning from './ActualTimeWarning';
import EditIconButton from './EditIconButton';

export default function ActualTimeFields({
  actualTimeEnd = '',
  actualTimeStart = '',
  editActualTime = false,
  enableToEditActualTime = false,
  temporaryVisitData = {},
  setEditActualTime = () => {},
}) {
  const warningTitle = useMemo(() => {
    if (
      ![visitStatusesMap.inProgress, visitStatusesMap.completed].includes(
        temporaryVisitData?.status,
      ) ||
      !temporaryVisitData.duration ||
      !temporaryVisitData?.arrivalTime
    ) {
      return null;
    }
    const marginOfErrorInMinutes = 30;
    const minActualStartValue = moment(
      temporaryVisitData?.arrivalTimeStart,
      H12_TIME_FORMAT,
    ).subtract(marginOfErrorInMinutes, 'minutes');
    const maxActualEndValue = moment(temporaryVisitData?.arrivalTimeEnd, H12_TIME_FORMAT).add(
      marginOfErrorInMinutes,
      'minutes',
    );
    const actualStartMoment = moment(actualTimeStart, H12_TIME_FORMAT);
    const actualEndMoment = moment(actualTimeEnd, H12_TIME_FORMAT);
    const actualTimeOutOfArrivalTime =
      actualStartMoment.isBefore(minActualStartValue, 'minutes') ||
      actualStartMoment.isAfter(maxActualEndValue, 'minutes');
    if (actualTimeOutOfArrivalTime) {
      return 'The start time of the visit is outside of the scheduled arrival window';
    }
    if (actualEndMoment.isSameOrBefore(minActualStartValue, 'minutes')) {
      return 'Wrong actual time. Please, edit actual time.';
    }
    return null;
  }, [actualTimeEnd, actualTimeStart, temporaryVisitData]);

  const [actualTimeStartValue, setActualTimeStartValue] = useState(null);
  const [actualTimeEndValue, setActualTimeEndValue] = useState(null);

  useEffect(() => {
    if (actualTimeStart) {
      setActualTimeStartValue(moment(actualTimeStart).format(H12_TIME_FORMAT));
    }
    if (actualTimeEnd) {
      setActualTimeEndValue(moment(actualTimeEnd).format(H12_TIME_FORMAT));
    }
  }, [actualTimeEnd, actualTimeStart]);
  return (
    <Box>
      {warningTitle && <ActualTimeWarning title={warningTitle} />}
      {!editActualTime && (
        <Box sx={infoRowWrapperStyles}>
          <Typography sx={infoKeyField}>Actual Start Time:</Typography>
          <Box sx={infoRowEditableInfoWrapper}>
            <Typography variant="body1">{actualTimeStartValue}</Typography>
            {enableToEditActualTime && (
              <EditIconButton
                onEdit={() => setEditActualTime(true)}
                tooltip="Edit actual start time"
              />
            )}
          </Box>
        </Box>
      )}
      {!editActualTime && (
        <Box sx={infoRowWrapperStyles}>
          <Typography sx={infoKeyField}>Actual End Time:</Typography>
          <Typography variant="body1">{actualTimeEndValue}</Typography>
        </Box>
      )}
      <Collapse in={editActualTime} timeout={300}>
        <VisitActualTimesSetup cancelEditing={() => setEditActualTime(false)} />
      </Collapse>
    </Box>
  );
}

ActualTimeFields.propTypes = {
  actualTimeEnd: string,
  actualTimeStart: string,
  editActualTime: bool,
  enableToEditActualTime: bool,
  temporaryVisitData: instanceOf(Object),
  setEditActualTime: func,
};
