import { Box, Button } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import ButtonsGroup from '../../../../../components/ButtonsGroup';

export default function AvailabilityControls({
  availabilityData = [],
  setOpenDialog = () => {},
  effectiveDates = [],
  setSelectedDate = () => {},
  selectedDate = {},
}) {
  const changedate = target => {
    let infoType = target.name;
    if (!infoType) {
      infoType = '1';
    }
    const thisDate = effectiveDates.find(date => date.infoType === infoType);
    setSelectedDate(thisDate);
  };

  return (
    <Box>
      {effectiveDates.length > 0 && (
        <ButtonsGroup
          buttons={effectiveDates}
          changeSelected={({ target }) => changedate(target)}
          selected={selectedDate.infoType}
          isChangedView
        />
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpenDialog(true)}
        sx={{ ml: '28px' }}
        disableElevation
      >
        {availabilityData?.length ? 'EDIT MODE' : 'ADD AVAILABILITY'}
      </Button>
    </Box>
  );
}

AvailabilityControls.propTypes = {
  availabilityData: instanceOf(Array),
  setOpenDialog: func,
  effectiveDates: instanceOf(Array),
  setSelectedDate: func,
  selectedDate: instanceOf(Object),
};