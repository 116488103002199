import { blue } from '@mui/material/colors';
import { COLORS } from '../../shared/styles/Theme';

export const customAccordionSummaryStyles = (status, hardConstraints, softConstraints) => {
  let backgroundColor = COLORS.black[200];
  if (status === 'running') {
    backgroundColor = blue[200];
  }
  if (status === 'finished') {
    if (hardConstraints) {
      backgroundColor = COLORS.red[200];
    } else if (softConstraints) {
      backgroundColor = COLORS.yellow[200];
    } else {
      backgroundColor = COLORS.green[200];
    }
  }
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 0,
    margin: '12px 0',
    cursor: 'pointer',
    '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '32px',
    },
    '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
      justifyContent: 'space-between',
      margin: 0,
      paddingRight: '5px',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    backgroundColor: backgroundColor,
    borderRadius: '4px',
    width: '50%',
  };
};

export const customAccordionStyles = {
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '0 0 20px 0',
  },
  '&:last-of-type, &.Mui-expanded:last-of-type': {
    borderRadius: 0,
  },
  '& .MuiCollapse-root': {
    width: '100%',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const visitAccordionSummaryTitle = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  ml: '5px',
  cursor: 'pointer',
  gap: '8px',
  justifyContent: 'center',
};

export const optimizerDateTitle = {
  lineHeight: '32px',
  cursor: 'pointer',
};

export const visitAccordionDetailsContainer = {
  padding: '5px 20px 0px 20px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '50%',
  margin: 'auto',
};

export const visitDetailsContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  gap: '10px',
};

export const allConstraintsList = {
  margin: '0px',
  paddingLeft: '25px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
};

export const visitDivider = {
  height: '1px',
  width: '100%',
  margin: '5px 0px',
};

export const emptyConstraintsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

export const customDialogButtonStyles = {
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: 'transparent',
    color: COLORS.blue[500],
    opacity: 0.7,
  },
};
