import { autocompleteClasses } from '@mui/material/Autocomplete';
import { customInputStyles } from '../../../../../../components/Inputs/styles';
import { COLORS } from '../../../../../../shared/styles/Theme';

export const autocompleteStyles = {
  width: '100%',
  padding: 0,
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: 'none',
  },
  '& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
    backgroundColor: COLORS.blue[50],
  },
  '& .MuiAutocomplete-endAdornment': {
    top: '50%',
  },
  '& .MuiInputBase-root': {
    border: 'none',
    backgroundColor: COLORS.blue[50],
    padding: 0,
    '.MuiFilledInput-input': {
      padding: '0 16px',
    },
    fieldset: {
      border: 'none',
    },
  },
};

export const autocompletePaperStyles = {
  padding: '18px 0',
  boxShadow: '0px 4px 12px 2px rgba(180, 180, 180, 0.2)',
  borderRadius: '10px',
  '& .MuiAutocomplete-listbox': {
    maxHeight: '300px',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    "& .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: COLORS.blue[50],
    },
  },
};

export const textFieldStyles = {
  ...customInputStyles,
  color: COLORS.blue[900],
  height: '40px',
};
