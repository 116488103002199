import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ErrorIcon from '../../img/icons/error-icon.svg';
import WarningIcon from '../../img/icons/warning-icon.svg';
import React, { useEffect, useState } from 'react';
import {
  allConstraintsList,
  customAccordionStyles,
  customAccordionSummaryStyles,
  emptyConstraintsContainer,
  optimizerDateTitle,
  visitAccordionDetailsContainer,
  visitAccordionSummaryTitle,
  visitDetailsContainer,
  visitDivider,
} from './styles';
import { H12_TIME_FORMAT } from '../../shared/constants';

const DateAccordion = ({ data }) => {
  const [expanded, setExpanded] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [visitDataToDisplay, setVisitDataToDisplay] = useState([]);

  const checkForConstraints = () => {
    const differences = data?.differences ?? [];
    let tempVisitDataToDisplay = [];
    setHasError(false);
    setHasWarning(false);
    for (const difference of differences) {
      const violatedHardConstraints = difference?.changes?.violatedHardConstraints?.new ?? [];
      const violatedSoftConstraints = difference?.changes?.violatedSoftConstraints?.new ?? [];
      if ([...violatedHardConstraints, ...violatedSoftConstraints].length > 0) {
        const visitId = difference?.id ?? '';
        const visit = [...(data?.editedVisits ?? []), ...(data?.addedVisits ?? [])].find(
          visit => visit?.id === visitId,
        );
        tempVisitDataToDisplay.push({
          clientName: visit?.clientName ?? 'Unknown Client',
          arrivalTime: visit?.arrivalTime,
          newViolatedHardConstraints: violatedHardConstraints,
          newViolatedSoftConstraints: violatedSoftConstraints,
        });
        if (violatedHardConstraints.length > 0) {
          setHasError(true);
        }
        if (violatedSoftConstraints.length > 0) {
          setHasWarning(true);
        }
      }
    }
    setVisitDataToDisplay(tempVisitDataToDisplay);
  };

  useEffect(() => {
    if (data) {
      checkForConstraints();
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Accordion elevation={0} expanded={expanded} sx={customAccordionStyles}>
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles(data?.type, hasError, hasWarning)}
      >
        <Box sx={visitAccordionSummaryTitle}>
          {hasWarning && <img src={WarningIcon} alt="Warning" />}
          {hasError && <img src={ErrorIcon} alt="Error" />}
          <Typography sx={optimizerDateTitle} variant="h5">
            {moment(data?.date).format('dddd, MMM D, YYYY')} - {data?.type}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={visitAccordionDetailsContainer}>
        {visitDataToDisplay.length ? (
          visitDataToDisplay.map((visit, index) => {
            const notFirstItem = index !== 0;
            const notLastItem = index !== visitDataToDisplay.length - 1;
            return (
              <Box
                sx={{
                  ...visitDetailsContainer,
                  ...(notFirstItem && { marginTop: '10px' }),
                }}
              >
                <Typography variant="h6">
                  {visit.clientName} - {moment(visit.arrivalTime).format(H12_TIME_FORMAT)}
                </Typography>
                <ul style={allConstraintsList}>
                  {visit.newViolatedHardConstraints.length > 0 ? (
                    <>
                      <Typography variant="h6" sx={{ display: 'list-item' }}>
                        New Violoted Hard Constraints:
                      </Typography>
                      <ul style={{ margin: '0px', paddingLeft: '25px' }}>
                        {visit.newViolatedHardConstraints.map(softConstraint => (
                          <li style={{ listStyleType: 'upper-roman' }}>
                            <Typography variant="body1">{softConstraint}</Typography>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                  {visit.newViolatedSoftConstraints.length > 0 ? (
                    <>
                      <Typography variant="h6" sx={{ display: 'list-item' }}>
                        New Violoted Soft Constraints:
                      </Typography>
                      <ul style={{ margin: '0px', paddingLeft: '25px' }}>
                        {visit.newViolatedSoftConstraints.map(softConstraint => (
                          <li style={{ listStyleType: 'upper-roman' }}>
                            <Typography variant="body1">{softConstraint}</Typography>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
                {notLastItem && <Divider sx={visitDivider} />}
              </Box>
            );
          })
        ) : (
          <Box sx={emptyConstraintsContainer}>
            <Typography variant="h6">No New Constraint Violations</Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DateAccordion;
