import AddIcon from '@mui/icons-material/Add';
import { Box, Button, IconButton, Typography } from '@mui/material';
import {
  useApplyPreferredCaregiverMutation,
  useCheckPreferredCaregiverAvailabilityMutation,
  useUnmarkPreferredCaregiverMutation,
} from '../../../../api/commonApi/api';
import DeleteIcon from '../../../../img/icons/iconButtons/delete-icon.svg';
import OutlinedPreferredIcon from '../../../../img/icons/preferred-icon-outlined.svg';
import { bool, func, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import {
  customOutlinedButton,
  infoFieldWrapper,
  infoKeyField,
  infoRowEditableInfoWrapper,
  infoRowWrapperStyles,
} from '../../styles';
import EditIconButton from '../EditIconButton';
import LinkItem from '../VisitInfoFields/LinkItem';
import {
  VisitPreferredCaregiverSetup,
  VisitUnmarkPreferredCaregiver,
} from './VisitPreferedCaregiverSetup';

export default function VisitPrimaryCaregiver({
  cancelPrimaryCaregiverEditing = () => {},
  caregiverName = '',
  caregiverId = '',
  editPrimaryCaregiver = () => {},
  editingPrimaryCaregiver = false,
  enableToEditPrimaryCaregiver = false,
  onUnmarkPreferredCaregiver = false,
  preferredCaregiverId = '',
  unMarkingPreferredCaregiver = false,
}) {
  const { setAlert } = useContext(AlertContext);
  const [preferredCaregiverAvailability, setPreferredCaregiverAvailability] = useState(null);
  const [
    checkPreferredCaregiverAvailability,
    {
      data: checkPreferredCaregiverAvailabilityData,
      error: checkShadowCaregiverAvailabilityError,
      isLoading: checkPreferredCaregiverAvailabilityLoading,
    },
  ] = useCheckPreferredCaregiverAvailabilityMutation();
  const [applyPreferredCaregiver, { isLoading: applyLoading, error: updateVisitDetailsError }] =
    useApplyPreferredCaregiverMutation();
  const [unmarkPreferredCaregiver, { isLoading: unmarkLoading, error: unmarkError }] =
    useUnmarkPreferredCaregiverMutation();
  useEffect(() => {
    const errorData =
      updateVisitDetailsError || checkShadowCaregiverAvailabilityError || unmarkError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [checkShadowCaregiverAvailabilityError, setAlert, unmarkError, updateVisitDetailsError]);

  useEffect(() => {
    if (checkPreferredCaregiverAvailabilityData) {
      setPreferredCaregiverAvailability(checkPreferredCaregiverAvailabilityData);
    }
  }, [checkPreferredCaregiverAvailabilityData]);

  const deletePreferredCaregiver = () => onUnmarkPreferredCaregiver(true);

  return (
    <Box>
      {(applyLoading || unmarkLoading || checkPreferredCaregiverAvailabilityLoading) && <Loader />}
      {!editingPrimaryCaregiver && !unMarkingPreferredCaregiver && (
        <Box sx={{ ...infoRowWrapperStyles, alignItems: 'start' }}>
          <Typography sx={infoKeyField}>Primary Caregiver:</Typography>
          <Box sx={infoFieldWrapper}>
            <Box sx={infoRowEditableInfoWrapper}>
              {caregiverId !== 'NoCaregiver' && caregiverName ? (
                <LinkItem
                  isPreferredCaregiver={preferredCaregiverId === caregiverId}
                  link={`/caregivers/${caregiverId}`}
                  name={caregiverName}
                />
              ) : (
                <Typography>-</Typography>
              )}
              {enableToEditPrimaryCaregiver && !!preferredCaregiverId && (
                <Box sx={{ display: 'flex', gap: '12px' }}>
                  <EditIconButton
                    onEdit={editPrimaryCaregiver}
                    tooltip="Edit preferred caregiver"
                  />
                  <IconButton
                    aria-label="delete"
                    disableRipple
                    onClick={deletePreferredCaregiver}
                    sx={{ p: 0 }}
                  >
                    <img src={DeleteIcon} alt="Delete" />
                  </IconButton>
                </Box>
              )}
            </Box>
            {enableToEditPrimaryCaregiver && !preferredCaregiverId && (
              <Button
                color="secondary"
                disabled={!enableToEditPrimaryCaregiver}
                disableElevation
                onClick={editPrimaryCaregiver}
                startIcon={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                    <AddIcon sx={{ fontSize: '14px' }} />
                    <img src={OutlinedPreferredIcon} alt="preferred-icon" />
                  </Box>
                }
                sx={customOutlinedButton}
                variant="outlined"
              >
                Preferred caregiver
              </Button>
            )}
          </Box>
        </Box>
      )}
      {unMarkingPreferredCaregiver && (
        <VisitUnmarkPreferredCaregiver
          applyUnmarkCaregiver={unmarkPreferredCaregiver}
          cancelUnmarkCaregiver={() => onUnmarkPreferredCaregiver(false)}
          caregiverName={caregiverName}
        />
      )}
      {editingPrimaryCaregiver && (
        <VisitPreferredCaregiverSetup
          applyPreferredCaregiver={applyPreferredCaregiver}
          cancelEditing={cancelPrimaryCaregiverEditing}
          checkPreferredCaregiverAvailability={checkPreferredCaregiverAvailability}
          checkPreferredCaregiverAvailabilityData={preferredCaregiverAvailability}
          clearPreferredCaregiverAvailabilityData={() => setPreferredCaregiverAvailability(null)}
        />
      )}
    </Box>
  );
}

VisitPrimaryCaregiver.propTypes = {
  cancelPrimaryCaregiverEditing: func,
  caregiverName: string,
  caregiverId: string,
  editPrimaryCaregiver: func,
  editingPrimaryCaregiver: bool,
  enableToEditPrimaryCaregiver: bool,
  onUnmarkPreferredCaregiver: func,
  preferredCaregiverId: string,
  unMarkingPreferredCaregiver: bool,
};
