import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetUserDataByIdQuery } from '../../../../../../../../api/Clients/api';
import NoRowsComponent from '../../../../../../../../pages/ClientDetails/components/NoRowsComponent';
import VisitDateAccordion from '../../../../../../../../pages/ClientDetails/components/VisitDateAccordion';
import { bool, func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../../../components/Alert';
import CustomTable from '../../../../../../../../components/Table';
import VisitSummary from './VisitSummary';
import { editColumns } from './constants';
import { stepContentWrapperStyles, tasksManagementTableStyles } from './styles';
import { getVisitAccordionTitle } from './utils';
import { useGetClientTasksListQuery } from '../../../../../../../../api/CarePlan/api';
import moment from 'moment';
export default function TasksManagement({
  goToEditTasks = () => {},
  setAvailableVisitTasks = () => {},
  visitParams = {},
  availableVisitTasks = [],
  stepDataLoading = true,
  setStepDataLoading = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const apiRef = useGridApiRef();
  const { data: userData, error: getUserDataByIdError } = useGetUserDataByIdQuery(id);

  const {
    data: clientTasks,
    error: getClientTasksError,
    isLoading: isLoadingClientTasks,
  } = useGetClientTasksListQuery({ clientId: id }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (getUserDataByIdError || getClientTasksError) {
      const errorData = getUserDataByIdError || getClientTasksError;

      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getUserDataByIdError, getClientTasksError, setAlert]);

  useEffect(() => {
    if (clientTasks) {
      const validTasks = clientTasks?.filter(clientTask => {
        const arrivalStart = moment(visitParams.arrivalStart);
        const visitEnd = moment(visitParams.arrivalEnd).add(visitParams?.duration, 'minutes');
        let dayOfWeek = moment(visitParams?.date).day() - 1;
        if (dayOfWeek === -1) {
          dayOfWeek = 6;
        }
        const validTimeOfDays = clientTask.timeOfDaysDetails?.filter(timeOfDays => {
          const timeFrom = moment(
            `${visitParams?.date.substring(0, 10)} ${timeOfDays.from}`,
            'YYYY-MM-DD H:mm',
          );
          const timeTo = moment(
            `${visitParams?.date.substring(0, 10)} ${timeOfDays.to}`,
            'YYYY-MM-DD H:mm',
          );
          return (
            (arrivalStart.isSameOrAfter(timeFrom) && arrivalStart.isBefore(timeTo)) ||
            (visitEnd.isAfter(timeFrom) && visitEnd.isSameOrBefore(timeTo))
          );
        });
        return (
          clientTask.visitTypes.includes(visitParams.visitType) &&
          validTimeOfDays.length > 0 &&
          clientTask.days?.includes(dayOfWeek)
        );
      });
      setAvailableVisitTasks(validTasks);
      setStepDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTasks]);

  const getRowId = row => (row ? row.id : '');

  const summaryData = {
    client: visitParams.clientName,
    address: [
      userData?.street,
      userData?.city,
      userData?.state,
      userData?.zipCode,
      userData?.country,
    ]
      .filter(addressLine => addressLine && addressLine.length > 0)
      .join(', '),
    visitType: visitParams.visitType.name,
    duration: visitParams.duration,
  };

  const getNoRowsComponent = useCallback(
    () => <NoRowsComponent goToEditTasks={goToEditTasks} />,
    [goToEditTasks],
  );

  const updateTasks = newTasks => {
    setAvailableVisitTasks(newTasks);
  };

  return (
    <Box sx={stepContentWrapperStyles}>
      <VisitSummary data={summaryData} />
      <VisitDateAccordion
        key={visitParams.date}
        preferredCaregiver={visitParams.caregiverId ? visitParams.caregiverName : null}
        tasksCount={availableVisitTasks?.length ?? 0}
        title={getVisitAccordionTitle(visitParams)}
      >
        <CustomTable
          apiRef={apiRef}
          columns={editColumns({ allTasks: availableVisitTasks, updateTasks: updateTasks })}
          customStyles={tasksManagementTableStyles(!availableVisitTasks?.length)}
          headerHeight={34}
          getRowId={getRowId}
          isLoading={isLoadingClientTasks || stepDataLoading}
          noRowsOverlay={() => getNoRowsComponent()}
          onRowClick={() => {}}
          rows={availableVisitTasks ?? []}
          withoutPagination
        />
      </VisitDateAccordion>
    </Box>
  );
}

TasksManagement.propTypes = {
  goToEditTasks: func,
  setAvailableVisitTasks: func,
  visitParams: instanceOf(Object),
  availableVisitTasks: instanceOf(Object),
  stepDataLoading: bool,
  setStepDataLoading: func,
};
