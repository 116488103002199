import { Box, Typography } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import OptimizerCheck from '../../../../../../../../components/Optimizer/OptimizerCheck';
import { COLORS } from '../../../../../../../../shared/styles/Theme';

function CheckAvailability({
  setStepDataLoading = () => {},
  submitStep = () => {},
  visitParams = {},
  resetToInitialView = () => {},
  setVisitParams = () => {},
}) {
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(true);

  useEffect(() => {
    setStepDataLoading(true);
  }, [setStepDataLoading]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* TODO: Update this? */}
      <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
        Optimized
      </Typography>
      <OptimizerCheck
        data={{
          added: [visitParams],
          edited: [],
          deleted: [],
        }}
        dataObjName="visitObj"
        submitFunction={data => {
          const visitToCreate = data?.find(visit => visit?.id === visitParams?.id);
          if (visitToCreate) {
            setVisitParams(visitToCreate);
            submitStep();
          } else {
            resetToInitialView();
          }
        }}
        cancelFunction={resetToInitialView}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
        returnAPIData={true}
      />
    </Box>
  );
}

CheckAvailability.propTypes = {
  setStepDataLoading: func,
  submitStep: func,
  visitParams: instanceOf(Object),
  resetToInitialView: func,
  setVisitParams: func,
};

export default React.memo(CheckAvailability);
