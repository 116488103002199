import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import LocationsFilter from '../../../../../components/FiltersComponents/LocationFilter';
import SearchFilter from '../../../../../components/FiltersComponents/Search';
import StatusesFilter from '../../../../../components/FiltersComponents/Select';
import { TIME_OFF_REQUESTS_STATUSES } from '../constants';
import CustomDatePicker from './CustomDatePicker';
import { customFilterStyles, datesDividerStyles, filterStyles } from './styles';

export default function Filters({
  debouncedSearch = () => {},
  filterData = {},
  setFilterData = () => {},
  setTeamFilter = () => {},
  teamsArray = [],
  teamsFilter = {},
}) {
  const onSelectChange = (params) => {
    setFilterData({
      ...filterData,
      status: params.value,
    });
  };
  const onStartDateChange = (date) => {
    setFilterData({
      ...filterData,
      datesRange: {
        startDate: date,
        endDate: filterData.datesRange.endDate,
      },
    });
  };
  const onEndDateChange = (date) => {
    setFilterData({
      ...filterData,
      datesRange: {
        startDate: filterData.datesRange.startDate,
        endDate: date,
      },
    });
  };
  return (
    <Box sx={filterStyles}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomDatePicker
          value={filterData?.datesRange?.startDate || moment()}
          label={
            filterData?.datesRange?.startDate?.format('MMM DD, YYYY') || ''
          }
          minDate={null}
          maxDate={filterData?.datesRange?.endDate || null}
          onDateChange={(newDate) => onStartDateChange(newDate)}
        />
        <Typography sx={datesDividerStyles}>–</Typography>
        <CustomDatePicker
          value={filterData?.datesRange?.endDate || moment()}
          label={filterData?.datesRange?.endDate?.format('MMM DD, YYYY') || ''}
          minDate={filterData.datesRange.startDate}
          maxDate={null}
          onDateChange={(newDate) => onEndDateChange(newDate)}
        />
      </Box>
      <SearchFilter
        changeValue={debouncedSearch}
        name="caregiver"
        placeholder="Caregiver"
        customFilterStyles={customFilterStyles}
      />

      <LocationsFilter
        locationsList={teamsArray}
        setFilters={setTeamFilter}
        filters={teamsFilter}
      />
      <StatusesFilter
        filterItems={filterData.status.map((status) => ({id: status}))}
        id="status"
        isMultiselect
        options={TIME_OFF_REQUESTS_STATUSES.map((status) => ({id: status, name: status}))}
        placeHolder="Status"
        setFilters={onSelectChange}
        showFiltersCount
      />
    </Box>
  );
}

Filters.propTypes = {
  debouncedSearch: func,
  filterData: instanceOf(Object),
  setFilterData: func,
  setTeamFilter: func,
  teamsArray: instanceOf(Array),
  teamsFilter: instanceOf(Object),
};
