import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import moment from 'moment';
import { bool, func, instanceOf, number, string } from 'prop-types';
import React, { useMemo } from 'react';
import { H12_TIME_FORMAT } from '../../shared/constants';
import { customDateTimePickerStyles } from './styles';

export default function CustomTimePicker({
  customStyles = [],
  disabled = false,
  isPMPlaceholder = false,
  isRequired = false,
  label = '',
  onChangeValue = () => {},
  timeMinutesStep = 1,
  value = undefined,
  views = [],
  withIcon = false,
  displayDefaultPlaceholder = true,
}) {
  const timeFieldClassName = useMemo(() => {
    if (disabled) {
      return 'disabled-time-picker';
    }
    if (!value?.isValid()) {
      return 'invalid-time-value';
    }
    return '';
  }, [disabled, value]);
  const defaultValuePlaceholder = useMemo(
    () => moment(isPMPlaceholder ? '00:00 PM' : '00:00 AM', H12_TIME_FORMAT),
    [isPMPlaceholder],
  );
  return (
    <TimeField
      disabled={disabled}
      className={timeFieldClassName}
      defaultValue={null}
      label={label}
      minutesStep={timeMinutesStep}
      required={isRequired}
      slotProps={{
        textField: {
          variant: 'filled',
          InputProps: withIcon
            ? {
                endAdornment: <AccessTimeIcon />,
              }
            : undefined,
        },
      }}
      views={views}
      onChange={onChangeValue}
      sx={[...customDateTimePickerStyles, ...(customStyles || [])]}
      value={
        value?.isValid() ? value : displayDefaultPlaceholder ? defaultValuePlaceholder : undefined
      }
    />
  );
}

CustomTimePicker.propTypes = {
  customStyles: instanceOf(Array),
  disabled: bool,
  isPMPlaceholder: bool,
  isRequired: bool,
  label: string,
  onChangeValue: func,
  timeMinutesStep: number,
  value: instanceOf(Object),
  views: instanceOf(Array),
  withIcon: bool,
  displayDefaultPlaceholder: bool,
};
