import { useGridApiRef } from '@mui/x-data-grid';
import moment from 'moment';
import { instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CALIFORNIA_TIME_ZONE, CONVERT_TO_UTC_FORMAT } from '../../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import { getNoData } from '../../../../../shared/utils/common';
import CustomTable from '../../../../../components/Table';
import {
  DEFAULT_SORT_MODAL,
  NO_DATA_TITLES,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  VISITS_STATUSES_TYPES,
  reschedulingVisitColumns,
} from '../constants';
import { tableStyles } from '../styles';
import VisitsAccordion from './VisitsAccordion';

function VisitsList({ visitsList = [], tabSubView = '' }) {
  const apiRef = useGridApiRef();
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODAL);
  const updateSorting = useCallback(data => setSortModel(data[0]), []);

  const { onOpenVisitInfoTypeDialog } = useContext(VisitInfoTypeContext);

  const onRowClick = ({ row }) => onOpenVisitInfoTypeDialog(row);

  const visitsByStartDate = useMemo(
    () => ({
      upcoming: visitsList.filter(({ arrivalStart }) => {
        const visitStartDate = moment(`${arrivalStart}`, CONVERT_TO_UTC_FORMAT);
        return visitStartDate.isAfter(moment().tz(CALIFORNIA_TIME_ZONE), 'day');
      }),
      past: visitsList.filter(({ arrivalStart }) => {
        const visitStartDate = moment(`${arrivalStart}`, CONVERT_TO_UTC_FORMAT);
        return visitStartDate.isSameOrBefore(moment().tz(CALIFORNIA_TIME_ZONE), 'day');
      }),
    }),
    [visitsList],
  );

  const getNoDataTitle = useCallback(
    () => getNoData({ title: NO_DATA_TITLES[tabSubView] }),
    [tabSubView],
  );

  return VISITS_STATUSES_TYPES.map(({ key, title }) => (
    <VisitsAccordion
      key={key}
      predefinedExpanded={key === 'upcoming'}
      tasksCount={visitsByStartDate[key].length}
      title={title}
    >
      <CustomTable
        apiRef={apiRef}
        columns={reschedulingVisitColumns}
        customStyles={tableStyles}
        headerHeight={44}
        noRowsOverlay={getNoDataTitle}
        onRowClick={onRowClick}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        rows={visitsByStartDate[key]}
        setSortModelState={updateSorting}
        sortModel={[sortModel]}
      />
    </VisitsAccordion>
  ));
}

VisitsList.propTypes = {
  visitsList: instanceOf(Array),
  tabSubView: string,
};

export default React.memo(VisitsList);
