import { Box, Button, DialogActions } from '@mui/material';
import { func } from 'prop-types';
import React from 'react';
import { COLORS } from '../../../../shared/styles/Theme';
import { disabledButtonStyles } from './styles';

export default function Actions({ openVisitDetailsDialog = () => {} }) {
  return (
    <DialogActions
      sx={{
        justifyContent: 'flex-end',
        width: '100%',
        padding: '12px 20px',
        borderTop: `1px solid ${COLORS.blue[200]}`,
      }}
    >
      <Box sx={{ display: 'flex', gap: '12px' }}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => openVisitDetailsDialog()}
          disableElevation
          sx={disabledButtonStyles}
        >
          Visit details
        </Button>
      </Box>
    </DialogActions>
  );
}

Actions.propTypes = {
  openVisitDetailsDialog: func,
};
