import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';
import { parseClientBlockedCaregiversResponse } from '../../pages/ClientDetails/utils';

export const CLIENTS_API_KEY = 'clientsApi';

const clientsApi = createApi({
  reducerPath: CLIENTS_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: builder => ({
    getClientsList: builder.query({
      query: () => ({
        url: 'crud/clients/',
        method: 'GET',
      }),
    }),
    // TODO migrate this query over
    getClientActivity: builder.query({
      query: ({ clientId }) => ({
        url: `activity/log/client/${clientId}`,
        method: 'GET',
      }),
    }),
    getUserDataById: builder.query({
      query: id => ({
        url: `crud/clients`,
        method: 'GET',
        params: { id },
      }),
      providesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    updateClientsInfo: builder.mutation({
      query: payload => ({
        url: `crud/clients/`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    getClientsCalendar: builder.query({
      query: params => ({
        url: `crud/visits/`,
        method: 'GET',
        params: {
          index: 'client-index',
          pk: 'clientId',
          pkValue: params.clientId,
          sk: 'active',
          skValue: 'True',
          filters: JSON.stringify([
            {
              key: 'date',
              value: params.date,
              operation: '>',
              preOperation: 'AND',
            },
          ]),
        },
      }),
    }),
    // TODO migrate this query over
    getClientsProfileHistory: builder.query({
      query: id => ({
        url: `history/clients/`,
        method: 'GET',
        params: {
          index: 'id-index',
          pk: 'id',
          pkValue: id,
        },
      }),
      providesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    checkClientSuspend: builder.query({
      query: ({ clientId, payload }) => ({
        url: `check/clients`,
        method: 'POST',
        body: {
          clientId,
          payload,
          action: 'suspend',
        },
      }),
    }),
    checkClientResume: builder.query({
      query: ({ clientId }) => ({
        url: `check/clients`,
        method: 'POST',
        body: {
          clientId,
          action: 'resume',
        },
      }),
    }),
    checkClientDeactivation: builder.query({
      query: ({ clientId, date }) => ({
        url: `check/clients`,
        method: 'POST',
        body: {
          clientId,
          date,
          action: 'deactivate',
        },
      }),
    }),
    checkClientActivation: builder.query({
      query: ({ clientId }) => ({
        url: `check/clients`,
        method: 'POST',
        body: {
          clientId,
          action: 'activate',
        },
      }),
    }),
    checkClientBlockedCaregiversChanges: builder.query({
      query: ({ clientId, payload }) => ({
        url: `check/clients`,
        method: 'POST',
        body: {
          clientId,
          payload,
          action: 'blockCaregiver',
        },
      }),
      transformResponse: response => parseClientBlockedCaregiversResponse(response),
    }),
    confirmClientSuspend: builder.mutation({
      query: userData => ({
        url: `crud/clients/`,
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmClientResume: builder.mutation({
      query: userData => ({
        url: `crud/clients/`,
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmClientDeactivation: builder.mutation({
      query: userData => ({
        url: `crud/clients/`,
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmClientActivation: builder.mutation({
      query: userData => ({
        url: `crud/clients/`,
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
    confirmBlockedCaregivers: builder.mutation({
      query: payload => ({
        url: `crud/clients`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: () => [{ type: 'clientsInfo', id: 'ALL' }],
    }),
  }),
});
export const {
  useGetClientsListQuery,
  useGetUserDataByIdQuery,
  useUpdateClientsInfoMutation,
  useGetClientsProfileHistoryQuery,
  useGetClientsCalendarQuery,
  useCheckClientSuspendQuery,
  useCheckClientResumeQuery,
  useCheckClientDeactivationQuery,
  useCheckClientActivationQuery,
  useCheckClientBlockedCaregiversChangesQuery,
  useConfirmClientSuspendMutation,
  useConfirmBlockedCaregiversMutation,
  useConfirmClientResumeMutation,
  useConfirmClientDeactivationMutation,
  useConfirmClientActivationMutation,
  useGetClientActivityQuery,
} = clientsApi;

export default clientsApi;
