import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useRef } from 'react';
import { DEFAULT_MAX_WORKING_HOUR, DEFAULT_MIN_WORKING_HOUR } from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import { getEventBoxClass, isWeekend } from '../../../../shared/utils/common';
import DefaultFiveDaysCalendar from '../../../../components/Calendar';
import SevenDaysCalendar from '../../../../components/SevenDaysCalendar';
import ClientCalendarEvent from './components/ClientCalendarEvent';
import ClientCalendarToolbar from './components/ClientCalendarToolbar';
import SevenDaysCalendarToolbar from './components/SevenDaysCalendarToolbar';
import { maxHour, minHour } from './utils';

export default function ClientsCalendarsView({
  calendarHeader = () => {},
  calendarDate = null,
  clientEvents = [],
  dayPropGetter = {},
  setCalendarDate = () => {},
}) {
  const eventWrapperRef = useRef();
  const weekendEvents = clientEvents?.some(event => isWeekend(event.start));
  const { onOpenVisitInfoTypeDialog } = useContext(VisitInfoTypeContext) || {};

  const onSelectEvent = data => {
    onOpenVisitInfoTypeDialog(data);
  };

  const getMinHour = () => (minHour(clientEvents) ? minHour(clientEvents) - 1 : 0);

  return (
    <Box sx={{ height: 'calc(100vh - 200px)' }} ref={eventWrapperRef}>
      {weekendEvents ? (
        <SevenDaysCalendar
          className="client-seven-days-calendar"
          customToolbar={SevenDaysCalendarToolbar}
          dateHeaderWrapper={calendarHeader}
          dayPropGetter={dayPropGetter}
          eventComponent={ClientCalendarEvent}
          eventStyleGetter={getEventBoxClass}
          events={clientEvents}
          date={calendarDate}
          firstVisit={clientEvents?.length > 0 ? getMinHour() : DEFAULT_MIN_WORKING_HOUR}
          lastVisit={
            clientEvents?.length > 0 ? maxHour(clientEvents) + 1 : DEFAULT_MAX_WORKING_HOUR
          }
          onNavigate={date => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          toolbar
        />
      ) : (
        <DefaultFiveDaysCalendar
          className="client-five-days-calendar"
          customToolbar={ClientCalendarToolbar}
          date={calendarDate}
          dateHeaderWrapper={calendarHeader}
          eventComponent={ClientCalendarEvent}
          eventStyleGetter={getEventBoxClass}
          dayPropGetter={dayPropGetter}
          events={clientEvents}
          firstVisit={clientEvents?.length > 0 ? getMinHour() : DEFAULT_MIN_WORKING_HOUR}
          lastVisit={
            clientEvents?.length > 0 ? maxHour(clientEvents) + 1 : DEFAULT_MAX_WORKING_HOUR
          }
          onNavigate={date => setCalendarDate(date)}
          onSelectEvent={onSelectEvent}
          toolbar
        />
      )}
    </Box>
  );
}

ClientsCalendarsView.propTypes = {
  calendarHeader: func,
  calendarDate: instanceOf(Date),
  clientEvents: instanceOf(Array),
  dayPropGetter: instanceOf(Object),
  setCalendarDate: func,
};
