import { COLORS } from '../../../../shared/styles/Theme';

export const editAvailabilityHeaderWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '30px',
};

export const successAlertStyles = {
  position: 'absolute',
  zIndex: 10000,
  right: '50%',
  left: '80%',
  top: '14px',
  minWidth: '300px',
  fontSize: '12px',
  boxShadow: '0px 0px 12px rgba(44, 45, 46, 0.16)',
};

export const effectiveDateSelectCustomAlert = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  padding: '12px',
  mb: '20px',
  '& .MuiAlert-icon': {
    padding: 0,
  },
  '& .MuiAlert-message': {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: COLORS.yellow[700],
    padding: 0,
  },
};

export const scheduleEntryContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '18px 0px',
  width: '100%',
};

export const scheduleEntryDivider = {
  height: '1px',
  width: '100%',
  margin: '5px 0px',
};