import { Breadcrumbs, Typography } from '@mui/material';
import { func, string } from 'prop-types';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { VisitInfoTypeContext } from '../../../../../shared/context';
import { COLORS } from '../../../../../shared/styles/Theme';
import BreadcrumbsButton from '../../../../../components/BreadcrumbsButton';

export default function CustomBreadcrumbs({ closeDialog = () => {}, title = '' }) {
  const navigate = useNavigate();
  const { temporaryVisitData } = useContext(VisitInfoTypeContext);

  const onLinkClick = ({ e, url }) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    } else {
      navigate(url);
      closeDialog();
    }
  };
  return (
    <Breadcrumbs aria-label="breadcrumbs">
      <BreadcrumbsButton name="Clients" onLinkClick={e => onLinkClick({ e, url: '/clients' })} />
      <BreadcrumbsButton
        name={temporaryVisitData?.clientName}
        onLinkClick={e =>
          onLinkClick({
            e,
            url: `/clients/${temporaryVisitData?.clientId}`,
          })
        }
      />
      <Typography
        sx={{ color: COLORS.blue[700], cursor: 'pointer' }}
        variant="body1"
        onClick={closeDialog}
      >
        Calendar
      </Typography>
      <Typography sx={{ color: COLORS.blue[900] }} variant="body1">
        {title}
      </Typography>
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  closeDialog: func,
  title: string,
};
