import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetClientTasksListQuery } from '../../../../../../../../../../api/CarePlan/api';
import NoRowsComponent from '../../../../../../../../../../pages/ClientDetails/components/NoRowsComponent';
import VisitDateAccordion from '../../../../../../../../../../pages/ClientDetails/components/VisitDateAccordion';
import { bool, func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertContext from '../../../../../../../../../../components/Alert';
import CustomTable from '../../../../../../../../../../components/Table';
import { getEditColumns } from './constants';
import { stepContentWrapperStyles, tasksManagementTableStyles } from './styles';
import moment from 'moment';
import { H12_TIME_FORMAT } from '../../../../../../../../../../shared/constants';

export default function TasksManagement({
  availableVisitTasks = [],
  setAvailableVisitTasks = () => {},
  closeDialog = () => {},
  visitData = {},
  stepDataLoading = false,
  setStepDataLoading = () => {},
  alternativeArrivalWindows = {},
}) {
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const {
    data: clientTasks,
    error: getClientTasksError,
    isLoading: isLoadingClientTasks,
  } = useGetClientTasksListQuery({ clientId: id }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (getClientTasksError) {
      const errorData = getClientTasksError;

      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getClientTasksError, setAlert]);

  useEffect(() => {
    if (
      clientTasks &&
      Object.keys(visitData?.days ?? {}).length &&
      alternativeArrivalWindows?.availableSchedule?.length
    ) {
      const arrivalWindow = alternativeArrivalWindows?.availableSchedule[0];
      const validTasks = clientTasks?.filter(clientTask => {
        const daysOfWeek = Object.keys(visitData.days) ?? [];
        const arrivalStart = moment(arrivalWindow.arrivalStart, H12_TIME_FORMAT);
        const visitEnd = moment(arrivalWindow.arrivalEnd, H12_TIME_FORMAT).add(
          visitData.duration,
          'minutes',
        );
        const validTimeOfDays = clientTask.timeOfDaysDetails?.filter(timeOfDays => {
          const timeFrom = moment(timeOfDays.from, H12_TIME_FORMAT);
          const timeTo = moment(timeOfDays.to, H12_TIME_FORMAT);
          return (
            (arrivalStart.isSameOrAfter(timeFrom) && arrivalStart.isBefore(timeTo)) ||
            (visitEnd.isAfter(timeFrom) && visitEnd.isSameOrBefore(timeTo))
          );
        });
        return (
          clientTask.visitTypes.includes(visitData.visitType) &&
          validTimeOfDays?.length &&
          daysOfWeek.every(dayOfWeek => clientTask?.days?.includes(Number(dayOfWeek)))
        );
      });
      setAvailableVisitTasks(validTasks);
      setStepDataLoading(false);
    } else {
      setStepDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTasks]);

  const goToEditTasks = useCallback(() => {
    navigate(`/clients/${id}/care-plan/current`);
    closeDialog();
  }, [closeDialog, id, navigate]);

  const getRowId = row => (row ? row.id : '');

  const getNoRowsComponent = useCallback(
    () => <NoRowsComponent goToEditTasks={goToEditTasks} />,
    [goToEditTasks],
  );

  const updateTasks = newTasks => {
    setAvailableVisitTasks(newTasks);
  };

  return (
    <Box sx={stepContentWrapperStyles}>
      <VisitDateAccordion
        tasksCount={availableVisitTasks?.length ?? 0}
        title={`Tasks to be Included`}
      >
        <CustomTable
          apiRef={apiRef}
          columns={getEditColumns({ allTasks: availableVisitTasks, updateTasks: updateTasks })}
          customStyles={tasksManagementTableStyles(!availableVisitTasks?.length)}
          headerHeight={34}
          getRowId={getRowId}
          isLoading={isLoadingClientTasks || stepDataLoading}
          noRowsOverlay={() => getNoRowsComponent()}
          onRowClick={() => {}}
          rows={availableVisitTasks ?? []}
          withoutPagination
        />
      </VisitDateAccordion>
    </Box>
  );
}

TasksManagement.propTypes = {
  availableVisitTasks: instanceOf(Array),
  setAvailableVisitTasks: func,
  closeDialog: func,
  visitData: instanceOf(Object),
  stepDataLoading: bool,
  setStepDataLoading: func,
  alternativeArrivalWindows: instanceOf(Object),
};
