import { Box, Typography, Button } from '@mui/material';
import { api } from '../../../../../../../../../../api';
import AvailabilityLoader from '../../../../../../../../../../pages/ClientDetails/components/AvailabilityLoader';
import { func, instanceOf, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { COLORS } from '../../../../../../../../../../shared/styles/Theme';
import AlertContext from '../../../../../../../../../../components/Alert';
import CustomDialog from '../../../../../../../../../../components/Dialog';
import AvailabilityColumns from './AvailabilityColumns';
import { WebSocketContext } from '../../../../../../../../../../components/WebsocketProvider/WebsocketProvider';

function AlternativeArrivalWindows({
  alternativeArrivalWindows = {},
  completedSteps = [],
  selectedTimeSlots = {},
  setAlternativeArrivalWindows = () => {},
  setStepDataLoading = () => {},
  setVisitData = () => {},
  updatedCareProgram = {},
  visitData = {},
  temporaryVisitData = {},
  effectiveDate = '',
}) {
  const [subscribe, unsubscribe] = useContext(WebSocketContext);
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deletedVisitData, setDeletedVisitData] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [careProgramArrivalWindows, setCareProgramArrivalWindows] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [operationId, setOperationId] = useState(null);

  const checkDb = async () => {
    const payload = {
      id: operationId,
    };
    const apiData = await api('GET', 'crud', 'checksData', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (apiData?.data?.status === 'finished') {
        setCareProgramArrivalWindows(apiData?.data?.data);
        setIsLoading(false);
      }
    }
  };

  const getData = async () => {
    const payload = {
      clientId: id,
      careProgram: { ...temporaryVisitData, effectiveDateStart: effectiveDate.replaceAll('Z', '')},
      action: 'startAlternative',
      selectedTimeSlots: selectedTimeSlots,
    };
    const apiData = await api('POST', 'check', 'careProgram', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      setOperationId(apiData?.data?.operationId);
    }
  };

  useEffect(() => {
    if (!operationId) {
      getData();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (careProgramArrivalWindows) {
      const tempAlternativeArrivalWindows = {
        availableSchedule: careProgramArrivalWindows.filter(item => item.available),
        unAvailableSchedule: careProgramArrivalWindows.filter(item => !item.available),
      };
      setAlternativeArrivalWindows(tempAlternativeArrivalWindows);
    }
  }, [careProgramArrivalWindows, setAlternativeArrivalWindows]);

  useEffect(() => {
    const isDataLoading = isLoading;
    setStepDataLoading(isDataLoading);
  }, [isLoading, setStepDataLoading]);

  const confirmDeleting = () => {
    const dateChanges = {};
    dateChanges[deletedVisitData.date.slice(0, 10)] = {'canceled': true};
    const tempVisitData = { ...visitData };
    tempVisitData.dateChanges = {...tempVisitData.dateChanges, ...dateChanges};
    setVisitData(tempVisitData);
    const { dataKey, date } = deletedVisitData;
    const newSchedule = alternativeArrivalWindows[dataKey].map(item =>
      item.date === date ? { ...item, cancelled: true } : item,
    );
    setExpanded(expanded.filter(item => item !== date));
    setDeletedVisitData(null);
    setOpenConfirmationModal(false);
    setAlternativeArrivalWindows({
      ...alternativeArrivalWindows,
      [dataKey]: newSchedule,
    });
  };

  const deleteVisitFromSchedule = date => {
    setOpenConfirmationModal(true);
    setDeletedVisitData(date);
  };

  useEffect(() => {
    subscribe('checkAlternativeAvailability', messageData => {
      if (messageData.operationId === operationId) {
        setCareProgramArrivalWindows(messageData.data);
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe('checkAlternativeAvailability');
    };
  }, [subscribe, unsubscribe]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => {
          setDeletedVisitData(null);
          setOpenConfirmationModal(false);
        }}
        submitButtonName="confirm"
        submitCallback={confirmDeleting}
        title="Confirmation"
        openDialog={openConfirmationModal}
      >
        <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
          <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
            The day of visit will be removed. Do you want to proceed?
          </Typography>
        </Box>
      </CustomDialog>
      {isLoading ? (
        <>
          <Button onClick={checkDb}>Refresh</Button>
          <AvailabilityLoader />
        </>
      ) : (
        <AvailabilityColumns
          alternativeArrivalWindows={alternativeArrivalWindows}
          deleteVisitFromSchedule={deleteVisitFromSchedule}
          expanded={expanded}
          setAlternativeArrivalWindows={setAlternativeArrivalWindows}
          setExpanded={setExpanded}
          setVisitData={setVisitData}
          visitData={visitData}
        />
      )}
    </Box>
  );
}

AlternativeArrivalWindows.propTypes = {
  alternativeArrivalWindows: instanceOf(Object),
  completedSteps: instanceOf(Array),
  selectedTimeSlots: instanceOf(Object),
  setAlternativeArrivalWindows: func,
  setStepDataLoading: func,
  setVisitData: func,
  updatedCareProgram: instanceOf(Object),
  visitData: instanceOf(Object),
  temporaryVisitData: instanceOf(Object),
  effectiveDate: string,
};

export default React.memo(AlternativeArrivalWindows);
