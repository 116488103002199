import { TextField } from '@mui/material';
import { bool, func, instanceOf, number, string } from 'prop-types';
import React, { useState } from 'react';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../shared/constants';
import { useDebouncedCallback } from '../../shared/hooks/useDebounce';
import { customInputStyles, customMultilinesInputStyles } from './styles';

export default function MultilineInput({
  changeDetails = () => {},
  className = '',
  disabled = false,
  id = '',
  label = '',
  maxLength = 0,
  value = '',
  showHelperText = false,
  customStyles = {},
  onKeyDown = () => {},
}) {
  const [showHelper, setShowHelper] = useState(false);
  const debouncedChangeData = useDebouncedCallback(e => {
    changeDetails(e);
  }, SEARCH_INPUT_DEBOUNCE_DELAY);
  return (
    <TextField
      className={className || ''}
      disabled={disabled}
      defaultValue={value}
      helperText={
        showHelperText && (value?.length || showHelper)
          ? `${value?.length || 0} / ${maxLength}`
          : ''
      }
      id={id}
      label={label}
      inputProps={{ maxLength }}
      maxRows={5}
      minRows={4}
      multiline
      onBlur={() => setShowHelper(false)}
      onChange={debouncedChangeData}
      onFocus={() => setShowHelper(true)}
      onKeyDown={onKeyDown}
      // required
      sx={{
        ...customInputStyles,
        ...customMultilinesInputStyles,
        ...customStyles,
      }}
      variant="filled"
    />
  );
}

MultilineInput.propTypes = {
  changeDetails: func,
  className: string,
  disabled: bool,
  id: string,
  label: string,
  maxLength: number,
  value: string,
  showHelperText: bool,
  customStyles: instanceOf(Object),
  onKeyDown: func,
};
