import { TabContext, TabList } from '@mui/lab';
import { Box, Divider, Tab, Typography } from '@mui/material';
import repeatedVisit from '../../../../img/icons/visitTypes/repeatedVisit.svg';
import { bool, func, number, string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { visitStatusesMap, visitTypeIcons } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import CustomDialog from '../../../../components/Dialog';
import {
  canRestoreCanceledVisitStatuses,
  onlyCancelAllowsVisitStatuses,
  unVerifyVisitStatuses,
  visitDetailsViewsMap,
  visitStatuses,
} from '../../constants';
import { getBgColor } from '../../utils';
import JobIdInfo from '../JobIdInfo';
import VisitStatusSelect from '../StatusSelect';
import VisitCost from '../VisitCost';
import {
  visitDetailsTitleStyles,
  visitDetailsTitleWrapper,
  visitDetailsVisitStatusChangingConfirmationWrapperStyles,
} from './styles';
import { visitStatusChangingTextAreaWrapper } from '../StatusChangingConfirmation/styles';
import { useGetConfigQuery } from '../../../../api/Config/api';
import CustomSelect from '../../../Select';

function Title({
  cost = 0,
  detailsView = '',
  disabledAll = false,
  initialStatus = '',
  jobId = '',
  repeated = false,
  setDetailsView = () => {},
  setNewStatus = () => {},
  status = '',
  cancellationReason = '',
  title = '',
  type = '',
  isFutureVisit = false,
  isNotAllocatedVisit = false,
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [temporaryStatus, setTemporaryStatus] = useState(null);
  const [reason, setReason] = useState(cancellationReason);
  const userProfile = JSON.parse(localStorage.getItem('user'));
  const changeTab = (_, newValue) => setDetailsView(newValue);

  const changeStatus = value => {
    if (value === visitStatusesMap.canceled) {
      setOpenDialog(true);
      setTemporaryStatus(value);
    } else setNewStatus({ newStatus: value });
  };

  const confirmationCanceling = () => {
    setNewStatus({ newStatus: temporaryStatus, reason });
    setOpenDialog(false);
  };

  const rejectCanceling = () => {
    setTemporaryStatus(null);
    setOpenDialog(false);
    setReason('');
  };

  const { data: configData } = useGetConfigQuery(['cancellationReasons']);

  const availableVisitsOptions = useMemo(() => {
    if (
      initialStatus?.toLowerCase() === 'canceled' &&
      userProfile?.roles?.includes('ADMIN') &&
      isFutureVisit
    ) {
      return canRestoreCanceledVisitStatuses;
    }
    if (initialStatus?.toLowerCase() === 'verified' && userProfile?.roles?.includes('ADMIN')) {
      return unVerifyVisitStatuses;
    }
    if (initialStatus?.toLowerCase() !== 'verified' && (isNotAllocatedVisit || isFutureVisit)) {
      return onlyCancelAllowsVisitStatuses;
    }
    return visitStatuses;
  }, [initialStatus, isNotAllocatedVisit, isFutureVisit, userProfile]);

  return (
    <Box sx={visitDetailsTitleWrapper}>
      <Box sx={visitDetailsTitleStyles}>
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={rejectCanceling}
          disabledSubmit={!reason.length}
          submitButtonName="confirm"
          submitCallback={confirmationCanceling}
          title="Cancellation Reason"
          openDialog={openDialog}
        >
          <Box sx={visitDetailsVisitStatusChangingConfirmationWrapperStyles}>
            <Typography sx={{ mb: '10px' }}>
              {`Visit Status will be changed to '${temporaryStatus}'. Do you want to proceed?`}
            </Typography>
            <CustomSelect
              id="reasons"
              isLoading={false}
              options={configData?.cancellationReasons}
              placeHolder="Reasons"
              selectedValue={reason}
              setValue={params => setReason(params.id)}
              addStyles={visitStatusChangingTextAreaWrapper}
            />
          </Box>
        </CustomDialog>
        <img src={visitTypeIcons[type?.toLowerCase()]} alt="visit type icon" />
        {repeated && <img src={repeatedVisit} alt="icon" />}
        <Typography variant="h1" noWrap>
          {title}
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ color: COLORS.blue[200] }} />
        <VisitCost cost={cost} />
        <Divider orientation="vertical" flexItem sx={{ color: COLORS.blue[200] }} />
        <JobIdInfo jobId={jobId} />
        <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
          <VisitStatusSelect
            bgColor={getBgColor(status)}
            disabled={disabledAll}
            initialVisitStatus={initialStatus}
            isLoading={false}
            options={availableVisitsOptions}
            selectedValue={status || ''}
            setValue={changeStatus}
          />
        </Box>
      </Box>
      <TabContext value={detailsView}>
        <TabList
          onChange={changeTab}
          sx={{
            display: 'flex',
            '& .MuiTabs-flexContainer': { gap: '24px' },
          }}
        >
          {Object.values(visitDetailsViewsMap)?.map(value => (
            <Tab disableRipple key={value} label={value} value={value} />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
}

Title.propTypes = {
  cost: number,
  detailsView: string,
  disabledAll: bool,
  jobId: string,
  initialStatus: string,
  repeated: bool,
  setDetailsView: func,
  setNewStatus: func,
  status: string,
  cancellationReason: string,
  title: string,
  type: string,
  isFutureVisit: bool,
  isNotAllocatedVisit: bool,
};

export default React.memo(Title);
