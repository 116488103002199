import { Box, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../../../img/icons/error-icon.svg';
import warningIconYellow from '../../../../../../img/icons/warning-icon.svg';
import moment from 'moment';
import { violatedSoftConstraintsStyles } from '../../../../../../pages/Scheduling/components/styles';
import { instanceOf } from 'prop-types';
import React, { useState } from 'react';
import {
  checkIfShowArrivalWindow,
  violationTooltipComponent,
} from '../../../../../../shared/utils/common';
import EventCardInfo from '../EventCardInfo';
import EventCardInfoIcons from '../EventCardInfoIcons';
import {
  constraintsViolationsIconsWrapper,
  mainEventWrapper,
  teamBlockWrapperCommonStyles,
} from '../style';
import EventHoveredInfo from './EventHoveredInfo';

export default function ClientCalendarEvent({ event = {} }) {
  const [hovered, setHovered] = useState(false);
  const visitWithArrivalWindow = moment(event.arrivalTimeStart).isSame(
    moment(event.arrivalTimeEnds),
  );
  const arrivalWindowHeight =
    moment(event.arrivalTimeEnds).diff(event.arrivalTimeStart, 'minutes') * 2.7;
  const arrivalStartPosition = moment(event.start).diff(event.arrivalTimeStart, 'minutes') * 2.7;
  const arrivalEndPosition = moment(event.arrivalTimeEnds).diff(event.start, 'minutes') * 2.7;
  const checkIfShowArrival = checkIfShowArrivalWindow(event?.status);
  return (
    <Box
      sx={{ height: '100%' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        className="event-before-box"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '4px 8px 0 8px',
          height: '100%',
        }}
      >
        <Box sx={mainEventWrapper}>
          <EventCardInfo
            checkIfShowArrival={checkIfShowArrival}
            eventData={event}
            visitWithArrivalWindow={visitWithArrivalWindow}
          />
          <EventCardInfoIcons eventData={event} />
        </Box>
        <Box sx={constraintsViolationsIconsWrapper}>
          {event?.violatedHardConstraints?.length > 0 && (
            <Box sx={violatedSoftConstraintsStyles}>
              {violationTooltipComponent({
                violations: event.violatedHardConstraints,
                icon: hardConstraintsWarning,
                status: event.status,
                violationType: 'hard',
              })}
            </Box>
          )}
          {event?.violatedSoftConstraints?.length > 0 && (
            <Box sx={violatedSoftConstraintsStyles}>
              {violationTooltipComponent({
                violations: event.violatedSoftConstraints,
                icon: warningIconYellow,
                status: event.status,
                violationType: 'soft',
              })}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          ...teamBlockWrapperCommonStyles,
          background: hovered && event?.region?.color?.color,
          height: hovered ? '24px' : '3px',
        }}
      >
        {hovered && (
          <Typography
            sx={{
              color: event?.region?.color?.fontColor,
            }}
          >
            {event?.region?.name}
          </Typography>
        )}
      </Box>
      {hovered && (
        <EventHoveredInfo
          arrivalEndPosition={arrivalEndPosition}
          arrivalStartPosition={arrivalStartPosition}
          arrivalWindowHeight={arrivalWindowHeight}
          checkIfShowArrival={checkIfShowArrival}
          event={event}
          visitWithArrivalWindow={visitWithArrivalWindow}
        />
      )}
    </Box>
  );
}
ClientCalendarEvent.propTypes = {
  event: instanceOf(Object),
};
