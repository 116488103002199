import { Box } from '@mui/material';
import shadowCaregiverIcon from '../../../../../img/icons/shadow-visit-black-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
import { getIconComponent, getOneTimeOrRepeatedIcon } from '../../../../../shared/utils/common';
import CustomTooltip from '../../../../../components/CustomTooltip';

export default function EventCardInfoIcons({ eventData = {} }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: eventData.duration < 45 ? 'row' : 'column',
        mt: '2px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          {getOneTimeOrRepeatedIcon(eventData?.repeat)}
          <CustomTooltip title={eventData?.visitTypeDetails?.name}>
            {getIconComponent(eventData?.visitTypeDetails?.name)}
          </CustomTooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {eventData?.shadowCaregiver && (
          <CustomTooltip title="Shadow visit">
            <img src={shadowCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
      </Box>
    </Box>
  );
}

EventCardInfoIcons.propTypes = {
  eventData: instanceOf(Object),
};
