import { Box, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import softConstraintsWarning from '../../../../img/icons/warning-icon.svg';
import moment from 'moment/moment';
import React from 'react';
import { H12_TIME_FORMAT, SHORT_DATE_FORMAT } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { getLargeSizeStatusComponent } from '../../../../shared/utils/common';
import VisitCaregiverInfo from './components/VisitCaregiversInfo';
import VisitType from './components/VisitType';

export const DEFAULT_SORT_MODAL = { field: 'date', sort: 'asc' };

export const VISITS_STATUSES_TYPES = [
  { key: 'upcoming', title: 'Upcoming' },
  { key: 'past', title: 'Past' },
];

export const NO_DATA_TITLES = {
  'not-allocated': 'There are no not allocated Visits this week',
  'hard-constraints': 'There are no visits with hard constraint violations this week',
  'soft-constraints': 'There are no visits with soft constraint violations this week',
};

export const PAGE_SIZE = 20;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const VISITS_VIEWS = {
  notAllocated: 'not-allocated',
  softConstraints: 'soft-constraints',
  hardConstraints: 'hard-constraints',
};

export const viewTypeButtons = [
  {
    name: 'Not Allocated',
    infoType: VISITS_VIEWS.notAllocated,
    icon: hardConstraintsWarning,
  },
  {
    name: 'Critical Violations',
    infoType: VISITS_VIEWS.hardConstraints,
    icon: hardConstraintsWarning,
  },
  {
    name: 'Minor Violations',
    infoType: VISITS_VIEWS.softConstraints,
    icon: softConstraintsWarning,
  },
];

const comparingVisitDates = (first, second) => {
  const firstTime = moment(first);
  const secondTime = moment(second);
  if (firstTime.isBefore(secondTime)) {
    return -1;
  }
  if (firstTime.isAfter(secondTime)) {
    return 1;
  }
  return 0;
};

export const reschedulingVisitColumns = [
  {
    field: 'date',
    headerName: 'Date',
    colSpan: 1,
    width: 160,
    renderCell: ({ row }) => <Typography>{moment(row.date).format(SHORT_DATE_FORMAT)}</Typography>,
    sortComparator: (v1, v2) => comparingVisitDates(v1, v2),
  },
  {
    field: 'arrivalStart',
    flex: 1,
    headerName: 'Scheduled Arrival Window',
    colSpan: 1,
    minWidth: 220,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>
        {row?.arrivalStart !== row?.arrivalEnd
          ? `${moment(row?.arrivalStart).format(H12_TIME_FORMAT)} - ${moment(
              row?.arrivalEnd,
            ).format(H12_TIME_FORMAT)}`
          : moment(row?.arrivalStart).format(H12_TIME_FORMAT)}
      </Typography>
    ),
  },
  {
    field: 'visitType',
    headerName: 'Visit Type',
    flex: 1,
    colSpan: 1,
    minWidth: 240,
    sortable: false,
    renderCell: ({ row }) => <VisitType row={row} />,
  },
  {
    field: 'team',
    headerName: 'Team',
    sortable: false,
    width: 150,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            background: row.teamDetails?.color?.color,
            width: '16px',
            height: '16px',
            borderRadius: '3px',
            mr: '6px',
          }}
        />
        <Typography>{row?.teamDetails?.name ?? 'N/A'}</Typography>
      </Box>
    ),
  },
  {
    field: 'clientName',
    flex: 1,
    headerName: 'Client',
    minWidth: 180,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography
        variant="body1"
        sx={{
          color: COLORS.blue[700],
          fontWeight: '600',
          fontSize: '14px',
        }}
      >
        {row?.clientName}
      </Typography>
    ),
  },
  {
    field: 'caregiverId',
    headerName: 'Caregivers',
    minWidth: 180,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <VisitCaregiverInfo visitData={row} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    minWidth: 150,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {getLargeSizeStatusComponent(row?.status)}
      </Box>
    ),
  },
];

export const processUnallocatedVisit = visit => {
  const thisVisit = { ...visit };
  const arrivalTime = moment(thisVisit['arrivalStart']);
  const start = moment(thisVisit?.date)
    .set({
      hour: arrivalTime?.hours(),
      minute: arrivalTime?.minutes(),
      second: 0,
      millisecond: 0,
    })
    .toDate();
  const end = moment(start).add(thisVisit.duration, 'minutes').toDate();
  thisVisit.arrivalTime = arrivalTime.format('YYYY-MM-DDTHH:mm:ss');
  thisVisit.start = start;
  thisVisit.end = end;
  thisVisit.arrivalTimeDuration = 60;

  return thisVisit;
};

export const processAllocatedVisit = visit => {
  return {
    ...visit,
    start: new Date(visit.arrivalTime),
    end: new Date(
      moment(visit.arrivalTime).add(visit.duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    ),
    arrivalTimeDuration: moment(visit.arrivalEnd).diff(visit.arrivalStart, 'minutes'),
    travelTime: 20,
    timeDifferenceBetweenStartAndArrivalStart: 0,
    timeDifferenceBetweenStartAndArrivalEnd: 60,
  };
};
