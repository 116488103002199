import { Box } from '@mui/material';
import {
  useGetHolidaysQuery,
  useGetWeekendsSchedulingDaysQuery,
} from '../../../../../../../../api/Administration/api';
import moment from 'moment';
import { getBlockedReason } from '../../../../../../../../pages/ClientDetails/views/Calendar/components/CreateVisitProvider/CreateOneTimeVisitMultistep/constants';
import { func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  CALIFORNIA_TIME_ZONE,
  DAYS_MAP,
  ISO_DATE_ONLY_FORMAT,
} from '../../../../../../../../shared/constants';
import AlertContext from '../../../../../../../../components/Alert';
import CustomTooltip from '../../../../../../../../components/CustomTooltip';
import { calendarElementStyles, datesWrapper, daysWrapper } from './styles';
import { getClassNames, getTooltipTitle } from './utils';

export default function DatesCalendar({ handleSelectDate = () => {}, selectedDate = '' }) {
  const { setAlert } = useContext(AlertContext);
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();
  const { data: disabledWeekDays, error: getWeekdaysError } = useGetWeekendsSchedulingDaysQuery();
  useEffect(() => {
    if (getHolidaysError || getWeekdaysError) {
      const error = getWeekdaysError || getHolidaysError;
      setAlert({
        message: error?.data?.message || 'Something went wrong',
        stacktrace: error?.data?.stacktrace || 'Something went wrong',
        type: 'error',
      });
    }
  }, [getHolidaysError, getWeekdaysError, setAlert]);

  const listOfDates = useMemo(() => {
    const startOfTheWeek = moment().startOf('isoWeek');
    const maxDate = startOfTheWeek.clone().add(13, 'days');
    const holidaysList = holidays?.map(holiday => holiday?.date);

    const dates = [];
    for (
      let startDate = startOfTheWeek.clone();
      startDate.isSameOrBefore(maxDate, 'days');
      startDate.add(1, 'days')
    ) {
      const isSunday = startDate.day() === 0;
      const isSaturday = startDate.day() === 6;
      dates.push({
        date: startDate.clone(),
        disabled:
          startDate.isBefore(moment().tz(CALIFORNIA_TIME_ZONE), 'days') ||
          holidaysList?.includes(startDate.format(ISO_DATE_ONLY_FORMAT)) ||
          (isSaturday && !disabledWeekDays?.schema?.saturdaySwitchedOn) ||
          (isSunday && !disabledWeekDays?.schema?.sundaySwitchedOn),
        isToday: startDate.isSame(moment(), 'days'),
        name: startDate.format('MMM D'),
        reason: getBlockedReason({
          pastDate: startDate.isBefore(moment().tz(CALIFORNIA_TIME_ZONE), 'days'),
          holiday: holidaysList?.includes(startDate.format(ISO_DATE_ONLY_FORMAT)),
          turnOfDay:
            (isSaturday && disabledWeekDays?.schema?.saturdaySwitchedOn) ||
            (isSunday && disabledWeekDays?.schema?.sundaySwitchedOn),
        }),
      });
    }
    if (!selectedDate?.length) {
      handleSelectDate(dates.filter(date => !date.disabled)[0].date.format('YYYY-MM-DDTHH:mm:ss'));
    }
    return dates;
  }, [
    disabledWeekDays?.schema?.saturdaySwitchedOn,
    disabledWeekDays?.schema?.sundaySwitchedOn,
    handleSelectDate,
    holidays,
    selectedDate?.length,
  ]);

  const onSelectDate = date => {
    const newDate = date.format('YYYY-MM-DDTHH:mm:ss');
    handleSelectDate(newDate);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={daysWrapper}>
        {DAYS_MAP.map(({ id, isWeekend, shortName }) => (
          <Box key={id} sx={calendarElementStyles.days} className={isWeekend ? 'weekend-day' : ''}>
            {shortName}
          </Box>
        ))}
      </Box>
      <Box sx={datesWrapper}>
        {listOfDates.map(({ date, disabled, isToday, name, reason }) => (
          <CustomTooltip
            key={date}
            title={disabled ? reason : getTooltipTitle({ disabled, isToday })}
            placement="bottom-end"
          >
            <Box
              key={name}
              sx={calendarElementStyles.dates}
              className={getClassNames({
                disabled,
                isToday,
                selected: selectedDate === date.format('YYYY-MM-DDTHH:mm:ss'),
              })}
              onClick={() => (disabled ? {} : onSelectDate(date))}
            >
              {name}
            </Box>
          </CustomTooltip>
        ))}
      </Box>
    </Box>
  );
}

DatesCalendar.propTypes = {
  handleSelectDate: func,
  selectedDates: instanceOf(Array),
};
