import { Box, Typography } from '@mui/material';
import { useGetConfigQuery } from '../../../../../../api/Config/api';
import { dayBubbleWrapper } from '../../../../../../pages/ClientDetails/views/CarePlan/components/TableTaskCell/styles';
import { instanceOf } from 'prop-types';
import React from 'react';
import { visitTypeIcons, WEEK_DAYS } from '../../../../../../shared/constants';
import IconsCell from '../../../../../../components/IconsCell';

export default function RecurrencePattern({ row }) {
  const { data: configData } = useGetConfigQuery([
    'categoryTypes',
    'levelsOfAssistance',
    'taskTypes',
    'timeOfDays',
    'visitTypes',
  ]);

  const allDaysSelected = row?.days.length === WEEK_DAYS.length;
  const allVisitTypes = row?.visitTypes.length === configData?.visitTypes.length;
  const allTimeOfDays = row?.timeOfDays.length === configData?.timeOfDays.length;

  const getNameById = (id, dataArray) => {
    const item = dataArray.find(item => item.id === id);
    return item ? item.name : id;
  };

  const reversedArray = [];
  if (row?.visitTypes) {
    const visitTypesArray = Object.values(row.visitTypes);
    visitTypesArray.forEach(element => {
      reversedArray.unshift(element);
    });
  }

  const daysArray = Array.isArray(row?.days) ? row.days : Object.values(row?.days || {});
  console.log('daysArray', daysArray);
  const timeOfDaysArray = Array.isArray(row?.timeOfDays)
    ? row.timeOfDays
    : Object.values(row?.timeOfDays || {});

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        width: '340px',
      }}
    >
      {allDaysSelected ? (
        <Typography variant="body1">—</Typography>
      ) : (
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {daysArray.map(day => (
            <Box key={day} sx={dayBubbleWrapper}>
              {getNameById(day, WEEK_DAYS) || day?.slice(0, 2).toUpperCase()}
            </Box>
          ))}
        </Box>
      )}
      {allTimeOfDays ? (
        <Typography variant="body1">—</Typography>
      ) : (
        timeOfDaysArray.map(time => (
          <Typography key={time} variant="body1">
            {getNameById(time, configData?.timeOfDays) || time}
          </Typography>
        ))
      )}
      {allVisitTypes ? (
        <Typography variant="body1">—</Typography>
      ) : (
        <IconsCell
          iconsMap={visitTypeIcons}
          items={reversedArray.map(visitTypeId => getNameById(visitTypeId, configData?.visitTypes))}
          maxWidth={300}
        />
      )}
    </Box>
  );
}

RecurrencePattern.propTypes = {
  row: instanceOf(Object),
};
