import { useGetHolidaysQuery } from '../../../../api/Administration/api';
import { useGetClientsCalendarQuery } from '../../../../api/Clients/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ISO_DATE_ONLY_FORMAT } from '../../../../shared/constants';
import { checkIfIsHoliday } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import Loader from '../../../../components/Loader';
import VisitManagementProvider from '../../../../components/VisitInfoTypeProvider/VisitManagementProvider';
import ClientsCalendarsView from './ClientsCalendarsView';
import { ClientsCalendarHeader } from './components/ClientsCalendarHeader';
import CreateOneTimeVisitProvider from './components/CreateVisitProvider/CreateOneTimeVisitProvider';
import './index.css';

export default function ClientsCalendar() {
  const { setAlert } = useContext(AlertContext);
  const [calendarDate, setCalendarDate] = useState(null);
  const { id } = useParams();
  const today = moment();
  const mondayDate = today.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT);
  const formattedDate = calendarDate
    ? moment(calendarDate)?.startOf('isoWeek').format(ISO_DATE_ONLY_FORMAT)
    : null;
  const {
    data: clientEvents,
    refetch: refetchCalendarData,
    error: getClientsCalendarError,
    isLoading: isLoadingCalendar,
  } = useGetClientsCalendarQuery(
    {
      clientId: id,
      date: formattedDate || mondayDate,
    },
    { refetchOnMountOrArgChange: true },
  );
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();

  useEffect(() => {
    const errorData = getHolidaysError || getClientsCalendarError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getHolidaysError, getClientsCalendarError, setAlert]);

  const parsedEvents = clientEvents?.map(clientEvent => {
    const thisVisit = { ...clientEvent };
    const arrivalTime = moment(thisVisit['arrivalStart']);
    const start = moment(thisVisit?.date)
      .set({
        hour: arrivalTime?.hours(),
        minute: arrivalTime?.minutes(),
        second: 0,
        millisecond: 0,
      })
      .toDate();
    const end = moment(start).add(thisVisit.duration, 'minutes').toDate();
    return {
      ...thisVisit,
      arrivalTime: arrivalTime.format('YYYY-MM-DDTHH:mm:ss'),
      start,
      end,
      arrivalTimeDuration: 60,
    };
  });

  useEffect(() => {
    const now = new Date();
    setTimeout(() => {
      const currentTimeIndicator = document.querySelector('.rbc-current-time-indicator');

      currentTimeIndicator?.classList.add('custom-time-indicator');
      const currentTimeIndicator1 = document.querySelector('.custom-time-indicator');
      const currentTime = now.toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
      });
      currentTimeIndicator1?.setAttribute('data-current-time', currentTime);
    }, 0);
  }, [parsedEvents]);

  const calendarHeader = ({ date }) => {
    const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);
    return (
      <ClientsCalendarHeader
        date={date}
        holiday={isDateHoliday?.name ? date : null}
        holidayName={isDateHoliday?.name || ''}
      />
    );
  };

  const dayPropGetter = useCallback(
    date => {
      const isDateHoliday = checkIfIsHoliday(date, holidays, calendarDate);

      const dayProps = {};

      if (isDateHoliday) {
        dayProps.className = 'holiday';
      }
      return dayProps;
    },
    [holidays, calendarDate],
  );
  return (
    <CreateOneTimeVisitProvider getCalendarData={refetchCalendarData}>
      <VisitManagementProvider reFetchCalendarEvents={refetchCalendarData}>
        {isLoadingCalendar ? (
          <Loader />
        ) : (
          <ClientsCalendarsView
            calendarHeader={calendarHeader}
            calendarDate={calendarDate}
            dayPropGetter={dayPropGetter}
            clientEvents={parsedEvents}
            setCalendarDate={setCalendarDate}
          />
        )}
      </VisitManagementProvider>
    </CreateOneTimeVisitProvider>
  );
}
