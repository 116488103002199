import { Box, CircularProgress, Typography } from '@mui/material';
import hardConstraintsWarning from '../../../../img/icons/error-icon.svg';
import WarningIconSchedule from '../../../../img/icons/warning-icon-shcedule.svg';
import { PREFERRED_TITLE } from '../../../../pages/Scheduling/constants';
import { bool, func, instanceOf } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../components/Dialog';
import SuccessResult from './SuccessResult';
import FailedResult from './FailedResult';

export default function RescheduleModalBackend({
  checkPossibilityData = [],
  closeDialog = () => {},
  disabledSubmit = false,
  isLoading = false,
  openDialog = false,
  submitDialog = () => {},
}) {
  const errorResponse = checkPossibilityData?.violatedConstraints;

  const successResult =
    checkPossibilityData?.violatedConstraints?.length === 0 &&
    checkPossibilityData?.visitAvailable === true;
  const hasHardConstraints = checkPossibilityData?.violatedConstraints?.length > 0;
  const successButton = successResult ? 'Confirm' : 'OK';
  return (
    <div>
      <CustomDialog
        cancelButtonName={'Cancel'}
        cancelCallback={closeDialog}
        disableOnCloseByClickOutside
        openDialog={openDialog}
        disabledSubmit={disabledSubmit}
        submitButtonName={successResult ? successButton : 'OK'}
        submitCallback={successResult ? submitDialog : closeDialog}
        title="Confirmation"
      >
        <Box>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {checkPossibilityData?.intersectWithShadowVisit ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 28px 28px 28px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: '6px' }}>
                <img src={hardConstraintsWarning} alt="" />
                <Typography sx={{ ml: '6px', maxWidth: '470px' }} variant="body1">
                  You are attempting to schedule a visit that conflicts with an existing shadow
                  visit on the caregiver&apos;s calendar.
                </Typography>
              </Box>
              <Typography sx={{ pl: '20px' }}>
                This action is restricted.{successResult ? ' Do you want to continue?' : ''}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 28px 28px 28px',
              }}
            >
              {hasHardConstraints && (
                <Box>
                  {errorResponse.map(title => (
                    <Box key={title} sx={{ display: 'flex', alignItems: 'center', mb: '6px' }}>
                      {title === PREFERRED_TITLE ? (
                        <img src={WarningIconSchedule} alt="" />
                      ) : (
                        <img src={hardConstraintsWarning} alt="" />
                      )}
                      <Typography sx={{ ml: '6px', maxWidth: '470px' }} variant="body1">
                        {title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              {successResult ? <SuccessResult /> : !disabledSubmit && <FailedResult />}
            </Box>
          )}
        </Box>
      </CustomDialog>
    </div>
  );
}

RescheduleModalBackend.propTypes = {
  openDialog: bool,
  checkPossibilityData: instanceOf(Object),
  closeDialog: func,
  isLoading: bool,
  submitDialog: func,
  disabledSubmit: bool,
};
